import { z } from "zod";
// Local
import {
  invoiceFrequency,
  invoiceMethod,
  TaskGeneration,
} from "../../models/membership-template";

export type TemporaryTaskGeneration = TaskGeneration & { id: string };

// ok so technically the pbi ones aren't dialogs.. it's for lack of a better term
/** For add/edit template pages */
export type DialogStates = {
  pbi: boolean;
  renewalPBI: boolean;
  taskGen: boolean;
};

export const TemplateFormSchema = z.object({
  title: z.string().min(1).max(500),
  description: z.string().min(1).max(4000),
  invoiceMethod: z.enum(invoiceMethod),
  invoiceFrequency: z.enum(invoiceFrequency),
  automaticallyRenewMembership: z.boolean().optional(),
  automaticallyGenerateTasks: z.boolean().optional(),
  discount: z.number().min(0).max(100, {
    message: "Discount must be between 0 and 100",
  }),
});

export type TemplateFormState = z.infer<typeof TemplateFormSchema>;
