// Libs
import { useCallback, useEffect, useRef, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { XCircleIcon } from "@heroicons/react/24/solid";
import {
  CalendarProps,
  DayPilot,
  DayPilotCalendar,
  DayPilotScheduler,
  SchedulerProps,
} from "daypilot-pro-react";
import { DateTime } from "luxon";

// Local
import {
  ExistingTask,
  ExistingTaskWithCustomerLocation,
} from "../../models/task";
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { SchedulingSection } from "../customers/CreateTask";
import {
  convertFSTimestampToLuxonDT,
  convertLuxonDTToFSTimestamp,
  getTimeDifferenceInHours,
  isStringArray,
  convertJSDateToFSTimestamp,
} from "../../utils";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import {
  checkTsdRequired,
  updateTaskFromScheduleDateChange,
} from "../../assets/js/tasks";
import { ExistingSiteKey } from "../../models/site-key";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { getServiceWindowAndScheduledTS } from "../../Pages/Customers/CreateTaskForCustomerContainer";
import { logger as devLogger, logger } from "../../logging";
import StyledMessage from "../StyledMessage";
import getCurrentJSDateWithoutSeconds from "../../assets/js/getCurrentJSDateWithoutSeconds";
import { ScheduleByPriorityType } from "../customers/CreateTask/SchedulingSection";
import {
  customizeHeader,
  stiltTaskBubble,
} from "../../Pages/Scheduling/SchedulingContainer";
import { getFontColorForBackground } from "../../utils/colors";
import {
  generateResourcesForDayPilotCalendar,
  generateResourcesForDayPilotScheduler,
} from "../../assets/js/scheduling/generateResources";
import { generateCalendarEvents } from "../../assets/js/scheduling/generateCalendarEvents";
import { ExistingVehicle } from "../../models/vehicle";
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { CalendarEvent } from "../../models/scheduling-types";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { ExistingStiltCalendarEvent } from "../../models/stilt-calendar-event";
import { generateStiltCalendarEvents } from "../../assets/js/scheduling/generateStiltCalendarEvents";
import { DbRead } from "../../database";
import { useAuthStore } from "../../store/firebase-auth";
import { User } from "firebase/auth";
import { ExistingAssignedRoute } from "../../models/assigned-route";
import { ExistingZone } from "../../models/zone";
import {
  getDotColor,
  getDotColorWIPTask,
} from "../../assets/js/scheduling/getDotColor";
import { getBackColor } from "../../assets/js/scheduling/getBackColor";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useUserPermissionsStore } from "../../store/user-permissions";

/*
TODO: want to refactor SchedulingSection so we can display ALL of the original
task's scheduling stuff - right now we're just leaving the schedule by priority
dropdown as "Select", IE ignoring if they originally scheduled it as Urgent.
there's a note about this in my backlog too.
*/

interface Props {
  // DIALOG BASICS
  isOpen: boolean;
  onClose: () => void;

  // DATA
  task: ExistingTaskWithCustomerLocation;
  siteKey: ExistingSiteKey;
  siteKeyUserPermissions: ExistingSiteKeyUserPermissions;
  vehicleList: ExistingVehicle[];
  permissionsMap: Record<string, ExistingSiteKeyUserPermissions>;
  siteKeyUsersList: ExistingSiteKeyUserDoc[];
  siteLocationList: ExistingSiteKeyLocation[];
  // FUNCTIONS
  handleRescheduleTask: (task: ExistingTask) => Promise<void>;
  handleOpenTaskStatusChangeDialogDueToScheduleChange: (args: {
    updatedTask: ExistingTask;
    originalTask: ExistingTask;
  }) => void;
}

export default function RescheduleTaskDialog(props: Props): JSX.Element {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const clonedInitialTask = cloneDeep(props.task);

  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  // #region Get the task's original scheduling data
  // Get date to display on first render
  const timestampScheduled = clonedInitialTask.timestampScheduled;
  let dateAndTime: Date;
  if (timestampScheduled) {
    const luxonDT = convertFSTimestampToLuxonDT(timestampScheduled);
    dateAndTime = luxonDT.toJSDate();
  } else {
    dateAndTime = getCurrentJSDateWithoutSeconds();
  }

  // Will be null or a Firestore Timestamp
  const serviceWindowStart =
    clonedInitialTask.taskSpecificDetails.scheduledServiceWindowStart;
  const serviceWindowEnd =
    clonedInitialTask.taskSpecificDetails.scheduledServiceWindowEnd;

  // Get task's current duration, if applicable.
  let duration: number;
  if (serviceWindowStart && serviceWindowEnd) {
    const timeDiff = getTimeDifferenceInHours({
      startDate: serviceWindowStart,
      endDate: serviceWindowEnd,
    });
    duration = timeDiff;
  } else {
    duration = 2;
  }
  // #endregion Get the task's original scheduling data

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scheduledByPriority, setScheduledByPriority] =
    useState<ScheduleByPriorityType | null>(null);
  const [serviceWindowDuration, setServiceWindowDuration] = useState(duration);
  const [scheduleDatetime, setScheduleDatetime] = useState(dateAndTime);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [sendJobReminderEmail, setSendJobReminderEmail] = useState(
    clonedInitialTask.sendJobReminderEmail ?? false,
  );
  const [showCalendar, setShowCalendar] = useState(false);

  const [currentEvent, setCurrentEvent] = useState<CalendarEvent[]>([]);
  const calendarEventsRef = useRef<CalendarEvent[]>([]);

  const [scheduledAwaitingTaskList, setScheduledAwaitingTaskList] = useState<
    ExistingTaskWithCustomerLocation[]
  >([]);

  const [stiltCalendarEvents, setStiltCalendarEvents] = useState<
    ExistingStiltCalendarEvent[] // REFACTOR: useRef
  >([]);

  const [assignedTo, setAssignedTo] = useState<string[]>([]);
  const originalAssignedVehicleID =
    typeof props.task.taskSpecificDetails.assignedVehicleID === "string"
      ? props.task.taskSpecificDetails.assignedVehicleID
      : "";
  const [assignedVehicleID, setAssignedVehicleID] = useState(
    originalAssignedVehicleID,
  );

  const resourcesMode =
    props.siteKey.customizations["dispatchBoardResourceTypeDefault"] ??
    "technicians";

  const [zoneList, setZoneList] = useState<ExistingZone[]>([]);

  useEffect(() => {
    function getScheduledAwaitingTaskList() {
      if (!siteKeyDoc) return;
      if (!userPermissions) return;

      const startDate = DateTime.fromJSDate(scheduleDatetime).set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      const selectedDatePlus5 = DateTime.fromJSDate(scheduleDatetime).plus({
        day: 5,
      });
      const endDate = selectedDatePlus5.set({
        hour: 23,
        minute: 59,
        second: 59,
      });

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubscribeScheduledAwaitingTaskList =
        DbRead.tasks.subscribeScheduledAwaitingTaskList({
          siteKey: siteKeyDoc.id,
          permissions: userPermissions,
          startDate: convertLuxonDTToFSTimestamp(startDate),
          endDate: convertLuxonDTToFSTimestamp(endDate),
          onChange: async (list) => {
            const tasksWithCustomerLocations: ExistingTaskWithCustomerLocation[] =
              [];
            const customerLocationIDs = list.flatMap(
              (task) => task.customerLocationID ?? [],
            );
            const uniqueCustomerLocationIDs = Array.from(
              new Set(customerLocationIDs),
            );
            // Promises all customerLocations
            const customerLocationPromises = uniqueCustomerLocationIDs.map(
              (clID) => DbRead.customerLocations.getSingle(siteKeyDoc.id, clID),
            );
            const customerLocations = await Promise.all(
              customerLocationPromises,
            );
            for (const task of list) {
              const taskWithCL: ExistingTaskWithCustomerLocation = task;
              // query customerLocation for each task
              if (task.customerLocationID) {
                taskWithCL.customerLocation = customerLocations.find(
                  (cl) => cl.id === task.customerLocationID,
                );
                tasksWithCustomerLocations.push(taskWithCL);
              }
            }
            setScheduledAwaitingTaskList(tasksWithCustomerLocations);
          },
          onError: (error) =>
            devLogger.error(
              `Error in ${getScheduledAwaitingTaskList.name}: ${error.message}`,
            ),
        });

      return unsubscribeScheduledAwaitingTaskList;
    }

    const unsubscribe = getScheduledAwaitingTaskList();

    // Return an anonymous ƒn for cleanup.
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [scheduleDatetime, siteKeyDoc, userPermissions]);

  // Fetch the list of zones when this component loads.
  useEffect(() => {
    async function getZones() {
      logger.debug("getZones useEffect called ");
      // Query zones via realtime updates. Set the list of zones.
      const unsubscribeAllZones = DbRead.zones.subscribeAll({
        siteKey: props.siteKey.id,
        onChange: (zoneList: ExistingZone[]) => {
          setZoneList(zoneList);
        },
        onError: (error) => logger.error(`Error in getZones: ${error.message}`),
      });
      return unsubscribeAllZones;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getZones();
    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(RescheduleTaskDialog) getZones useEffect return ƒn just invoked.",
      );
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          logger.debug("Now unsubscribing from zones.");
          unsubscribe();
        }
      });
      logger.debug("Done running cleanup ƒn for getZones useEffect.");
    };
  }, [props.siteKey]);

  const getCalendarEvents = useCallback(() => {
    const events = generateCalendarEvents({
      siteKey: props.siteKey,
      siteKeyLocations: props.siteLocationList,
      allocatedTasks: scheduledAwaitingTaskList,
      vehicleList: props.vehicleList,
      searchTerm: "",
      mode: resourcesMode,
      inactiveUserIDs: props.siteKey?.inactiveUsers ?? [],
      unapprovedUserIDs: props.siteKey?.unapprovedUsers ?? [],
      zoneList: zoneList,
    });

    for (const event of events) {
      event.moveDisabled = true;
      event.resizeDisabled = true;
    }
    const nonCurrentEvents = events.filter(
      (event) => event.tags.taskID !== clonedInitialTask.id,
    );
    return nonCurrentEvents;
  }, [
    clonedInitialTask.id,
    scheduledAwaitingTaskList,
    props.siteKey?.inactiveUsers,
    props.siteKey?.unapprovedUsers,
    props.vehicleList,
    resourcesMode,
    zoneList,
  ]);
  calendarEventsRef.current = getCalendarEvents();

  useEffect(() => {
    if (currentEvent.length === 0) return;

    // Show the current event regardless of what day is selected
    for (const event of currentEvent) {
      if (event.tags.taskID === clonedInitialTask.id) {
        const { eventStart, eventEnd } = getEventServiceWindowForSelectedDate({
          start: event.start,
          end: event.end,
          selectedDate: DateTime.fromJSDate(scheduleDatetime),
        });
        event.start = eventStart;
        event.end = eventEnd;
      }
    }
    setCurrentEvent(currentEvent);
  }, [clonedInitialTask.id, currentEvent, scheduleDatetime]);

  useEffect(() => {
    if (currentEvent.length !== 0) return;

    const currentTaskEvent = getCurrentEvent({
      mode: resourcesMode,
      task: clonedInitialTask,
      zoneList,
      startDate: DateTime.fromJSDate(scheduleDatetime),
      endDate: DateTime.fromJSDate(scheduleDatetime).plus({
        hours: serviceWindowDuration,
      }),
      vehicleList: props.vehicleList,
      inactiveUIDs: props.siteKey?.inactiveUsers ?? [],
      unapprovedUIDs: props.siteKey?.unapprovedUsers ?? [],
    });
    setCurrentEvent(currentTaskEvent);
  }, [
    clonedInitialTask,
    currentEvent.length,
    props.siteKey?.inactiveUsers,
    props.siteKey?.unapprovedUsers,
    props.vehicleList,
    resourcesMode,
    scheduleDatetime,
    serviceWindowDuration,
    zoneList,
  ]);

  useEffect(() => {
    const assignedUsers = props.task.taskSpecificDetails["assignedTo"];
    const assignedResourceList: string[] = [];
    if (
      assignedUsers !== null &&
      Array.isArray(assignedUsers) &&
      assignedUsers.length > 0
    ) {
      for (let userId of assignedUsers) {
        if (typeof userId === "string") {
          if (
            props.siteKey?.inactiveUsers?.includes(userId) ||
            props.siteKey?.unapprovedUsers.includes(userId)
          ) {
            userId = "Unassigned";
          }
          const resource = userId ?? "Unassigned";
          assignedResourceList.push(resource);
        }
      }
      setAssignedTo(assignedResourceList);
    }
  }, [
    props.siteKey?.inactiveUsers,
    props.siteKey?.unapprovedUsers,
    props.task,
  ]);

  useEffect(() => {
    function getCalendarEventListBySelectedDate() {
      // typeguard
      if (!props.siteKeyUserPermissions) {
        logger.debug("No userPermissions yet...");
        return undefined;
      }

      logger.debug(
        `Running useEffect: ${getCalendarEventListBySelectedDate.name}.`,
      );

      //setting the date selected to doesn't care about the hours selected but
      // check the entire day
      const startDate = DateTime.fromJSDate(scheduleDatetime).set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      const endDate = DateTime.fromJSDate(scheduleDatetime).set({
        hour: 23,
        minute: 59,
        second: 59,
      });

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubscribeCalendarEventList =
        DbRead.calendarEvents.subscribeByDate({
          siteKey: props.siteKey.id,
          permissions: props.siteKeyUserPermissions,
          userID: firebaseUser.uid,
          startDate: convertLuxonDTToFSTimestamp(startDate),
          endDate: convertLuxonDTToFSTimestamp(endDate),
          onChange: (events) => {
            setStiltCalendarEvents(events);
            logger.debug(`useEffect stiltCalendarEvents: ${events}`);
          },
          onError: (error) =>
            logger.error(
              `Error in ${getCalendarEventListBySelectedDate.name}: ${error.message}`,
            ),
        });

      return unsubscribeCalendarEventList;
    }

    const unsubscribe = getCalendarEventListBySelectedDate();

    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(SchedulingContainer) getCalendarEventListBySelectedDate useEffect return ƒn just invoked.",
      );

      if (unsubscribe) unsubscribe();
    };
  }, [
    firebaseUser.uid,
    scheduleDatetime,
    props.siteKey,
    props.siteKeyUserPermissions,
  ]);

  const stiltEvents: CalendarEvent[] = [];
  if (stiltCalendarEvents.length !== 0) {
    const result = generateStiltCalendarEvents({
      stiltCalendarEvents,
      moveDisabled: true,
      resizeDisabled: true,
    });

    stiltEvents.push(...result);
  }

  const [dailyVehicleUsersMap, setDailyVehicleUsersMap] = useState<
    ExistingAssignedRoute["vehicleUsersMap"]
  >({});

  useEffect(() => {
    function getDailyVehicleUsersMap() {
      // typeguard
      if (!props.siteKeyUserPermissions) {
        logger.debug("No userPermissions yet...");
        return undefined;
      }
      logger.debug(`Running useEffect: ${getDailyVehicleUsersMap.name}.`);

      // Query for daily assignedRoute via realtime updates.
      const unsubscribeDailyAssignment =
        DbRead.assignedRoutes.subscribeDailyVehicleUsersMap({
          siteKey: props.siteKey.id,
          isoDate: DateTime.fromJSDate(scheduleDatetime).toISODate(),
          onChange: setDailyVehicleUsersMap,
          onError: (error) => {
            logger.error(
              `Error in ${getDailyVehicleUsersMap.name}: ${error.message}`,
            );
          },
        });

      return unsubscribeDailyAssignment;
    }

    const unsubscribe = getDailyVehicleUsersMap();

    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(SchedulingContainer) getDailyAssignment useEffect return ƒn just invoked.",
      );
      if (unsubscribe) unsubscribe();
    };
  }, [scheduleDatetime, props.siteKey, props.siteKeyUserPermissions]);

  async function onRescheduleTask() {
    setIsSubmitting(true);

    const xTask: ExistingTask = cloneDeep(props.task);

    xTask.urgent = scheduledByPriority === strings.URGENT;
    xTask.nextOpportunity = scheduledByPriority === strings.NEXT_OPPORTUNITY;
    xTask.timestampScheduled = convertJSDateToFSTimestamp(scheduleDatetime);

    let updatedTask: ExistingTask;
    try {
      // Updates taskStatus and timestampAwaitingStart (in this case) if necessary
      updatedTask = await updateTaskFromScheduleDateChange({
        task: xTask,
        scheduleVal: scheduledByPriority ? scheduledByPriority : null,
        newTaskCreation: false,
        siteKey: props.siteKey,
        siteKeyUserPermissions: props.siteKeyUserPermissions,
      });
    } catch (e) {
      logger.error(
        "error while executing updateTaskFromScheduleDateChange:",
        e,
      );
      setIsSubmitting(false);
      setShowErrorMessage(true);
      return;
    }

    const {
      scheduledServiceWindowStart,
      scheduledServiceWindowEnd,
      timestampScheduled,
    } = getServiceWindowAndScheduledTS({
      scheduledByPriority,
      scheduledDateTime: scheduleDatetime,
      serviceWindowDuration,
    });

    updatedTask.taskSpecificDetails.scheduledServiceWindowStart =
      scheduledServiceWindowStart;
    updatedTask.taskSpecificDetails.scheduledServiceWindowEnd =
      scheduledServiceWindowEnd;
    updatedTask.timestampScheduled = timestampScheduled;
    updatedTask.taskSpecificDetails["assignedTo"] = assignedTo;
    updatedTask.sendJobReminderEmail = sendJobReminderEmail;

    if (
      props.siteKey.customizations.vehiclesEnabled === true &&
      assignedVehicleID != null
    ) {
      updatedTask.taskSpecificDetails.assignedVehicleID = assignedVehicleID;
    }

    const shouldOpenTSChangeDialog = checkTsdRequired({
      task: updatedTask,
      siteKeyDoc: props.siteKey,
      previousTaskStatus: clonedInitialTask.taskStatus,
    });

    if (shouldOpenTSChangeDialog) {
      setIsSubmitting(false);
      props.onClose();
      await props.handleOpenTaskStatusChangeDialogDueToScheduleChange({
        updatedTask,
        originalTask: clonedInitialTask,
      });
      return;
    }

    try {
      await props.handleRescheduleTask(updatedTask);
      setIsSubmitting(false);
      props.onClose();
    } catch (e) {
      logger.error("error while executing props.handleRescheduleTask:", e);
      setShowErrorMessage(true);
      setIsSubmitting(false);
    }
  }

  const confirmationReminderToggles = props.siteKey?.customizations
    .sendTaskReminderToCustomers &&
    props.siteKey.customizations.sendTaskReminderToCustomers && (
      <StyledSwitchGroup
        tooltipText={strings.SEND_24H_REMINDER_EMAIL_HELP_TEXT}
        readableName={strings.SEND_24H_REMINDER_EMAIL}
        onBlur={() => {}}
        onChange={() => setSendJobReminderEmail(!sendJobReminderEmail)}
        checked={sendJobReminderEmail}
        id="sendJobReminderEmail"
        name={"sendJobReminderEmail"}
      />
    );

  const dotElement = getDotColorWIPTask(
    clonedInitialTask.title,
    clonedInitialTask.customerLocation ?? null,
    zoneList,
  );

  const showCalendarButton =
    props.siteKey.customizations.schedulingDispatchEnabled === true ? (
      <div className="my-4 flex items-center gap-4">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase xs:w-fit"
          onClick={() => setShowCalendar(!showCalendar)}
        >
          {!showCalendar
            ? strings.buttons.SHOW_DISPATCH_BOARD
            : strings.buttons.HIDE_DISPATCH_BOARD}
        </BaseButtonSecondary>
        {dotElement}
      </div>
    ) : null;

  const localStorageTableOrientation = localStorage.getItem(
    "tableOrientationIsHorizontal",
  );

  const tableOrientationIsHorizontal = localStorageTableOrientation
    ? JSON.parse(localStorageTableOrientation) === true
    : false;

  // date used for the daypilot calendar
  const calendarDateString = convertFSTimestampToLuxonDT(
    convertJSDateToFSTimestamp(scheduleDatetime),
  ).toISODate();

  const calendarConfig: CalendarProps = props.siteKey
    ? {
        bubble: stiltTaskBubble(props.siteKey),
        cellDuration: 60,
        cellHeight: 50,
        // columnWidth: 100,
        // columnWidthSpec: "Fixed" as any,
        headerHeight: 60,
        headerTextWrappingEnabled: false,
        startDate: calendarDateString,
        dynamicEventRendering: "Disabled" as any,
        timeRangeSelectedHandling: "Enabled" as any,
        useEventBoxes: "Never" as any,
        viewType: "Resources" as any,
        onBeforeHeaderRender: (
          args: DayPilot.CalendarBeforeHeaderRenderArgs,
        ) => {
          customizeHeader(
            args,
            [...calendarEventsRef.current, ...currentEvent],
            resourcesMode,
            DateTime.fromJSDate(scheduleDatetime),
          );
        },
        onBeforeEventRender: (args: any) => {
          if (args.data.backColor) {
            args.data.fontColor = getFontColorForBackground(
              args.data.backColor,
            );
          }
          if (args.data.dotColor) {
            args.data.areas = [
              {
                right: 2,
                top: "calc(100% - 10px)",
                width: 8,
                height: 8,
                symbol: "icons/daypilot.svg#checkmark-2",
                backColor: args.data.dotColor,
                fontColor: "#ffffff",
                padding: 2,
                style: "border-radius: 50%",
              },
            ];
          }
        },
        onEventMoved: (args: DayPilot.CalendarEventMovedArgs) => {
          handleEventMoved({
            daypilot: args,
            resourcesMode,
            task: clonedInitialTask,
            setAssignedTo,
            setScheduleDatetime,
            siteKey: props.siteKey,
            setAssignedVehicleID,
            dailyVehicleUsersMap,
          });
        },
        onEventResized: (args: DayPilot.CalendarEventResizedArgs) => {
          const start = args.newStart.toString();
          const end = args.newEnd.toString();
          const timeDiff = getTimeDifferenceInHours({
            startDate: start,
            endDate: end,
          });
          const startDate = new Date(start);
          setScheduleDatetime(startDate);
          setServiceWindowDuration(Math.round(timeDiff));
        },
      }
    : {};
  const nowISO = DateTime.now().toISO({
    includeOffset: false,
    suppressMilliseconds: true,
  });
  const cellWidth = 100;
  let scrollHour = DateTime.now().hour.valueOf();
  // Go back 1 hour if not already at midnight
  if (scrollHour !== 0) {
    scrollHour = scrollHour - 1;
  }

  const calendarConfigScheduler: SchedulerProps = props.siteKey
    ? {
        businessBeginsHour: 6,
        businessEndsHour: 20,
        cellWidth: cellWidth,
        days: 7,
        eventStackingLineHeight: 85,
        eventTextWrappingEnabled: true,
        scale: "Hour" as any,
        scrollX: cellWidth * scrollHour,
        separators: [{ color: "red", location: nowISO }],
        startDate: calendarDateString,
        dynamicEventRendering: "Disabled" as any,
        timeHeaders: [
          { groupBy: "Day" as any, format: "dddd - M/d/yyyy" },
          { groupBy: "Cell" as any },
        ],
        treeEnabled: true,
        treePreventParentUsage: true,
        useEventBoxes: "Never" as any,
        onBeforeCellRender: (args: any) => {
          if (args.cell.isParent) {
            args.cell.properties.backColor = "#dddddd";
          }
        },
        onBeforeEventRender: (args: any) => {
          if (args.data.backColor) {
            args.data.fontColor = getFontColorForBackground(
              args.data.backColor,
            );
          }
          if (args.data.dotColor) {
            args.data.areas = [
              {
                right: 2,
                top: "calc(100% - 10px)",
                width: 8,
                height: 8,
                symbol: "icons/daypilot.svg#checkmark-2",
                backColor: args.data.dotColor,
                fontColor: "#ffffff",
                padding: 2,
                style: "border-radius: 50%",
              },
            ];
          }
        },
        onEventMoved: (args: DayPilot.SchedulerEventMovedArgs) => {
          handleEventMoved({
            daypilot: args,
            resourcesMode,
            task: clonedInitialTask,
            setAssignedTo,
            setScheduleDatetime,
            siteKey: props.siteKey,
            setAssignedVehicleID,
            dailyVehicleUsersMap,
          });
        },
        onEventResized: (args: DayPilot.SchedulerEventResizedArgs) => {
          const start = args.newStart.toString();
          const end = args.newEnd.toString();
          const timeDiff = getTimeDifferenceInHours({
            startDate: start,
            endDate: end,
          });
          const startDate = new Date(start);
          setScheduleDatetime(startDate);
          setServiceWindowDuration(Math.round(timeDiff));
        },
        bubble: stiltTaskBubble(props.siteKey),
      }
    : {};

  const resourcesForCalendar = generateResourcesForDayPilotCalendar(
    props.vehicleList,
    props.siteKeyUsersList,
    props.siteLocationList,
    resourcesMode,
    props.permissionsMap,
    dailyVehicleUsersMap,
    props.task,
  );

  const resourcesForScheduler = generateResourcesForDayPilotScheduler(
    props.vehicleList,
    props.siteKeyUsersList,
    props.siteLocationList,
    resourcesMode,
    props.permissionsMap,
  );

  const taskCalendar = showCalendar ? (
    <DayPilotCalendar
      {...calendarConfig}
      onTimeRangeSelected={(args) => args.control.clearSelection()} // prevent adding a new event, only allow moving existing events
      columns={resourcesForCalendar}
      events={[...calendarEventsRef.current, ...stiltEvents, ...currentEvent]}
    />
  ) : null;

  const taskScheduler = showCalendar ? (
    <DayPilotScheduler
      {...calendarConfigScheduler}
      onTimeRangeSelected={(args) => args.control.clearSelection()} // prevent adding a new event, only allow moving existing events
      resources={resourcesForScheduler}
      events={[...calendarEventsRef.current, ...stiltEvents, ...currentEvent]}
    />
  ) : null;

  return (
    <BaseModal
      open={props.isOpen}
      closeModal={props.onClose}
      allowOverflowY={true}
      title={
        <div className="relative rounded-t-lg bg-primary p-6 text-xl font-semibold text-white lg:px-8">
          {strings.RESCHEDULE_TASK}
        </div>
      }
      parentDivStyles="text-left max-w-md lg:max-w-4xl"
    >
      <div className="px-6 pb-8 lg:px-8 lg:pb-10 lg:pt-2">
        <SchedulingSection
          dateAndTime={scheduleDatetime}
          setDateAndTime={setScheduleDatetime}
          duration={serviceWindowDuration}
          setDuration={setServiceWindowDuration}
          siteKeyDoc={props.siteKey}
          handleClickScheduledByPriority={(val) => setScheduledByPriority(val)}
          emailReminder={confirmationReminderToggles}
        />
        <div className="mt-4 text-center text-sm">
          {showErrorMessage && (
            <StyledMessage type="error">
              {{
                message: strings.ERROR_RESCHEDULING_TASK,
                icon: (
                  <XCircleIcon
                    className="h-8 w-8 text-red-900"
                    aria-hidden="true"
                  />
                ),
              }}
            </StyledMessage>
          )}
        </div>

        {showCalendarButton}
        {tableOrientationIsHorizontal ? taskScheduler : taskCalendar}
        {/* ACTION BUTTONS */}
        <div className="mt-4 flex w-full flex-col items-center justify-between gap-6 xs:flex-row ">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase xs:w-fit"
            onClick={props.onClose}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_UPDATING}
            className="w-full justify-center uppercase xs:w-fit"
            onClick={onRescheduleTask}
          >
            {strings.buttons.UPDATE}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}

/** returns ISO8601 datetime strings */
function getEventServiceWindowForSelectedDate(args: {
  start: CalendarEvent["start"];
  end: CalendarEvent["end"];
  selectedDate: DateTime;
}): { eventStart: string; eventEnd: string } {
  const eventStart = DateTime.fromISO(args.start);
  const eventEnd = DateTime.fromISO(args.end);

  const start = args.selectedDate.set({
    hour: eventStart.hour,
    minute: eventStart.minute,
    second: eventStart.second,
  });
  const end = args.selectedDate.set({
    hour: eventEnd.hour,
    minute: eventEnd.minute,
    second: eventEnd.second,
  });

  return {
    eventStart: start.toISO({
      includeOffset: false,
      suppressMilliseconds: true,
    }),
    eventEnd: end.toISO({
      includeOffset: false,
      suppressMilliseconds: true,
    }),
  };
}

function handleEventMoved(args: {
  daypilot: DayPilot.CalendarEventMovedArgs | DayPilot.SchedulerEventMovedArgs;
  setScheduleDatetime: (date: Date) => void;
  resourcesMode: string;
  task: ExistingTask;
  setAssignedTo: (assignedTo: string[]) => void;
  siteKey: ExistingSiteKey;
  setAssignedVehicleID: (vehicleID: string) => void;
  dailyVehicleUsersMap: ExistingAssignedRoute["vehicleUsersMap"];
}): void {
  const startDate = new Date(args.daypilot.newStart.toString());
  args.setScheduleDatetime(startDate);

  const newResourceID = args.daypilot.newResource.toString();
  const r = args.daypilot.e?.data?.tags?.resourceID; // without storing it in a variable first, TS didn't recognize that originalResourceID is a string
  const originalResourceID = typeof r === "string" ? r : "Unassigned";
  // console.log({ originalResourceID });
  // console.log({ newResourceID });

  if (args.resourcesMode === "technicians") {
    const assignedTo = args.task.taskSpecificDetails?.assignedTo;
    if (!isStringArray(assignedTo)) return;

    const index = assignedTo.indexOf(originalResourceID, 0);
    if (index > -1) assignedTo.splice(index, 1);

    assignedTo.push(newResourceID);
    args.setAssignedTo(assignedTo);
    return;
  } else if (
    args.resourcesMode === "vehicles" &&
    args.siteKey.customizations.vehiclesEnabled === true
  ) {
    if (newResourceID === "Unassigned") {
      args.setAssignedVehicleID("");
      args.setAssignedTo([]);
      return;
    }

    // newResourceID is not unassigned --
    args.setAssignedVehicleID(newResourceID);
    if (
      args.dailyVehicleUsersMap[newResourceID] != null &&
      args.dailyVehicleUsersMap[newResourceID].length > 0
    ) {
      args.setAssignedTo(args.dailyVehicleUsersMap[newResourceID]);
    } else {
      args.setAssignedTo([]);
    }
  }
}

/**
 * Most of the time, will return a single event (as a list).
 *
 * When in "technicians" mode, and there are multiple assigned users,
 * will return multiple events.
 */
function getCurrentEvent(args: {
  mode: string | null;
  task: ExistingTaskWithCustomerLocation;
  zoneList: ExistingZone[];
  startDate: DateTime;
  endDate: DateTime;
  vehicleList: ExistingVehicle[];
  inactiveUIDs: string[];
  unapprovedUIDs: string[];
}): CalendarEvent[] {
  const startDateString = args.startDate.toISO({
    includeOffset: false,
    suppressMilliseconds: true,
  });
  const endDateString = args.endDate.toISO({
    includeOffset: false,
    suppressMilliseconds: true,
  });

  const dotColor = getDotColor(args.task, args.zoneList);
  const backColor = getBackColor(args.task, args.zoneList);

  const event: CalendarEvent = {
    id: 69420,
    start: startDateString,
    end: endDateString,
    resource: "Unassigned",
    text: args.task.title,
    barColor: "#FF00FF",
    backColor: backColor,
    dotColor: dotColor,
    portable: args.task.taskSpecificDetails["portableJob"]
      ? (args.task.taskSpecificDetails["portableJob"] as boolean)
      : false,
    tags: {
      taskID: args.task.id,
      resourceID: "Unassigned",
      craftRecordRefPath: args.task.craftRecordID,
      task: args.task,
    },
    moveDisabled: false,
    resizeDisabled: false,
  };

  if (args.mode === "vehicles") {
    const tsdVehicleID = args.task.taskSpecificDetails["assignedVehicleID"];
    const assignedVehicle = args.vehicleList.find(
      (vehicle) => vehicle.id === tsdVehicleID,
    );
    if (assignedVehicle) {
      event.resource = assignedVehicle.id;
      event.tags.resourceID = assignedVehicle.id;
    }
    return [event];
  }

  if (args.mode === "technicians") {
    const assignedUsers = args.task.taskSpecificDetails["assignedTo"];
    const isNonEmptyList =
      assignedUsers !== null &&
      Array.isArray(assignedUsers) &&
      assignedUsers.length > 0;

    if (isNonEmptyList) {
      const events: CalendarEvent[] = [];
      for (const uid of assignedUsers) {
        if (
          typeof uid !== "string" ||
          args.inactiveUIDs.includes(uid) ||
          args.unapprovedUIDs.includes(uid)
        ) {
          continue;
        }

        const xevent = cloneDeep(event);
        xevent.resource = uid;
        xevent.tags.resourceID = uid;
        xevent.id += 1;
        events.push(xevent);
      }
      return events;
    }
  }

  /**
   * FIXME:
   * mode = technicians
   * more than one assigned user
   */

  return [event];
}
