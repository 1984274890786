//Libs
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "firebase/auth";
import cloneDeep from "lodash/cloneDeep";
import { DocumentData, Timestamp } from "firebase/firestore";
import { useMutation } from "react-query";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";

//Local
import * as strings from "../../strings";
import { ExistingMembership } from "../../models/membership";
import {
  CardOnFile,
  ExistingCustomer,
  ExistingCustomerUpdate,
} from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { DbRead, DbWrite } from "../../database";
import { useNavToCreateTask } from "../../navigation";
import { ExistingTask } from "../../models/task";
import { EventTypes } from "../../models/event-types";
import {
  convertToReadableTimestamp,
  convertToReadableTimestampDate,
} from "../../assets/js/convertToReadableTimestamp";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { WORK_RECORD_AND_TASKS_URL } from "../../urls";
import {
  ExistingStiltInvoice,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  TemplatePaymentTerm,
} from "../../models/invoice";
import { getReadableTaskStatus } from "../../models/task-status";
import { getTaskStatusPillClasses } from "../../assets/js/tasks";
import { useAuthStore } from "../../store/firebase-auth";
import { logger } from "../../logging";
import MembershipDetailsPage from "./MembershipDetailsPage";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  getEventIcon,
  getEventIconColor,
} from "../../components/WorkRecordAndTasks/functions";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { ExistingCraftRecord } from "../../models/craft-record";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { nextExpectedTaskDate } from "./MembershipsListPage";
import SendCustomEmailDialog from "../../components/customers/SendCustomEmailDialog";
import { getEmailList } from "../../utils/getEmailList";
import MembershipDetailsActionDropdown, {
  MembershipDetailsActions,
} from "../../components/Memberships/MembershipDetailsActionDropdown";
import ManageCardsOnFileDialog from "../../components/Payments/ManageCardsOnFileDialog";
import EditMembershipDialog from "../../components/Memberships/EditMembershipDialog";
import ViewInvoiceDialog, {
  ViewInvoiceDialogProps,
} from "../../components/Payments/ViewInvoiceDialog";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import HandlePaymentDialog from "../../components/Invoices/HandlePaymentDialog";
import {
  APIPaymentSavedCard,
  paymentMethods,
  StiltPayment_CreateAPI,
  StiltPaymentManager,
} from "../../models/stilt-payment";
import { createToastMessageID } from "../../utils/createToastMessageID";
import { useToastMessageStore } from "../../store/toast-messages";
import { generatePaymentUniqueLink } from "../../assets/js/generatePaymentUniqueLink";
import EditInvoiceDialog from "../../components/Invoices/EditInvoiceDialog";
import { SchedulingButton } from "../Scheduling/SchedulingContainer";
import BaseInputSelect from "../../components/BaseInputSelect";
import { NewManualPayment } from "../../components/Invoices/RecordManualPaymentDialog";
import { diffObjects } from "../../assets/js/object-diff";
interface Props {
  siteKey: string;
}

export default function MembershipDetailsContainer(props: Props) {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;

  // Extract membershipID from the URL
  type UrlParams = { membershipID: string };
  const data = useParams<UrlParams>();
  const membershipID = data.membershipID;
  if (typeof membershipID !== "string") {
    throw new Error(`membershipID was not a string: ${membershipID}`);
  }

  const navToCreateTask = useNavToCreateTask();
  const navigate = useNavigate();

  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  const [customerDoc, setCustomerDoc] = useState<ExistingCustomer | null>(null);
  const [customerLocationDoc, setCustomerLocationDoc] =
    useState<ExistingCustomerLocation | null>(null);

  const [membershipDoc, setMembershipDoc] = useState<ExistingMembership | null>(
    null,
  );
  const [membershipInvoice, setMembershipInvoice] =
    useState<ExistingStiltInvoice | null>(null);
  const [nextTaskText, setNextTaskText] = useState<string | null>(null);

  const [membershipTemplateList, isLoadingMembershipTemplateList] =
    useMembershipTemplatesStore((state) => [
      state.membershipTemplates,
      state.loading,
    ]);

  const [tasksForCustomer, setTasksForCustomer] = useState<ExistingTask[]>([]);
  const [craftRecordsForCustomer, setCraftRecordsForCustomer] = useState<
    ExistingCraftRecord[]
  >([]);

  const [addMembershipLoading, setAddMembershipLoading] = useState<
    string | null
  >(null);

  const [membershipTasks, setMembershipTasks] = useState<ExistingTask[]>([]);
  const [nonMembershipTasks, setNonMembershipTasks] = useState<ExistingTask[]>(
    [],
  );
  // combining state for send email and manage cards - both dialogs won't be
  // open at the same time. the other available action (create task) will take
  // the user to a new page
  // adding dialog states for edit invoice and handle payment too - not present
  // in actions dropdown. dialog state for viewing the invoice is handled separately
  // (via invoiceDialogProps)
  const [isDialogOpen, setIsDialogOpen] = useState<
    | MembershipDetailsActions
    | "editInvoice"
    | "handlePayment"
    | "editMembership"
    | null
  >(null);

  const [dateStates, setDateStates] = useState<{
    datePicker: DateTime;
    dueDate: DateTime | null;
    issueDate: DateTime;
  }>({
    datePicker: DateTime.now(),
    dueDate: null,
    issueDate: DateTime.now(),
  });

  const [invoiceDialogProps, setInvoiceDialogProps] =
    useState<ViewInvoiceDialogProps | null>(null);

  const [paymentTerms, setPaymentTerms] = useState<string | null>(null);
  const templatesPaymentTerms: Record<string, TemplatePaymentTerm> =
    siteKeyDoc?.customizations.paymentTerms ?? {};

  function openInvoiceDialog(invoiceID: string): void {
    if (!siteKeyDoc) return;

    setInvoiceDialogProps({
      siteKeyID: props.siteKey,
      invoiceID,
      merchantName: siteKeyDoc.name,
      merchantLogoURL:
        siteKeyDoc.customizations.accounting?.merchantLogoURL ?? null,
    });
  }

  function goToWorkRecordAndTasksPage(
    craftRecordPath: ExistingTask["craftRecordID"],
  ) {
    const craftRecordID = craftRecordPath.split("/")[3];
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  const mutateUpdateCustomer = useMutation(
    async (update: ExistingCustomerUpdate) => DbWrite.customers.update(update),
  );

  useEffect(() => {
    function getMembership() {
      if (!membershipID) return undefined;

      const unsubscribe = DbRead.memberships.subscribe({
        membershipDocID: membershipID,
        siteKey: props.siteKey,
        onChange: setMembershipDoc,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getMembership();
    return () => unsubscribeFn && unsubscribeFn();
  }, [membershipID, props.siteKey]);

  useEffect(() => {
    function getInvoiceForMembership() {
      if (!membershipDoc) return undefined;

      const unsubscribe = DbRead.invoices.subscribeByMembershipID({
        membershipID: membershipDoc.id,
        siteKey: props.siteKey,
        onChange: setMembershipInvoice,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getInvoiceForMembership();
    return () => unsubscribeFn && unsubscribeFn();
  }, [membershipDoc, props.siteKey]);

  useEffect(() => {
    function getCraftRecords() {
      if (!customerDoc) return undefined;
      const unsubscribe = DbRead.parentRecords.subscribeAllByCustomerID({
        customerID: customerDoc.id,
        siteKey: props.siteKey,
        onChange: setCraftRecordsForCustomer,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCraftRecords();
    return () => unsubscribeFn && unsubscribeFn();
  }, [customerDoc, props.siteKey]);

  useEffect(() => {
    async function getTasks() {
      if (!membershipDoc || !userPermissions) return;
      const tasks = await DbRead.tasks.getSingleCustomerTasks({
        permissions: userPermissions,
        customerID: membershipDoc.customerID,
        siteKey: props.siteKey,
      });
      const sortedTasks = tasks.sort((a, b) => {
        if (!!a.timestampScheduled && b.timestampScheduled) {
          return (
            b.timestampScheduled?.toMillis() - a.timestampScheduled.toMillis()
          );
        } else {
          return 0;
        }
      });
      setTasksForCustomer(sortedTasks);
    }

    getTasks();
  }, [userPermissions, membershipDoc, props.siteKey]);

  useEffect(() => {
    function getCustomer() {
      if (!membershipDoc) return undefined;
      const unsubscribe = DbRead.customers.subscribe({
        customerID: membershipDoc.customerID,
        siteKey: props.siteKey,
        onChange: setCustomerDoc,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomer();
    return () => unsubscribeFn && unsubscribeFn();
  }, [membershipDoc, props.siteKey]);

  useEffect(() => {
    function getCustomerLocation() {
      if (!membershipDoc?.customerLocationID) return undefined;
      const unsubscribe = DbRead.customerLocations.subscribe({
        customerLocationID: membershipDoc.customerLocationID,
        siteKey: props.siteKey,
        onChange: setCustomerLocationDoc,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomerLocation();
    return () => unsubscribeFn && unsubscribeFn();
  }, [membershipDoc, props.siteKey]);

  useEffect(() => {
    if (tasksForCustomer.length === 0) return;

    const membershipResult = getMembershipTasks(tasksForCustomer);
    const nonMembershipResult = getNonMembershipTasks(tasksForCustomer);

    setMembershipTasks(membershipResult);
    setNonMembershipTasks(nonMembershipResult);
  }, [tasksForCustomer]); // FIXME:

  useEffect(() => {
    if (!membershipDoc || !membershipTemplate) return;
    let nextTaskText = null;
    if (areAllTasksCreated()) {
      nextTaskText = `All tasks have been created for this membership`;
    } else if (membershipDoc?.nextScheduledTaskDate) {
      nextTaskText = `The next task is scheduled for 
          ${convertToReadableTimestampDate(membershipDoc?.nextScheduledTaskDate)}`;
    } else {
      nextTaskText = `The next task should be on ${nextExpectedTaskDate([membershipTemplate], membershipDoc)} but it hasn't been created yet.`;
      // const nextTaskDate = getDateOfWhenNextTaskShouldBeGenerated(
      //   membershipDoc,
      //   membershipTemplate,
      // );
      // if (nextTaskDate === null) {
      //   nextTaskText = ``;
      // } else {
      //   nextTaskText = `The next task should be scheduled around
      //   ${convertToReadableTimestampDate(nextTaskDate)}`;
      // }
    }

    setNextTaskText(nextTaskText);
  }, [tasksForCustomer]); // FIXME:

  /** delete the given card on file. */
  function handleDeleteCard(cardToDelete: CardOnFile): void {
    if (!customerDoc || !customerDoc.cardsOnFile) return;
    if (!userPermissions || !userPermissions.permissions.isSiteAdmin) return;

    const foundIndex = customerDoc.cardsOnFile.findIndex(
      (c) =>
        c.expiry === cardToDelete.expiry &&
        c.lastFour === cardToDelete.lastFour &&
        c.name === cardToDelete.name &&
        c.token === cardToDelete.token &&
        c.type === cardToDelete.type,
    );
    const updatedCards = [...customerDoc.cardsOnFile];
    updatedCards.splice(foundIndex, 1);

    if (updatedCards.length + 1 !== customerDoc.cardsOnFile.length) {
      throw new Error(
        `Mismatch in card count: will not delete card ${JSON.stringify(cardToDelete, null, 2)} @ ${customerDoc.refPath}`,
      );
    }
    const update: ExistingCustomerUpdate = {
      id: customerDoc.id,
      refPath: customerDoc.refPath,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
      cardsOnFile: updatedCards,
    };

    logger.info(
      `handleDeleteCard: updating customer ${JSON.stringify(update, null, 2)}`,
    );
    mutateUpdateCustomer.mutate(update);
  }

  /** unset the primary card on file. */
  function handleUnsetPrimaryCard(): void {
    if (!customerDoc || !customerDoc.cardsOnFile) return;
    if (!userPermissions || !userPermissions.permissions.isSiteAdmin) return;
    const updatedCards: CardOnFile[] = [];

    for (const card of customerDoc.cardsOnFile) {
      updatedCards.push({
        ...card,
        isPrimary: false,
      });
    }
    const update: ExistingCustomerUpdate = {
      id: customerDoc.id,
      refPath: customerDoc.refPath,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
      cardsOnFile: updatedCards,
    };
    logger.info(
      `handleUnsetPrimaryCard: updating customer ${JSON.stringify(update, null, 2)}`,
    );
    mutateUpdateCustomer.mutate(update);
  }

  function updatePrimaryCardOnFile(card: CardOnFile): void {
    if (!customerDoc || !customerDoc.cardsOnFile) return;
    if (!userPermissions || !userPermissions.permissions.isSiteAdmin) return;
    const foundIndex = customerDoc.cardsOnFile.findIndex(
      (c) =>
        c.expiry === card.expiry &&
        c.lastFour === card.lastFour &&
        c.name === card.name &&
        c.token === card.token &&
        c.type === card.type,
    );

    const updatedCards: CardOnFile[] = [];
    for (let i = 0; i < customerDoc.cardsOnFile.length; i++) {
      updatedCards.push({
        ...customerDoc.cardsOnFile[i],
        isPrimary: i === foundIndex,
      });
    }

    const update: ExistingCustomerUpdate = {
      id: customerDoc.id,
      refPath: customerDoc.refPath,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
      cardsOnFile: updatedCards,
    };
    logger.info(
      `updatePrimaryCardOnFile: updating customer ${JSON.stringify(update, null, 2)}`,
    );
    mutateUpdateCustomer.mutate(update);
  }

  if (!customerDoc || !membershipDoc || isLoadingMembershipTemplateList) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const membershipTemplate: ExistingMembershipTemplate | undefined =
    membershipTemplateList.find(
      (m) => m.id === membershipDoc?.membershipTemplateID,
    );

  function areAllTasksCreated() {
    const totalTasks = membershipDoc?.tasks
      ? Object.keys(membershipDoc.tasks).length
      : 0;
    const templateTasks = membershipTemplate?.taskGeneration
      ? membershipTemplate.taskGeneration.length
      : 0;
    if (totalTasks >= templateTasks) {
      return true;
    }
    return false;
  }

  function shouldShowTaskButton() {
    // if the total tasks in the membershipDoc.tasks map is the same as the number of tasks in the membershipTemplate.taskGeneration array, then we should not show the button
    if (areAllTasksCreated()) {
      return false;
    }
    if (membershipDoc?.status !== "active") {
      return false;
    }
    return true;
  }

  const nextInvoiceText = membershipDoc?.nextInvoiceDate && (
    <div className="pt-2 text-right text-sm italic text-orange-800">
      The next invoice will be generated on{" "}
      {convertToReadableTimestampDate(membershipDoc?.nextInvoiceDate)}
    </div>
  );

  const nextTaskTextElement = nextTaskText && (
    <div className="pt-2 text-right text-sm italic text-orange-800">
      {nextTaskText}
    </div>
  );

  async function addMembershipToTask(task: ExistingTask, membershipID: string) {
    setAddMembershipLoading(task.id);

    const clonedMembershipTasks = cloneDeep(membershipTasks);
    const clonedNonMembershipTasks = cloneDeep(nonMembershipTasks);

    const newMembershipIDs = task.membershipIDs
      ? [...task.membershipIDs, membershipID]
      : [membershipID];

    const updateTask: DocumentData = {
      membershipIDs: newMembershipIDs,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
    };
    try {
      await DbWrite.tasks.update(updateTask, props.siteKey, task.id);
      logger.info(`Task updated - `, task.id);

      clonedMembershipTasks.push(task);
      const nonMembershipResult = clonedNonMembershipTasks.filter(
        (nonMembershipTask) => task.id !== nonMembershipTask.id,
      );

      setMembershipTasks(clonedMembershipTasks);
      setNonMembershipTasks(nonMembershipResult);
    } catch (error) {
      logger.error(`An error occurred during addMembershipToTask`, error);
    }
    setAddMembershipLoading(null);
  }

  const leftCaretClasses =
    "before:absolute before:-left-3 before:top-1/2 before:-translate-y-1/2 before:border-y-[12px] before:border-r-[12px] before:border-y-transparent before:border-r-gray-200 after:absolute after:-left-[10.5px] after:top-1/2 after:-translate-y-1/2 after:border-y-[12px] after:border-r-[12px] after:border-y-transparent after:border-r-white";

  function taskHistoryCard(
    task: ExistingTask,
    craftRecord?: ExistingCraftRecord,
  ) {
    return (
      // NOTE: task panel's vertical bar will "disappear" without the isolate class.
      <div key={task.id} className="relative isolate px-1 py-1">
        {/* VERTICAL BAR */}
        <span className="block before:absolute before:bottom-0 before:left-7 before:top-0 before:-z-10 before:h-full before:w-0.5 before:bg-gray-200"></span>
        <div className="flex items-center gap-2">
          {/* ICON */}
          <span
            className={`rounded-full p-2 ${getEventIconColor(
              EventTypes.NEW_TASK_ADDED,
            )}`}
          >
            {getEventIcon(EventTypes.NEW_TASK_ADDED)}
          </span>

          {/* EVENT CARD */}
          <div
            className={`${leftCaretClasses} relative w-full rounded-md border border-gray-200 px-3.5 py-2 text-sm text-gray-400 shadow-md hover:bg-gray-100`}
          >
            <button
              onClick={() => goToWorkRecordAndTasksPage(task.craftRecordID)}
              className={`flex w-full flex-col`}
            >
              <div className="flex w-full flex-row justify-between space-x-2">
                <div
                  className={`rounded-full px-2 py-1 text-xs font-medium ${getTaskStatusPillClasses(task.taskStatus)}`}
                >
                  {getReadableTaskStatus(task.taskStatus)}
                </div>
                {task.membershipIDs && task.membershipIDs.length > 0 && (
                  <div className="rounded-lg bg-blue-200 px-2 py-1 text-xs font-medium  text-black">
                    {strings.MEMBERSHIP_TASK}
                  </div>
                )}
              </div>
              <p className="pt-1 text-left font-bold text-gray-900">
                {task.title}
              </p>
              <p className="py-1 text-left italic text-gray-700">
                {craftRecord?.description ?? ""}
              </p>
            </button>
            <div className="flex w-full flex-row items-center justify-between">
              <p>
                {convertToReadableTimestamp(
                  task.timestampTaskCompleted ??
                    task.timestampScheduled ??
                    task.timestampCreated,
                )}
              </p>
              {membershipDoc &&
                !task.membershipIDs?.includes(membershipDoc.id) && (
                  <button
                    onClick={() => addMembershipToTask(task, membershipDoc.id)}
                    className="my-1 flex w-fit justify-center rounded-lg bg-orange-200 px-2 py-1 text-xs font-medium text-black"
                  >
                    {addMembershipLoading === task.id ? (
                      <LoadingSpinner marginClass="mx-2" />
                    ) : (
                      strings.CONFIRM_TASK_FOR_MEMBERSHIP
                    )}
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getMembershipTasks(tasks: ExistingTask[]) {
    if (membershipDoc === null) {
      return [];
    }
    // Return list of tasks where the membershipIDs array includes the membershipDoc.id
    return tasks
      .filter((t) => t.membershipIDs?.includes(membershipDoc.id))
      .sort((a, b) => {
        if (!!a.timestampScheduled && b.timestampScheduled) {
          return (
            b.timestampScheduled?.toMillis() - a.timestampScheduled.toMillis()
          );
        } else {
          return 0;
        }
      });
  }

  function getNonMembershipTasks(tasks: ExistingTask[]) {
    if (membershipDoc === null) {
      return [];
    }
    // Return list of tasks where the membershipIDs array includes the membershipDoc.id
    return tasks
      .filter((t) => !t.membershipIDs?.includes(membershipDoc.id))
      .sort((a, b) => {
        if (!!a.timestampScheduled && b.timestampScheduled) {
          return (
            b.timestampScheduled?.toMillis() - a.timestampScheduled.toMillis()
          );
        } else {
          return 0;
        }
      });
  }

  const membershipTimeline = (
    <div className="space-y-4 lg:flex lg:flex-row lg:space-x-2 lg:space-y-0">
      <div className="w-full">
        <span>Tasks in this Membership</span>
        {membershipTasks.map((task) => {
          const craftRecord = craftRecordsForCustomer.find((craftRecord) => {
            const justTheID = task.craftRecordID.split("/")[3];
            return craftRecord.id === justTheID;
          });
          return taskHistoryCard(task, craftRecord);
        })}
      </div>
      <div className="border-t-2 border-gray-200 lg:border-l-2"></div>
      <div className="w-full">
        <span>All Other Tasks For Customer</span>
        {nonMembershipTasks.map((task) => {
          const craftRecord = craftRecordsForCustomer.find((craftRecord) => {
            const justTheID = task.craftRecordID.split("/")[3];
            return craftRecord.id === justTheID;
          });
          return taskHistoryCard(task, craftRecord);
        })}
      </div>
    </div>
  );

  const emailList = getEmailList(null, customerDoc);

  const sendCustomEmailDialog = (
    <SendCustomEmailDialog
      title={"Send An Email"}
      emailSubject={""}
      emailBody={""}
      closeDialog={() => setIsDialogOpen(null)}
      isDialogOpen={isDialogOpen === "sendCustomEmail"}
      customerID={customerDoc.id}
      customerEmailList={emailList}
      siteKey={props.siteKey}
    />
  );

  async function handleGoToPaymentPage(): Promise<void> {
    if (!membershipInvoice) return;
    const paymentLink = await generatePaymentUniqueLink(
      props.siteKey,
      membershipInvoice.id,
      "web",
    );
    if (paymentLink) {
      goToPaymentPage(paymentLink);
    } else {
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERROR_PAYMENT_LINK,
        type: "error",
      });
    }
  }

  async function emailInvoice(
    email: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!membershipInvoice) {
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_EMAILING_INVOICE,
        type: "error",
      });
      return;
    }
    if (email.length === 0) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    const paymentLink = await generatePaymentUniqueLink(
      props.siteKey,
      membershipInvoice.id,
      "email",
    );
    if (!paymentLink) {
      logger.error("emailInvoice: missing invoice link");
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_GEN_INVOICE_LINK,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.invoices.sendViaEmail({
        siteKey: props.siteKey,
        invoiceURL: paymentLink,
        customerEmailList: email,
        includeJobPhotos: includeJobPhotos,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
      setInvoiceDialogProps(null);
    } catch (e) {
      logger.error(`emailInvoice: ${JSON.stringify(e, null, 2)}`);
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.ERR_EMAILING_INVOICE,
        type: "error",
      });
    }
  }

  async function emailReceipt(emailList: string[]): Promise<void> {
    if (!membershipInvoice) return;
    if (emailList.length === 0) {
      throw Error("emailReceipt given empty email list");
    }
    try {
      await DbWrite.payments.emailReceipt({
        siteKeyID: props.siteKey,
        invoiceID: membershipInvoice.id,
        customerEmailList: emailList,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error(`emailReceipt: ${JSON.stringify(e, null, 2)}`);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT,
        dialog: false,
        type: "error",
      });
    }
  }

  async function sendEmailFromHandlePaymentDialog(
    emails: string[],
    shouldIncludePhotos: boolean,
  ): Promise<void> {
    if (!membershipInvoice) return;
    if (membershipInvoice.amountDue > 0) {
      await emailInvoice(emails, shouldIncludePhotos);
    } else {
      await emailReceipt(emails);
    }
  }

  if (siteKeyDocIsLoading || !membershipDoc || !membershipTemplate) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const editMembershipDialog = siteKeyDoc && (
    <EditMembershipDialog
      assetsEnabled={siteKeyDoc.customizations.assetsEnabled === true}
      membership={membershipDoc}
      template={membershipTemplate}
      isDialogOpen={isDialogOpen === "editMembership"}
      customer={customerDoc}
      customerLocation={customerLocationDoc}
      siteKey={props.siteKey}
      closeDialog={() => setIsDialogOpen(null)}
    />
  );

  const manageCardsOnFileDialog = (
    <ManageCardsOnFileDialog
      isDialogOpen={isDialogOpen === "manageCardsOnFile"}
      closeDialog={() => setIsDialogOpen(null)}
      customer={customerDoc}
      updatePrimaryCard={updatePrimaryCardOnFile}
      deleteCard={handleDeleteCard}
      unsetPrimaryCard={handleUnsetPrimaryCard}
    />
  );

  async function applyManualPayment(
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (!membershipInvoice) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: invoiceDialogProps != null,
        type: "error",
      });
      return;
    }

    const data: StiltPayment_CreateAPI = {
      ...formValues,
      stringTimestampPaymentMade: dateStates.datePicker.toISO(),
      customerID: membershipInvoice.customerID,
      billToCustomerID: membershipInvoice.billToCustomerID,
      locationID: membershipInvoice.locationID,
      invoiceID: membershipInvoice.id,
      customData: {},
      createdBy: firebaseUser.uid,
      siteKey: props.siteKey,
      deleted: false,
    };

    const validPayment = StiltPaymentManager.parseCreate(data);
    logger.info({ validPayment });

    try {
      await writeManualPayment(validPayment);
      if (
        validPayment.amount === membershipInvoice.amountDue &&
        membershipInvoice.status !== "draft" &&
        siteKeyDoc?.customizations.sendAutomatedReceiptToCustomers
      ) {
        if (membershipInvoice.email !== null) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT,
            dialog: invoiceDialogProps != null,
            type: "success",
          });
        } else {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_NO_EMAIL,
            dialog: invoiceDialogProps != null,
            type: "info",
          });
        }
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
          dialog: invoiceDialogProps != null,
          type: "success",
        });
      }
    } catch (e) {
      logger.error(`applyManualPayment: ${JSON.stringify(e, null, 2)}`);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: invoiceDialogProps != null,
        type: "error",
      });
      return;
    }
  }

  async function handleUpdateInvoice(
    updatedInvoice: Partial<ExistingStiltInvoice>,
  ) {
    if (!membershipInvoice) return;

    const updatedInvoiceFields: StiltInvoice_UpdateAPI = {
      ...updatedInvoice,
      paymentTerms: paymentTerms,
      dueDate: dateStates.dueDate ? dateStates.dueDate.toString() : null,
      issueDate: dateStates.issueDate.toString(),
    };

    const diffInvoiceValues: DocumentData = diffObjects(
      membershipInvoice,
      updatedInvoiceFields,
    ).diff;

    if (Object.keys(diffInvoiceValues).length === 0) return;

    const updatedValues: Partial<ExistingStiltInvoice> = {
      ...diffInvoiceValues,
      id: membershipInvoice.id,
      refPath: membershipInvoice.refPath,
    };

    const validInvoice = StiltInvoiceManager.parseUpdate(updatedValues);
    logger.info({ validInvoice });

    try {
      await updateInvoice(validInvoice);
      setInvoiceDialogProps(null);
      addToastMessage({
        id: createToastMessageID(),
        dialog: false,
        message: strings.successfulUpdate(
          membershipInvoice.invoiceNumber
            ? `Invoice #${membershipInvoice.invoiceNumber}`
            : "Invoice",
        ),
        type: "success",
      });
    } catch (e) {
      logger.error(`handleUpdateInvoice: ${JSON.stringify(e, null, 2)}`);
      addToastMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }
  }

  function handlePaymentTermsChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = event.target.value;
    if (value === "") {
      setPaymentTerms(null);
    } else {
      setPaymentTerms(value);
      const selectedPaymentTermTemplate = Object.entries(
        templatesPaymentTerms,
      ).find(([key, _]) => key === value);
      if (!selectedPaymentTermTemplate) return;
      const newDueDate = dateStates.issueDate.plus({
        days: selectedPaymentTermTemplate[1].daysUntilDue,
      });
      setDateStates((prev) => ({ ...prev, dueDate: newDueDate }));
    }
  }

  async function payWithCardOnFile(args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }): Promise<void> {
    if (!siteKeyDoc) throw Error("payWithCardOnFile missing siteKeyDoc");

    const processor = siteKeyDoc.customizations.accounting?.ziftAccountID
      ? "zift"
      : "paya";
    const data: APIPaymentSavedCard = {
      siteKeyID: props.siteKey,
      invoiceID: args.invoiceID,
      amount: args.amount,
      cardLastFour: args.lastFour,
      cardExpiry: args.expiry,
    };
    const valid = StiltPaymentManager.parseCreateWithSavedCard(data);
    await DbWrite.payments.createWithSavedCard(valid, processor);
  }

  const dueDatePicker = membershipInvoice && (
    <div className="flex flex-col">
      {dateStates.dueDate ? `Due Date` : "Due Date Not Selected"}
      <div className="flex items-center gap-4">
        <DatePicker
          selected={
            dateStates.dueDate
              ? dateStates.dueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setDateStates((prev) => ({ ...prev, dueDate: luxonDate }));
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const issueDatePicker = membershipInvoice && (
    <div className="flex flex-col">
      {dateStates.issueDate ? `Issue Date` : "Issue Date Not Selected"}
      <DatePicker
        selected={
          dateStates.issueDate
            ? dateStates.issueDate.toJSDate()
            : DateTime.now().toJSDate()
        }
        onChange={(date: Date) => {
          const luxonDate = DateTime.fromJSDate(date);
          setDateStates((prev) => ({ ...prev, issueDate: luxonDate }));
          setPaymentTerms(null);
        }}
        customInput={<SchedulingButton />}
      />
    </div>
  );

  const paymentTermsSelector = (
    <BaseInputSelect
      inputName="paymentTerms"
      text="Payment Terms"
      admin={true}
      required={true}
      value={paymentTerms === null ? "" : paymentTerms}
      onChange={handlePaymentTermsChange}
    >
      <option value="">None</option>
      {Object.entries(templatesPaymentTerms).map(([key, paymentTerm]) => {
        return (
          <option key={key} value={key}>
            {paymentTerm.title}
          </option>
        );
      })}
    </BaseInputSelect>
  );

  const editInvoiceDialog = membershipInvoice && (
    <EditInvoiceDialog
      isDialogOpen={isDialogOpen === "editInvoice"}
      closeDialog={() => setIsDialogOpen(null)}
      invoiceDoc={membershipInvoice}
      handleSave={handleUpdateInvoice}
      dueDate={dueDatePicker}
      issueDate={issueDatePicker}
      paymentTerms={paymentTermsSelector}
    />
  );

  const datePicker = membershipInvoice && (
    <DatePicker
      selected={dateStates.datePicker.toJSDate()}
      onChange={(date: Date) => {
        const luxonDate = DateTime.fromJSDate(date);
        setDateStates((prev) => ({ ...prev, datePicker: luxonDate }));
      }}
      showTimeSelect
      customInput={<SchedulingButton />}
    />
  );

  const handlePaymentDialog = isDialogOpen === "handlePayment" &&
    siteKeyDoc &&
    userPermissions &&
    membershipInvoice &&
    customerDoc && (
      <HandlePaymentDialog
        isDialogOpen={isDialogOpen === "handlePayment"}
        closeDialog={() => setIsDialogOpen(null)}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        invoiceID={membershipInvoice.id}
        invoiceStatus={membershipInvoice.status}
        invoiceAmount={membershipInvoice.amountDue}
        invoiceSentToCustomer={membershipInvoice.timestampSentToCustomer}
        customer={customerDoc}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(membershipInvoice, customerDoc)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: datePicker,
        }}
      </HandlePaymentDialog>
    );

  const viewInvoiceDialog = invoiceDialogProps && userPermissions && (
    <ViewInvoiceDialog
      isOpen={invoiceDialogProps != null}
      onClose={() => setInvoiceDialogProps(null)}
      {...invoiceDialogProps}
      handleRefund={async (paymentID, refundAmount) =>
        await handleRefund(props.siteKey, paymentID, refundAmount)
      }
      handlePayment={() => setIsDialogOpen("handlePayment")}
      userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
      editInvoice={() => setIsDialogOpen("editInvoice")}
      sendEmail={emailInvoice}
      deleteInvoice={handleDeleteInvoice}
      userDisplayNamesMap={userDisplayNamesMap}
      handleDeletePayment={handleDeletePayment}
    >
      {{
        EditInvoiceDialog: editInvoiceDialog,
        HandlePaymentDialog: handlePaymentDialog,
      }}
    </ViewInvoiceDialog>
  );

  function handleClickAction(actionType: MembershipDetailsActions): void {
    if (!membershipDoc || !customerDoc) {
      // skill issue
      throw Error("Expected membership and customer doc");
    }

    switch (actionType) {
      case "scheduleNextTask":
        navToCreateTask(
          null,
          membershipDoc.customerID,
          customerLocationDoc ?? undefined,
          undefined,
          {
            membershipTemplateID: membershipDoc.membershipTemplateID,
            membershipID: membershipDoc.id,
          },
        );
        break;
      case "sendCustomEmail":
        setIsDialogOpen("sendCustomEmail");
        break;
      case "manageCardsOnFile":
        setIsDialogOpen("manageCardsOnFile");
        break;
      default:
        const ec: never = actionType;
        throw new Error(`Unhandled action type: ${ec}`);
    }
  }

  const actionsDropdown = membershipDoc && customerDoc && userPermissions && (
    <MembershipDetailsActionDropdown
      handleClick={handleClickAction}
      shouldShowScheduleTaskAction={shouldShowTaskButton()}
      userIsSiteAdmin={userPermissions.permissions.isSiteAdmin}
    />
  );

  return (
    <>
      <MembershipDetailsPage
        customerDoc={customerDoc}
        membershipDoc={membershipDoc}
        membershipInvoice={membershipInvoice}
        membershipTemplate={membershipTemplate}
        openEditMembershipDialog={() => setIsDialogOpen("editMembership")}
        openInvoiceDialog={openInvoiceDialog}
        children={{
          actionsDropdown,
          membershipTimeline,
          editMembershipDialog,
          nextTaskTextElement,
          nextInvoiceText,
          sendCustomEmailDialog,
          manageCardsOnFileDialog,
        }}
      />
      {viewInvoiceDialog}
    </>
  );
}

async function handleDeletePayment(
  siteKeyID: string,
  paymentID: string,
): Promise<void> {
  return DbWrite.payments.delete(siteKeyID, paymentID);
}

async function handleDeleteInvoice(
  siteKeyID: string,
  invoiceID: string,
): Promise<void> {
  return DbWrite.invoices.delete(siteKeyID, invoiceID);
}

async function handleRefund(
  siteKey: string,
  paymentID: string,
  refundAmount: number,
): Promise<void> {
  return DbWrite.payments.issueRefund(siteKey, paymentID, refundAmount);
}

async function writeManualPayment(
  payment: StiltPayment_CreateAPI,
): Promise<void> {
  await DbWrite.payments.manualPayment(payment);
}

async function updateInvoice(invoice: StiltInvoice_UpdateAPI): Promise<void> {
  await DbWrite.invoices.update(invoice);
}
