//Libs
import { z } from "zod";
import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputText from "../BaseInputText";
import BaseModal from "../BaseModal";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { Timestamp } from "firebase/firestore";
import { Asset, AssetManager, ExistingAsset } from "../../models/asset";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CustomerTableDialog from "../customers/CustomerTableDialog";
import { CustomerDetails } from "../estimates/CustomerDetails";
import { CustomerLocationSection } from "../customers/CreateTask";
import { DbRead, DbWrite } from "../../database";
import DatePicker from "react-datepicker";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import { CustomDateInput } from "../DatePickerWithManualEntry";
import ButtonColored from "../ButtonColored";
import { logger } from "../../logging";
import ConfirmDeleteEquipmentDialog from "./ConfirmDeleteEquipmentDialog";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { SiteKeyLocationSection } from "../../components/SiteKeyLocationSection";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";

interface Props {
  //DATA
  isDialogOpen: boolean;
  customer: ExistingCustomer | null;
  customerLocation: ExistingCustomerLocation | null;
  customerLocationOptions: ExistingCustomerLocation[] | null;
  locationID: string | null;
  asset: ExistingAsset | null;
  siteKey: string;
  closeDialog: () => void;
  isSubmitting: boolean;
}

const NewAssetFormSchema = z.object({
  title: z.string().min(0).max(200),
  description: z.string().min(0).max(500).nullable().optional(),
  manufacturer: z.string().min(0).max(400).optional(),
  equipmentType: z.string().min(0).max(400).optional(),
  serialNumber: z.string().min(0).max(400).optional(),
  modelNumber: z.string().min(0).max(400).optional(),
  installationDate: z.instanceof(Timestamp).optional(),
  manufacturerWarrantyStart: z.instanceof(Timestamp).optional(),
  manufacturerWarrantyEnd: z.instanceof(Timestamp).optional(),
  serviceWarrantyStart: z.instanceof(Timestamp).optional(),
  serviceWarrantyEnd: z.instanceof(Timestamp).optional(),
});
export type NewAssetFormState = z.infer<typeof NewAssetFormSchema>;

export default function AddAssetDialog(props: Props) {
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const [asset, setAsset] = useState<ExistingAsset | null>(null);

  const [selectedCustomer, setSelectedCustomer] =
    useState<ExistingCustomer | null>(props.customer);
  const [selectedCustomerID, setSelectedCustomerID] = useState<string | null>(
    props.asset?.customerID ?? props.customer?.id ?? null,
  );
  const [customerLocationOptions, setCustomerLocationOptions] = useState<
    ExistingCustomerLocation[] | null
  >(props.customerLocationOptions);

  const [selectedCustomerLocation, setSelectedCustomerLocation] =
    useState<ExistingCustomerLocation | null>(props.customerLocation);
  const [selectedCustomerLocationID, setSelectedCustomerLocationID] = useState<
    string | null
  >(props.asset?.customerLocationID ?? props.customerLocation?.id ?? null);

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("Unkown Error");
  const [dialogStates, setDialogStates] = useState<Record<string, boolean>>({
    selectCustomerOpen: false,
  });

  const [
    confirmDeleteEquipmentDialogOpen,
    setConfirmDeleteEquipmentDialogOpen,
  ] = useState<boolean>(false);

  const siteKeyLocations = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );
  const [selectedSiteKeyLocationID, setSelectedSiteKeyLocationID] = useState<
    string | null
  >(props.asset?.locationID ?? null);

  useEffect(() => {
    function getSelectedCustomerLocations() {
      if (!selectedCustomer) return undefined;

      const unsubscribe = DbRead.customerLocations.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: selectedCustomer.id,
        onChange: setCustomerLocationOptions,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getSelectedCustomerLocations();
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, selectedCustomer]);

  useEffect(() => {
    if (customerLocationOptions && selectedCustomerLocationID) {
      const location = customerLocationOptions.find(
        (loc) => loc.id === selectedCustomerLocationID,
      );
      if (location) {
        setSelectedCustomerLocation(location);
      }
    }
  }, [customerLocationOptions, selectedCustomerLocationID]);

  const selectCustomerDialog = (
    <CustomerTableDialog
      isOpen={dialogStates.selectCustomerOpen}
      onClose={() => {
        setDialogStates({
          ...dialogStates,
          selectCustomerOpen: false,
        });
      }}
      siteKey={props.siteKey}
      onSelectCustomer={(c: ExistingCustomer) => {
        setSelectedCustomer(c);
        setSelectedCustomerID(c.id);
        setDialogStates({
          ...dialogStates,
          selectCustomerOpen: false,
        });
      }}
      addCustomerButton={null}
    />
  );

  const newAssetDefaultValues: Pick<
    Asset,
    | "title"
    | "description"
    | "manufacturer"
    | "equipmentType"
    | "serialNumber"
    | "modelNumber"
    | "installationDate"
    | "manufacturerWarrantyStart"
    | "manufacturerWarrantyEnd"
    | "serviceWarrantyStart"
    | "serviceWarrantyEnd"
  > = useMemo(() => {
    return {
      title: asset?.title ?? "",
      description: asset?.description ?? "",
      manufacturer: asset?.manufacturer ?? "",
      equipmentType: asset?.equipmentType ?? "",
      serialNumber: asset?.serialNumber ?? "",
      modelNumber: asset?.modelNumber ?? "",
      installationDate: asset?.installationDate ?? undefined,
      manufacturerWarrantyStart: asset?.manufacturerWarrantyStart ?? undefined,
      manufacturerWarrantyEnd: asset?.manufacturerWarrantyEnd ?? undefined,
      serviceWarrantyStart: asset?.serviceWarrantyStart ?? undefined,
      serviceWarrantyEnd: asset?.serviceWarrantyEnd ?? undefined,
    };
  }, [asset]);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<
    Pick<
      Asset,
      | "title"
      | "description"
      | "manufacturer"
      | "equipmentType"
      | "serialNumber"
      | "modelNumber"
      | "installationDate"
      | "manufacturerWarrantyStart"
      | "manufacturerWarrantyEnd"
      | "serviceWarrantyStart"
      | "serviceWarrantyEnd"
    >
  >({
    defaultValues: newAssetDefaultValues,
    resolver: zodResolver(NewAssetFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    setAsset(props.asset);
    reset();
  }, [props.asset, reset]);

  useEffect(() => {
    reset(newAssetDefaultValues);
  }, [newAssetDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the section is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    setSelectedCustomerLocation(null);
    setSelectedCustomerLocationID(null);
    setSelectedCustomer(null);
    setSelectedCustomerID(null);
    props.closeDialog();
  }

  const errorMessageComponent = (
    <ErrorMessage
      message={errorMessage}
      clearMessage={() => setDisplayError(false)}
    />
  );

  const confirmDeleteEquipmentDialog = asset && (
    <ConfirmDeleteEquipmentDialog
      isOpen={confirmDeleteEquipmentDialogOpen}
      onClose={() => setConfirmDeleteEquipmentDialogOpen(false)}
      handleConfirmDelete={handleDeleteEquipment}
      isSubmitting={false}
    />
  );

  async function handleDeleteEquipment() {
    if (!asset) return;

    try {
      await DbWrite.assets.delete(props.siteKey, asset.id);
    } catch (error) {
      logger.error(`error while running handleDeleteEquipment: `, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_DELETE_EQUIPMENT,
        dialog: false,
        type: "error",
      });
    } finally {
      setConfirmDeleteEquipmentDialogOpen(false);
      closeAndReset();
    }
  }

  const selectCustomerButton = (
    <BaseButtonSecondary
      type="button"
      onClick={() =>
        setDialogStates({
          ...dialogStates,
          selectCustomerOpen: true,
        })
      }
      className="w-full text-primary xs:w-auto"
    >
      <CheckCircle fontSize="small" className="mr-2" />
      {strings.SELECT_EXISTING_CUSTOMER}
    </BaseButtonSecondary>
  );

  const customerLocationSection = (
    <div className="relative z-20">
      <h6 className="text-md mb-0 max-w-fit font-extrabold text-primary">
        {strings.SELECT_CUSTOMER_LOCATION}
      </h6>
      {customerLocationOptions === null ||
      customerLocationOptions.length === 0 ? (
        <div>
          No locations to select from, please select a customer or add a
          location for this customer.
        </div>
      ) : (
        <CustomerLocationSection
          allowSelection={true}
          selectedCustomer={selectedCustomer}
          customerLocationOptions={customerLocationOptions}
          selectedCustomerLocation={selectedCustomerLocation}
          setSelectedCustomerLocation={(location) => {
            setSelectedCustomerLocation(location);
            setSelectedCustomerLocationID(location?.id ?? null);
          }}
          membershipTemplateList={[]}
          customerLocationMemberships={[]}
        />
      )}
    </div>
  );

  const siteKeyLocationSection = siteKeyLocations.length > 1 && (
    <div className="relative z-50">
      <h6 className="text-md mb-0 max-w-fit font-extrabold text-primary">
        {strings.SELECT_LOCATION}
      </h6>
      <SiteKeyLocationSection
        siteKeyLocationList={siteKeyLocations}
        selectedSiteKeyLocation={selectedSiteKeyLocationID}
        setSelectedSiteKeyLocation={setSelectedSiteKeyLocationID}
      />
    </div>
  );

  const onSubmit: SubmitHandler<
    Pick<
      Asset,
      | "title"
      | "description"
      | "manufacturer"
      | "equipmentType"
      | "serialNumber"
      | "modelNumber"
      | "installationDate"
      | "manufacturerWarrantyStart"
      | "manufacturerWarrantyEnd"
      | "serviceWarrantyStart"
      | "serviceWarrantyEnd"
    >
  > = async (formValues) => {
    if (!selectedCustomerID) {
      setErrorMessage("Customer Not Selected.");
      setDisplayError(true);
      return;
    }
    if (!selectedCustomerLocationID) {
      setErrorMessage("Customer Location Not Selected.");
      setDisplayError(true);
      return;
    }

    const assetData: Asset = {
      title: formValues.title,
      description: formValues.description,
      customID: asset?.customID ?? null,
      locationID: selectedSiteKeyLocationID,
      latitude: asset?.latitude ?? selectedCustomerLocation?.latitude ?? null,
      longitude: asset?.latitude ?? selectedCustomerLocation?.longitude ?? null,
      qrCode: asset?.qrCode ?? null,
      thumbnailURL: asset?.thumbnailURL ?? null,
      manufacturer: formValues.manufacturer,
      equipmentType: formValues.equipmentType,
      serialNumber: formValues.serialNumber,
      modelNumber: formValues.modelNumber,
      installationDate: formValues.installationDate,
      manufacturerWarrantyStart: formValues.manufacturerWarrantyStart,
      manufacturerWarrantyEnd: formValues.manufacturerWarrantyEnd,
      serviceWarrantyStart: formValues.serviceWarrantyStart,
      serviceWarrantyEnd: formValues.serviceWarrantyEnd,
      customerID: asset?.customerID ?? selectedCustomerID,
      customerLocationID:
        asset?.customerLocationID ?? selectedCustomerLocationID,
    };

    try {
      if (asset) {
        const validatedData = AssetManager.convertForFirestore(assetData);
        // Update
        await DbWrite.assets.update(props.siteKey, {
          id: asset.id,
          refPath: asset.refPath,
          ...validatedData,
        });
        addMessage({
          id: createToastMessageID(),
          message: strings.successfulUpdate("Asset/Equipment"),
          dialog: false,
          type: "success",
        });
        props.closeDialog();
      } else {
        await DbWrite.assets.add(
          props.siteKey,
          AssetManager.convertForFirestore(assetData),
        );
        addMessage({
          id: createToastMessageID(),
          message: strings.successfulAdd("New Asset/Equipment"),
          dialog: false,
          type: "success",
        });
        props.closeDialog();
      }
    } catch (error) {
      console.log(error);
      setDisplayError(true);
    }
  };

  const newAssetHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {asset ? strings.EDIT_ASSET : strings.NEW_ASSET}
      </h1>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon
          aria-label="close new attribute form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <>
      <BaseModal
        closeModal={() => {}}
        open={props.isDialogOpen}
        title={newAssetHeader}
        parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
      >
        <div className="space-y-4 p-4">
          {selectedCustomerID === null && selectCustomerButton}
          {selectedCustomer && (
            <CustomerDetails
              customer={selectedCustomer}
              openEditCustomerDialog={null}
              openCallDialog={null}
            />
          )}
          {siteKeyLocationSection}
          {customerLocationSection}
          <form
            autoComplete="off"
            onSubmit={(event) => {
              event.stopPropagation();
              handleSubmit(onSubmit)(event);
            }}
            className="space-y-6 pt-4"
          >
            <div>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Title"
                    inputName="title"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.title?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.title.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
            <div>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Description"
                    inputName="description"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.description?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.description.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
            <div>
              <Controller
                name="manufacturer"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Manufacturer"
                    inputName="manufacturer"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.manufacturer?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.manufacturer.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
            <div>
              <Controller
                name="equipmentType"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Equipment Type"
                    inputName="equipmentType"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.equipmentType?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.equipmentType.message }}
                  </StyledMessage>
                </div>
              )}
            </div>

            <div>
              <Controller
                name="serialNumber"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Serial Number"
                    inputName="serialNumber"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.serialNumber?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.serialNumber.message }}
                  </StyledMessage>
                </div>
              )}
            </div>

            <div>
              <Controller
                name="modelNumber"
                control={control}
                render={({ field }) => (
                  <BaseInputText
                    text="Model Number"
                    inputName="modelNumber"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.modelNumber?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.modelNumber.message }}
                  </StyledMessage>
                </div>
              )}
            </div>

            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  Installation Date
                </label>
              </div>
              <Controller
                key="installationDate"
                name="installationDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>

            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  Manufacturer Warranty Start
                </label>
              </div>
              <Controller
                key="manufacturerWarrantyStart"
                name="manufacturerWarrantyStart"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>

            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  Manufacturer Warranty End
                </label>
              </div>
              <Controller
                key="manufacturerWarrantyEnd"
                name="manufacturerWarrantyEnd"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>

            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  Service Warranty Start
                </label>
              </div>
              <Controller
                key="serviceWarrantyStart"
                name="serviceWarrantyStart"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>

            <div className="flex w-full items-center justify-start gap-4">
              <div className="mr-10 w-1/3">
                <label className="whitespace-nowrap text-gray-500">
                  Service Warranty End
                </label>
              </div>
              <Controller
                key="serviceWarrantyEnd"
                name="serviceWarrantyEnd"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <DatePicker
                      selected={field.value?.toDate()}
                      onChange={(date) => {
                        if (date) {
                          field.onChange(Timestamp.fromDate(date));
                        }
                      }}
                      customInput={<CustomDateInput />}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>

            <div className="mt-4 flex flex-col items-end">
              {displayError ? errorMessageComponent : null}
            </div>

            {/* Action Buttons */}
            <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
              <BaseButtonSecondary
                type="button"
                className="w-full justify-center uppercase"
                onClick={closeAndReset}
              >
                {strings.buttons.CANCEL}
              </BaseButtonSecondary>

              {asset && (
                <ButtonColored
                  type="button"
                  onClick={() => setConfirmDeleteEquipmentDialogOpen(true)}
                  busyText={strings.buttons.BUSY_SAVING}
                  className="w-full justify-center uppercase"
                  kind="danger"
                >
                  {strings.buttons.DELETE_EQUIPMENT}
                </ButtonColored>
              )}

              <BaseButtonPrimary
                type="submit"
                formNoValidate
                disabled={props.isSubmitting}
                isBusy={props.isSubmitting}
                busyText={strings.buttons.BUSY_SAVING}
                className="w-full justify-center uppercase"
                data-testid="save item"
              >
                {strings.buttons.SAVE}
              </BaseButtonPrimary>
            </div>
          </form>
        </div>
      </BaseModal>
      {selectCustomerDialog}
      {confirmDeleteEquipmentDialog}
    </>
  );
}
