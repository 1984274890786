//Libs
import { z } from "zod";
import { useEffect, useMemo, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Timestamp } from "firebase/firestore";
import CheckCircle from "@mui/icons-material/CheckCircle";

//Local
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import CustomerTableDialog from "../customers/CustomerTableDialog";
import { CustomerDetails } from "../estimates/CustomerDetails";
import { CustomerLocationSection } from "../customers/CreateTask";
import { DbRead, DbWrite } from "../../database";
import {
  getReadableMembershipStatus,
  Membership,
  MembershipManager,
  MembershipStatus,
  membershipStatus,
} from "../../models/membership";
import { useAuthStore } from "../../store/firebase-auth";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import BaseInputTextArea from "../BaseInputTextArea";
import AssetEquipmentListCheckboxes from "./AssetEquipmentListCheckboxes";
import { ExistingAsset } from "../../models/asset";
import { logger } from "../../logging";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import BaseInputSelect from "../BaseInputSelect";
import BaseInputDate from "../BaseInputDate";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { StyledTooltip } from "../StyledTooltip";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;

  siteKey: string;
  assetsEnabled: boolean;
  defaultEmailDuesCollectedReceipt: boolean;
  customer: ExistingCustomer | null;
  customerLocation: ExistingCustomerLocation | null;
  customerLocationOptions: ExistingCustomerLocation[] | null;
  locationID: string | null;
}

const CreateFormSchema = z.object({
  notes: z.string().max(1000).nullable(),
  automaticallyGenerateTasks: z.boolean(),
  automaticallySendReceipt: z.boolean(),
  membershipEndDate: z.instanceof(Timestamp).nullable(),
  membershipStartDate: z
    .instanceof(Timestamp)
    .nullable()
    // want the default value to be null, but don't want it to be null on submit
    .refine((val) => (val === null ? false : true), {
      message: "Select a membership start date",
    }),
  lastCompletedTaskDate: z.instanceof(Timestamp).nullable(),
  nextScheduledTaskDate: z.instanceof(Timestamp).nullable(),
  lastPaymentDate: z.instanceof(Timestamp).nullable(),
  nextInvoiceDate: z.instanceof(Timestamp).nullable(),
  lastPaymentAmount: z.number().nullable(),
  status: z
    .nativeEnum(MembershipStatus)
    .nullable()
    .refine((val) => (val === null ? false : true), {
      message: "Select a status",
    }),
  membershipTemplateID: z
    .string()
    .nullable()
    .refine((val) => (!val || val.length === 0 ? false : true), {
      message: "Select a membership type",
    }),
  customerID: z
    .string()
    .nullable()
    .refine((val) => (val === null ? false : true), {
      message: "Select a customer",
    }),
  customerLocationID: z
    .string()
    .nullable()
    .refine((val) => (val === null ? false : true), {
      message: "Select a customer location",
    }),
  automaticallyRenewMembership: z.boolean().optional(),
});
type CreateFormState = z.infer<typeof CreateFormSchema>;

export default function CreateMembershipDialog(props: Props) {
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  const [siteKeyLocationList, isLoadingSKLocs] = useSiteKeyLocationsStore(
    (state) => [state.siteKeyLocationList, state.loading],
  );
  const membershipTemplateList = useMembershipTemplatesStore(
    (state) => state.membershipTemplates,
  );
  const automatedPaymentsTooltipRef = useRef<HTMLDivElement>(null);
  const autoRenewTooltipRef = useRef<HTMLDivElement>(null);

  const [pickCustomerDialogOpen, setPickCustomerDialogOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<ExistingCustomer | null>(props.customer);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedSiteKeyLoc, setSelectedSiteKeyLoc] =
    useState<ExistingSiteKeyLocation | null>(null);

  const [cusLocOptions, setCusLocOptions] = useState<
    ExistingCustomerLocation[] | null
  >(props.customerLocationOptions);
  const [selectedCusLoc, setSelectedCusLoc] =
    useState<ExistingCustomerLocation | null>(props.customerLocation);

  const [selectedTemplate, setSelectedTemplate] =
    useState<ExistingMembershipTemplate | null>(null);
  // so we can fetch the template to determine if we should show additional toggles:
  const [templateID, setTemplateID] = useState<string | null>(null); // alternatively, could use useWatch (from RHF). but we're already using useState, so 🤷‍♀️

  const [assetOptions, setAssetOptions] = useState<ExistingAsset[]>([]);
  const [assetIDs, setAssetIDs] = useState<string[] | undefined>([]);

  const defaultValues = useMemo(
    (): CreateFormState => ({
      lastCompletedTaskDate: null,
      lastPaymentAmount: null,
      lastPaymentDate: null,
      membershipEndDate: null,
      membershipStartDate: null,
      nextInvoiceDate: null,
      nextScheduledTaskDate: null,
      notes: null,
      status: null,
      automaticallyGenerateTasks: false,
      automaticallySendReceipt: props.defaultEmailDuesCollectedReceipt,
      membershipTemplateID: null,
      customerID: props.customer?.id ?? null,
      customerLocationID: props.customerLocation?.id ?? null,
      automaticallyRenewMembership: false,
    }),
    [
      props.customer?.id,
      props.customerLocation?.id,
      props.defaultEmailDuesCollectedReceipt,
    ],
  );

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
    setValue,
  } = useForm<CreateFormState>({
    defaultValues: defaultValues,
    resolver: zodResolver(CreateFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    function getAssetsForCustomerLocation() {
      if (!selectedCusLoc) return undefined;
      const unsubscribeFn = DbRead.assets.subscribeByCustomerLocationID({
        siteKey: props.siteKey,
        customerLocationID: selectedCusLoc.id,
        onChange: setAssetOptions,
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = getAssetsForCustomerLocation();
    return () => unsubscribe && unsubscribe();
  }, [props.siteKey, selectedCusLoc]);

  useEffect(() => {
    if (isLoadingSKLocs) return;
    if (siteKeyLocationList.length === 1) {
      setSelectedSiteKeyLoc(siteKeyLocationList[0]);
    }
  }, [isLoadingSKLocs, siteKeyLocationList]);

  useEffect(() => {
    function getCusLocOptions() {
      if (!selectedCustomer) return undefined;
      const unsubscribeFn = DbRead.customerLocations.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: selectedCustomer.id,
        onChange: setCusLocOptions,
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = getCusLocOptions();
    return () => unsubscribe && unsubscribe();
  }, [props.siteKey, selectedCustomer]);

  useEffect(() => {
    function getTemplate() {
      if (!templateID) return undefined;
      const unsubscribeFn = DbRead.membershipTemplates.subscribe({
        siteKey: props.siteKey,
        templateID: templateID,
        onChange: setSelectedTemplate,
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = getTemplate();
    return () => unsubscribe && unsubscribe();
  }, [props.siteKey, templateID]);

  useEffect(() => {
    if (!props.customer) return;
    setSelectedCustomer(props.customer);
  }, [props.customer]);

  useEffect(() => {
    // Always update the form value when template changes, using the template value or false
    setValue(
      "automaticallyRenewMembership",
      selectedTemplate?.automaticallyRenewMembership ?? false,
    );
    setValue(
      "automaticallyGenerateTasks",
      selectedTemplate?.automaticallyGenerateTasks ?? false,
    );
  }, [selectedTemplate, setValue]);

  const selectCustomerDialog = (
    <CustomerTableDialog
      isOpen={pickCustomerDialogOpen}
      onClose={() => setPickCustomerDialogOpen(false)}
      siteKey={props.siteKey}
      onSelectCustomer={(c: ExistingCustomer) => {
        setSelectedCustomer(c);
        setValue("customerID", c.id);
        setPickCustomerDialogOpen(false);
      }}
      addCustomerButton={null}
    />
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function closeAndReset() {
    reset();
    // don't want to reset these if the dialog was opened from ShowSingleCustomer.
    if (!props.customer) setSelectedCustomer(null);
    if (!props.customerLocationOptions?.length) setCusLocOptions(null);
    if (!props.customerLocation) {
      setSelectedCusLoc(null);
      setAssetOptions([]);
    }

    // the user will always have to select a template, and though the assetOptions
    // should be persisted, the assetIDs list is populated from user interaction and
    // should therefore be reset.
    setPickCustomerDialogOpen(false);
    setAssetIDs([]);
    setTemplateID(null);
    setSelectedTemplate(null);

    props.closeDialog();
  }

  const selectCustomerButton = (
    <BaseButtonSecondary
      type="button"
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.currentTarget.blur(); // allows the customer dialog to get focus
        setPickCustomerDialogOpen(true);
      }}
      className="w-full text-primary xs:w-auto"
    >
      <CheckCircle fontSize="small" className="mr-2" />
      {strings.SELECT_EXISTING_CUSTOMER}
    </BaseButtonSecondary>
  );

  function getCLSectionTitle(
    customer: ExistingCustomer | null,
    options: ExistingCustomerLocation[] | null,
  ): string {
    if (!customer) {
      return "No locations to choose from, please select a customer.";
    }
    if (!options || options.length === 0) {
      return "Add a location for this customer.";
    }
    return "";
  }

  const customerLocationSection = (
    <>
      {!selectedCustomer || cusLocOptions?.length === 0 ? (
        <div className="rounded-md border border-gray-500 px-3 py-2">
          <h6 className="mb-0 max-w-fit text-base font-semibold text-primary">
            {strings.SELECT_LOCATION} <span className="text-redFail">*</span>
          </h6>
          <p className="text-sm font-normal text-gray-700">
            {getCLSectionTitle(selectedCustomer, cusLocOptions)}
          </p>
        </div>
      ) : (
        <CustomerLocationSection
          allowSelection={true}
          selectedCustomer={selectedCustomer}
          customerLocationOptions={cusLocOptions}
          selectedCustomerLocation={selectedCusLoc}
          setSelectedCustomerLocation={(location) => {
            setSelectedCusLoc(location);
            setValue("customerLocationID", location?.id ?? null);
          }}
          openEditExistingCustomerLocation={undefined}
          membershipTemplateList={[]}
          customerLocationMemberships={[]}
          useBaseInputSelect
        />
      )}
    </>
  );

  const onSubmit: SubmitHandler<CreateFormState> = async (formValues) => {
    if (!firebaseUser) {
      throw Error("CreateMembershipDialog onSubmit: firebaseUser is null");
    }
    if (!selectedSiteKeyLoc) {
      setErrorMessage("Select a site location");
      return;
    }
    if (
      !selectedCustomer ||
      !selectedTemplate ||
      !formValues.customerLocationID ||
      !formValues.membershipTemplateID
    ) {
      return;
    }

    const now = Timestamp.now();
    const membership: Membership = {
      createdBy: firebaseUser.uid,
      customerID: selectedCustomer.id,
      customerLocationID: formValues.customerLocationID,
      customerName: selectedCustomer.name,
      lastCompletedTaskDate: formValues.lastCompletedTaskDate,
      lastModifiedBy: firebaseUser.uid,
      lastPaymentAmount: formValues.lastPaymentAmount,
      lastPaymentDate: formValues.lastPaymentDate,
      locationID: selectedSiteKeyLoc.id,
      membershipEndDate: formValues.membershipEndDate,
      membershipStartDate: formValues.membershipStartDate,
      membershipTemplateID: formValues.membershipTemplateID,
      nextInvoiceDate: formValues.nextInvoiceDate,
      nextScheduledTaskDate: formValues.nextScheduledTaskDate,
      automaticallyGenerateTasks: formValues.automaticallyGenerateTasks,
      notes:
        formValues.notes && formValues.notes.length > 0
          ? formValues.notes
          : null,
      status: formValues.status ?? MembershipStatus.Draft,
      timestampCreated: now,
      timestampLastModified: now,
    };
    // Conditionally add to the membership
    if (assetIDs && assetIDs.length) membership.assetIDs = assetIDs;
    if (selectedTemplate.invoiceMethod === "automatic") {
      membership.automaticallySendReceipt = formValues.automaticallySendReceipt;

      membership.automaticallyRenewMembership =
        formValues.automaticallyRenewMembership ?? false;
    }

    try {
      const valid = MembershipManager.parse(membership);
      logger.info("create membership", valid);
      await DbWrite.memberships.create(
        props.siteKey,
        MembershipManager.convertForFirestore(valid),
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulCreate("membership"),
        dialog: false,
        type: "success",
      });
      closeAndReset();
    } catch (e) {
      logger.error(e);
      // setErrorMessage("Unable to create membership")
      addMessage({
        id: createToastMessageID(),
        message: strings.failedCreate("membership"),
        dialog: true,
        type: "error",
      });
    }
  };

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white">
      <h1 className="inline-flex items-center text-xl font-semibold">
        {strings.NEW_MEMBERSHIP}
      </h1>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon
          aria-label="close create membership dialog"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <>
      <BaseModal
        closeModal={() => {}} // prevent unintentionally closing dialog
        open={props.isDialogOpen}
        title={header}
        allowOverflowY={true}
        parentDivStyles="inline-block transform overflow-hidden max-w-4xl rounded-lg bg-white text-left align-middle shadow-xl transition-all"
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="px-4 pb-4 md:px-8 md:pb-6"
        >
          <div className="space-y-8 md:grid md:grid-cols-2 md:gap-6 md:space-y-0">
            {/* CUSTOMER */}
            <div className="col-span-2 col-start-1">
              {selectedCustomer === null && selectCustomerButton}
              {selectedCustomer && (
                <CustomerDetails
                  customer={selectedCustomer}
                  openEditCustomerDialog={null}
                  openCallDialog={null}
                  shouldConstrainWidth={false}
                />
              )}
              {errors.customerID?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.customerID.message }}
                  </StyledMessage>
                </div>
              )}
            </div>

            {/* CUSTOMER LOCATION */}
            <div>
              {customerLocationSection}
              {errors.customerLocationID?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.customerLocationID.message }}
                  </StyledMessage>
                </div>
              )}
            </div>

            {/* MEMBERSHIP TEMPLATE */}
            <div>
              <Controller
                name="membershipTemplateID"
                control={control}
                render={({ field }) => (
                  <BaseInputSelect
                    id="membershipTemplateID"
                    inputName="membershipTemplateID"
                    text="Membership Type"
                    admin
                    required
                    {...field}
                    value={field.value ?? ""}
                    onChange={(event) => {
                      const val =
                        event.target.value === "" ? null : event.target.value;
                      field.onChange(val);
                      setTemplateID(val);
                    }}
                  >
                    <option value="" disabled>
                      Select a Membership Type
                    </option>

                    {membershipTemplateList.map((template) => (
                      <option key={template.id} value={template.id}>
                        {template.title}
                      </option>
                    ))}
                  </BaseInputSelect>
                )}
              />
              {errors.membershipTemplateID?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.membershipTemplateID.message }}
                  </StyledMessage>
                </div>
              )}
            </div>

            {/* SITE LOCATION */}
            {siteKeyLocationList.length > 1 && (
              <div>
                <BaseInputSelect
                  id="locationID"
                  inputName="locationID"
                  text="Site Location"
                  admin
                  required
                  value={selectedSiteKeyLoc?.id ?? ""}
                  onChange={(event) => {
                    const loc = siteKeyLocationList.find(
                      (l) => l.id === event.target.value,
                    );
                    setSelectedSiteKeyLoc(loc ?? null);
                  }}
                >
                  <option value="" disabled>
                    Select Site Location
                  </option>

                  {siteKeyLocationList.map((skl) => (
                    <option key={skl.id} value={skl.id}>
                      {skl.title}
                    </option>
                  ))}
                </BaseInputSelect>
                {errorMessage && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errorMessage }}
                    </StyledMessage>
                  </div>
                )}
              </div>
            )}

            {/* STATUS */}
            <div>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <BaseInputSelect
                    id="status"
                    inputName="status"
                    text="Membership Status"
                    admin
                    required
                    {...field}
                    value={field.value ?? ""}
                    onChange={(event) => {
                      field.onChange(
                        event.target.value === "" ? null : event.target.value,
                      );
                    }}
                  >
                    <option value="" disabled>
                      Select a Status
                    </option>

                    {membershipStatus.map((status) => (
                      <option key={status} value={status}>
                        {getReadableMembershipStatus(status)}
                      </option>
                    ))}
                  </BaseInputSelect>
                )}
              />
              {errors.status?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.status.message }}
                  </StyledMessage>
                </div>
              )}
            </div>

            {/* NOTES */}
            <div className="col-span-2 col-start-1">
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <BaseInputTextArea
                    text="Notes"
                    rows={4}
                    inputName="notes"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                )}
              />
              {errors.notes?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.notes.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          </div>

          {/* CONDITIONALLY-DISPLAYED FIELDS: assets, task generation, receipt emails, auto-renewal */}
          <div className="mt-4 flex flex-wrap gap-4">
            {props.assetsEnabled && assetOptions.length > 0 && (
              // Allow user to specify one or more assets that might apply to this membership
              <div className="max-w-fit space-y-2 rounded-md border border-gray-500 px-3 py-2">
                <h6 className="text-base font-semibold text-primary">
                  {strings.SELECT_ASSET}:
                </h6>
                <AssetEquipmentListCheckboxes
                  assetsForCustomer={assetOptions}
                  assetIDs={assetIDs}
                  setAssetIDs={setAssetIDs}
                />
              </div>
            )}

            <div className="flex flex-wrap gap-4">
              {selectedTemplate?.taskGeneration &&
                selectedTemplate.taskGeneration.length > 0 && (
                  <div className="max-w-fit self-start rounded-md border border-gray-500 px-3 py-2">
                    <Controller
                      name="automaticallyGenerateTasks"
                      control={control}
                      render={({ field }) => (
                        <StyledSwitchGroup
                          readableName="Automatically Generate Tasks"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          ref={field.ref}
                          checked={field.value}
                          id="automaticallyGenerateTasks"
                          name={field.name}
                        />
                      )}
                    />
                  </div>
                )}

              {selectedTemplate?.invoiceMethod === "automatic" && (
                <StyledTooltip title="You have selected a Membership Type that will automatically collect membership dues. This toggle controls whether or not receipts will automatically be sent to the customer.">
                  <div
                    ref={automatedPaymentsTooltipRef}
                    className="max-w-fit self-start rounded-md border border-gray-500 px-3 py-2"
                  >
                    <Controller
                      name="automaticallySendReceipt"
                      control={control}
                      render={({ field }) => (
                        <StyledSwitchGroup
                          readableName="Email Customer Receipts for Automated Payments"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          ref={field.ref}
                          checked={field.value}
                          id="automaticallySendReceipt"
                          name={field.name}
                        />
                      )}
                    />
                  </div>
                </StyledTooltip>
              )}

              {selectedTemplate?.invoiceMethod === "automatic" && (
                <StyledTooltip
                  title={`When enabled, this membership will automatically renew at the end of its term. The customer will receive an email notification 15 days prior to the renewal date (the membership end date).`}
                >
                  <div
                    ref={autoRenewTooltipRef}
                    className="max-w-fit self-start rounded-md border border-gray-500 px-3 py-2"
                  >
                    <Controller
                      name="automaticallyRenewMembership"
                      control={control}
                      render={({ field }) => (
                        <StyledSwitchGroup
                          readableName="Automatically Renew Membership"
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          ref={field.ref}
                          checked={field.value ?? false}
                          id="automaticallyRenewMembership"
                          name={field.name}
                        />
                      )}
                    />
                  </div>
                </StyledTooltip>
              )}
            </div>
          </div>

          {/* TIMESTAMP FIELDS */}
          <div className="mt-8 flex flex-wrap gap-x-6 gap-y-10 md:justify-between">
            <div className="space-y-8">
              <Controller
                key="membershipStartDate"
                name="membershipStartDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <BaseInputDate
                      inputName="membershipStart"
                      displayText={strings.MEMBERSHIP_START_DATE}
                      required
                      addWrapperStyles="mt-2"
                      // overrideWidth="max-w-fit md:max-w-none md:w-52 lg:w-60"
                      overrideWidth="w-48 lg:w-52"
                      value={field.value?.toDate().toISOString() ?? null}
                      onChange={(date) => {
                        if (date) field.onChange(Timestamp.fromDate(date));
                      }}
                    />
                    {error?.message && (
                      <div className="mt-2 text-sm">
                        <StyledMessage type="error">
                          {{ message: error.message }}
                        </StyledMessage>
                      </div>
                    )}
                  </div>
                )}
              />

              <Controller
                key="membershipEndDate"
                name="membershipEndDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <BaseInputDate
                      inputName="membershipEnd"
                      displayText={strings.MEMBERSHIP_END_DATE}
                      addWrapperStyles="mt-2"
                      overrideWidth="w-48 lg:w-52"
                      value={field.value?.toDate().toISOString() ?? null}
                      onChange={(date) => {
                        if (date) field.onChange(Timestamp.fromDate(date));
                      }}
                    />
                    {error?.message && (
                      <div className="mt-2 text-sm">
                        <StyledMessage type="error">
                          {{ message: error.message }}
                        </StyledMessage>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="space-y-8">
              <Controller
                key="lastCompletedTaskDate"
                name="lastCompletedTaskDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <BaseInputDate
                      inputName="lastCompletedTask"
                      displayText={strings.LAST_COMPLETED_TASK_DATE}
                      addWrapperStyles="mt-2"
                      overrideWidth="w-48 lg:w-52"
                      value={field.value?.toDate().toISOString() ?? null}
                      onChange={(date) => {
                        if (date) field.onChange(Timestamp.fromDate(date));
                      }}
                    />
                    {error?.message && (
                      <div className="mt-2 text-sm">
                        <StyledMessage type="error">
                          {{ message: error.message }}
                        </StyledMessage>
                      </div>
                    )}
                  </div>
                )}
              />

              <Controller
                key="nextScheduledTaskDate"
                name="nextScheduledTaskDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <BaseInputDate
                      inputName="nextScheduledTask"
                      displayText={strings.NEXT_SCHEDULED_TASK_DATE}
                      addWrapperStyles="mt-2"
                      overrideWidth="w-48 lg:w-52"
                      value={field.value?.toDate().toISOString() ?? null}
                      onChange={(date) => {
                        if (date) field.onChange(Timestamp.fromDate(date));
                      }}
                    />
                    {error?.message && (
                      <div className="mt-2 text-sm">
                        <StyledMessage type="error">
                          {{ message: error.message }}
                        </StyledMessage>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="space-y-8">
              <Controller
                key="lastPaymentDate"
                name="lastPaymentDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <BaseInputDate
                      inputName="lastPayment"
                      displayText={strings.LAST_PAYMENT_DATE}
                      addWrapperStyles="mt-2"
                      overrideWidth="w-48 lg:w-52"
                      value={field.value?.toDate().toISOString() ?? null}
                      onChange={(date) => {
                        if (date) field.onChange(Timestamp.fromDate(date));
                      }}
                    />
                    {error?.message && (
                      <div className="mt-2 text-sm">
                        <StyledMessage type="error">
                          {{ message: error.message }}
                        </StyledMessage>
                      </div>
                    )}
                  </div>
                )}
              />

              <Controller
                key="nextInvoiceDate"
                name="nextInvoiceDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <BaseInputDate
                      inputName="nextInvoice"
                      displayText={strings.NEXT_INVOICE_DATE}
                      addWrapperStyles="mt-2"
                      overrideWidth="w-48 lg:w-52"
                      value={field.value?.toDate().toISOString() ?? null}
                      onChange={(date) => {
                        if (date) field.onChange(Timestamp.fromDate(date));
                      }}
                    />
                    {error?.message && (
                      <div className="mt-2 text-sm">
                        <StyledMessage type="error">
                          {{ message: error.message }}
                        </StyledMessage>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex w-full flex-col items-center justify-between gap-4 pt-8 xs:flex-row">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={closeAndReset}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
              data-testid="save item"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>
      </BaseModal>
      {selectCustomerDialog}
    </>
  );
}
