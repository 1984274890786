export const HOME_URL = "/";

/* NOT FOUND */
export const NOT_FOUND_URL = "/404";

/* LOGIN */
export const LOGIN_URL = "/login";
export const AUTO_LOGIN_URL = "/auto-login";
export const FORGOT_PASSWORD_URL = "/forgot-password";

/* NEW ACCOUNT */
export const CREATE_ACCOUNT_URL = "/create-account";
export const CREATE_TRIAL_ACCOUNT_URL = "/create-trial-account";
export const CREATE_SITE_URL = "/create-site";
export const NEW_USER_URL = "/new-user";
export const APPLY_URL = "/apply";

/* ADMIN */
export const ADMIN_USER_SETTINGS_URL = "/admin/user-settings";
export const ADMIN_MY_SITES_URL = "/admin/my-sites";
export const ADMIN_LIST_COMPANIES_URL = "/admin/companies";
export const ADMIN_LIST_LOCATIONS_URL = "/admin/locations";
export const ADMIN_ADD_USER_URL = "/admin/user-management/add-user";
export const ADMIN_EDIT_USER_URL = "/admin/edit-user";
export const ADMIN_USER_MANAGEMENT_URL = "/admin/user-management";

export const UNAPPROVED_USER_URL = "/unapproved-user";

/* CHECKLIST */
export const CHECKLISTS_URL = "/checklists";
export const TEMPLATES_URL = "/templates";
export const TASKS_URL = "/tasks";
export const TEMPLATE_ITEMS_URL = "/template-items";
export const NEW_TEMPLATE_URL = "/new-template";
export const TASK_DETAILS_URL = "/task-details";

/* COMPLIANCE */
export const COMPLIANCE_URL = "/compliance";

/* CUSTOMERS */
export const CUSTOMERS_URL = "/customers";
export const CREATE_TASK_URL = "/create-task";
export const CUSTOMERS_CREATE_TASK_URL = "/customers/create-task";
export const CREATE_TASK_TABLE_URL = "/create-task-table";

/* WORK RECORD AND TASKS */
export const WORK_RECORD_AND_TASKS_URL = "/work-record-and-tasks";

// FEEDBACK
export const REVIEW_REQUEST = "/review-request";
export const FEEDBACK = "/feedback";

// MEMBERSHIPS
export const MEMBERSHIPS = "/memberships";
export const MEMBERSHIP_TEMPLATES = "/membership-templates";
export const MEMBERSHIP_ISSUES = "/membership-issues";
export const CREATE_TEMPLATE = "/create-template";

// ASSETS & EQUIPMENT
export const ASSETS = "/assets";

/* ESTIMATES & PRICEBOOK */
export const PRICEBOOK_URL = "/pricebook";
export const PRICEBOOK_CATEGORIES_URL = "/pricebook-categories";
export const CREATE_ESTIMATE_URL = "/create-estimate";
// This is for non-auth
export const ESTIMATES_URL_NON_AUTH = "/estimates";
export const ESTIMATES_URL_AUTH = "/estimates-list";

/* PAYMENTS */
export const PAYMENTS_URL = "/payments";
export const INVOICES_URL = "/invoices";
export const SELF_CHECKOUT_URL = "/selfCheckout";

/* INVENTORY */
export const INVENTORY_OBJECTS_URL = "/inventory-objects";
export const INVENTORY_TRANSACTIONS_URL = "/inventory-transactions";
export const INVENTORY_LOCATIONS_URL = "/inventory-locations";

/* SCHEDULING */
export const SCHEDULING_URL = "/scheduling";
export const RESOURCES_URL = "/resources";
export const VEHICLES_URL = "/vehicles";
export const ZONES_URL = "/zones";

/* WORK RECORD LIST */
export const WORK_RECORD_LIST_URL = "/customers-work-record-list";
export const DELETED_WORK_RECORD_LIST_URL =
  "/customers-work-record-list-deleted";

/* TASK LIST */
export const TASK_LIST_URL = "/customers-task-list";
export const DELETED_TASK_LIST_URL = "/customers-task-list-deleted";

/* KPIs */
export const KPIS_URL = "/kpis/dashboard";
export const REPORT_BUILDER_URL = "/report-builder";
export const REPORT_TEMPLATES_URL = "/report-templates";
export const USER_KPIS_URL = "/user-kpis";
/* USAGE METRICS */
export const USAGE_METRICS_URL = "/usage-metrics";

/* REPORTS */
export const REPORTS_URL = "/reports";

/* ASSIGNED TASKS */
export const ASSIGNED_TASKS_URL = "/assigned-tasks";

/* QUICKBOOKS */
export const QUICKBOOKS_URL = "/quickbooks";

/* LIGHTSPEEd */
export const LIGHTSPEED_URL = "/lightspeed";

/* GENERAL LEDGER ACCOUNTS */
export const GL_ACCOUNTS_URL = "/gl-accounts";

/* COMMISSIONS */
export const COMMISSIONS_LIST_URL = "/commissions";

/* CALLS */
/**
 * List:    /calls
 *
 * Detail:  /calls/:id
 *
 * Edit:    /calls/:id/edit
 */
export const CALLS_LIST_URL = "/calls";
export const PHONE_NUMBERS_URL = "/phones/numbers";

export const AVATAR_DEFAULT =
  "https://jovial-elion-a0d1d6.netlify.app/image/user-logo.png";

export const whiteLabel = import.meta.env.VITE_APP_BUILD_FLAVOR;
export const useIframes = import.meta.env.VITE_APP_IFRAMES === "true";

const AIMPOINT_PROD_URL = "https://aimpoint.web.app";

function getProdAppUrl() {
  if (whiteLabel === "aimpoint") {
    return AIMPOINT_PROD_URL;
  }
  if (!useIframes) {
    return "https://go.stilt-web.com";
  }
  return "https://stilt-web.com";
}

export const PROD_APP_URL = getProdAppUrl();
export const DEV_APP_URL = "https://jovial-elion-a0d1d6.netlify.app";

const firebaseProject = import.meta.env.VITE_APP_FIREBASE_PROJECT;

export let BASE_APP_URL: string;

if (firebaseProject === "scaffoldtracker") {
  BASE_APP_URL = PROD_APP_URL;
} else if (firebaseProject === "stilt-dev") {
  BASE_APP_URL = DEV_APP_URL;
} else {
  throw Error("Invalid Firebase project setting");
}

export let LARAVEL_API_URL_BASE: string;
if (firebaseProject === "scaffoldtracker") {
  LARAVEL_API_URL_BASE = "https://reports.stilt-web.com";
} else if (firebaseProject === "stilt-dev") {
  LARAVEL_API_URL_BASE = "https://stilt-sql.ruggedsoftware.dev";
} else {
  throw Error("Invalid Firebase project setting");
}
