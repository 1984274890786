// #region Imports
// Libs
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  InformationCircleIcon,
  MapPinIcon,
  PaperClipIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { DocumentData, Timestamp } from "firebase/firestore";
import { User } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import {
  getDownloadURL,
  getStorage,
  ref,
  StorageError,
  uploadBytesResumable,
} from "firebase/storage";
import { CloseFullscreen, CopyAll } from "@mui/icons-material";
import { ICellRendererParams } from "ag-grid-community";
import ReceiptIcon from "@mui/icons-material/Receipt";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import CheckCircle from "@mui/icons-material/CheckCircle";

// Local
import WorkRecordAndTasksPage from "./WorkRecordAndTasksPage";
import { DbRead, DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import WorkRecordPanel from "../../components/WorkRecordAndTasks/WorkRecordPanel";
import * as strings from "../../strings";
import TasksPanel from "../../components/WorkRecordAndTasks/TasksPanel";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import { logger } from "../../logging";
import {
  convertFSTimestampToLuxonDT,
  convertLuxonDTToFSTimestamp,
  createToastMessageID,
  dropUndefined,
  getRoundedCurrency,
} from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import BaseButtonTertiary from "../../components/BaseButtonTertiary";
import EditWorkRecordDialog from "../../components/WorkRecordAndTasks/EditWorkRecordDialog";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { diffObjects } from "../../assets/js/object-diff";
import { useAuthStore } from "../../store/firebase-auth";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { AttachmentManager, ExistingAttachment } from "../../models/attachment";
import PhotoViewer, {
  ValidPhoto,
} from "../../components/WorkRecordAndTasks/PhotoViewer";
import DetailsAndEventsPanels from "../../components/WorkRecordAndTasks/DetailsAndEventsPanels";
import {
  getCraftCustomFieldList,
  getTaskCustomFieldList,
} from "../../components/WorkRecordAndTasks/functions";
import { isValidCraftType } from "../../models/craft-types";
import {
  ExistingCustomField,
  MultipleUidCustomField,
} from "../../models/custom-field";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { useSiteKeyCompaniesStore } from "../../store/site-key-companies";
import RescheduleTaskDialog from "../../components/WorkRecordAndTasks/RescheduleTaskDialog";
import {
  ExistingTask,
  ExistingTaskWithCustomerLocation,
  getJobDescription,
  Task_CreateForCustomer,
  TaskRecordManager,
} from "../../models/task";
import EditTaskDialog from "../../components/WorkRecordAndTasks/EditTaskDialog";
import { isValidTaskType, TaskTypesValues } from "../../models/task-types";
import { IMultipleUid_AssignUser } from "../../components/CustomFields/MultipleUidDialog";
import { Json } from "../../models/json-type";
import {
  ExistingStiltInvoice,
  getInvoiceSectionString,
  OStiltInvoiceStatus,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  StiltInvoiceStatus,
  StiltInvoiceStatusValues,
  TemplatePaymentTerm,
} from "../../models/invoice";
import { useNavToCreateEstimate, useNavToCreateTask } from "../../navigation";
import {
  CUSTOMERS_URL,
  whiteLabel,
  WORK_RECORD_AND_TASKS_URL,
  WORK_RECORD_LIST_URL,
} from "../../urls";
import { StyledTooltip } from "../../components/StyledTooltip";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import ChipTag from "../../components/ChipTag";
import ViewPreviousJobsDialog from "../../components/WorkRecordAndTasks/ViewPreviousJobsDialog";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import ConfirmCloseWRDialog from "../../components/WorkRecordAndTasks/ConfirmCloseWRDialog";
import ButtonColored from "../../components/ButtonColored";
import ConfirmDeleteWRDialog from "../../components/WorkRecordAndTasks/ConfirmDeleteWRDialog";
import TaskStatusChangeDialog from "../../components/WorkRecordAndTasks/TaskStatusChangeDialog";
import { EstimateStatus, ExistingEstimate } from "../../models/estimate";
import EstimateListDialog from "../../components/WorkRecordAndTasks/EstimateListForTaskDialog";
import { InvoiceIconWithSpinner } from "../../components/InvoiceCreateButton";
import { isWhiteLabel } from "../../white-label-check";
import HandlePaymentDialog from "../../components/Invoices/HandlePaymentDialog";
import ViewEstimateDialog from "../../components/WorkRecordAndTasks/ViewEstimateDialog";
import { ExistingCustomerLocation } from "../../models/customer-location";
import ViewEstimateContainer from "../Estimates/ViewEstimateContainer";
import WorkStatusPanel from "../../components/WorkRecordAndTasks/WorkStatusPanel";
import { TaskStatus } from "../../models/task-status";
import currencyFormatter, { DollarCurrency } from "../../currency";
import { JobCardStructure } from "../../components/customers/JobCard";
import RecordManualPaymentDialog, {
  NewManualPayment,
  RecordManualPaymentDialogProps,
} from "../../components/Invoices/RecordManualPaymentDialog";
import { SchedulingButton } from "../Scheduling/SchedulingContainer";
import { PaymentIconWithSpinner } from "../../components/PaymentButton";
import { generatePaymentUniqueLink } from "../../assets/js/generatePaymentUniqueLink";
import {
  APIPaymentSavedCard,
  CreateMultiPayment,
  paymentMethods,
  StiltPayment_CreateAPI,
  StiltPaymentManager,
} from "../../models/stilt-payment";
import DuplicateJobDialog from "../../components/WorkRecordAndTasks/DuplicateJobDialog";
import { ExistingCustomer } from "../../models/customer";
import BaseInputCheckbox from "../../components/BaseInputCheckbox";
import { SchedulingSection } from "../../components/customers/CreateTask";
import getCurrentJSDateWithoutSeconds from "../../assets/js/getCurrentJSDateWithoutSeconds";
import { ScheduleByPriorityType } from "../../components/customers/CreateTask/SchedulingSection";
import EmailButtonWithSpinner from "../../components/EmailButtonWithSpinner";
import { convertJSDateToFSTimestamp } from "../../utils/convertJSDateToFSTimestamp";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { DuplicateJob, DuplicateJobManager } from "../../models/duplicate-job";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import BasicStringDropdown from "../../components/WorkRecordAndTasks/BasicStringDropdown";
import { ErrorMessage } from "../../components/ErrorMessage";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { Wrapper } from "@googlemaps/react-wrapper";
import SiteMap from "../../components/SiteMap";
import Marker from "../../components/admin/Marker";
import { ExistingFeedback } from "../../models/feedback";
import FeedbackButton from "../../components/WorkRecordAndTasks/FeedbackButton";
import ViewFeedbackDialog from "../../components/ViewFeedbackDialog";
import {
  FeedbackPlusExtraType,
  getCustomerName,
} from "../Feedback/FeedbackListContainer";
import ViewInvoiceDialog, {
  ViewInvoiceDialogProps,
} from "../../components/Payments/ViewInvoiceDialog";
import { useSiteKeyUsersStore } from "../../store/site-key-users";
import EditInvoiceDialog from "../../components/Invoices/EditInvoiceDialog";
import { generatePDF } from "../../components/Invoices/generatePDF";
import { getEmailList } from "../../utils/getEmailList";
import { getPermissionsMap } from "../Admin/UserManagementContainer";
import { PDFIconWithSpinner } from "../../components/PDFButton";
import BaseInputSelect from "../../components/BaseInputSelect";
import { filterInvoicesByDropdownSelection } from "../Invoices/InvoiceListContainer";
import { InvoiceActionTypes } from "../Invoices/InvoiceListPage";
import InvoiceListForTaskDialog from "../../components/WorkRecordAndTasks/InvoiceListForTaskDialog";
import AssignedToCustomField from "../../components/AssignedToCustomField";
import { getSelectedUsers } from "../../components/CustomFields/getSelectedUsers";
import { getTaskStatusForApprovedTask } from "../../assets/js/tasks";
import convertCustomizationsToCustomFields from "../../assets/js/convertCustomizationsToCustomFields";
import MembershipPill from "../../components/Memberships/MembershipPill";
import {
  getCustomerBalanceString,
  getCustomerBalanceTagColor,
  getCustomerBalanceTextColor,
} from "../../utils/customerBalanceTag";
import cloneDeep from "lodash/cloneDeep";
import StyledSwitchGroup from "../../components/StyledSwitchGroup";
import { ExistingCraftRecord } from "../../models/craft-record";
import { ExistingStiltPhoto } from "../../models/stilt-photo";
import { ExistingEvent } from "../../models/event";
import { ExistingVehicle } from "../../models/vehicle";
import {
  ExistingEstimateItem,
  getEstimateItemList,
} from "../../models/estimate-item";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { ExistingMembership } from "../../models/membership";
import HandleSendEmailDialog from "../../components/estimates/HandleSendEmailDialog";
import { phoneUtils } from "../../utils/phoneUtils";
import { ExistingCustomerContact } from "../../models/customer-contact";
import MultiPayCreditCardDialog, {
  MultiPayCreditCardDialogProps,
} from "../../components/Invoices/MultiPayCreditCardConfirmationDialog";
import { generateMultiPaymentUniqueLink } from "../../assets/js/generateMultiPaymentUniqueLink";
import { getAddress } from "../../components/customers/CreateTask/CustomerLocationSection";
import ConfirmDeleteTaskDialog from "../../components/WorkRecordAndTasks/ConfirmDeleteTaskDialog";
import { BuildingOfficeIcon } from "@heroicons/react/20/solid";
import CustomerTableDialog from "../../components/customers/CustomerTableDialog";
import { ExistingAsset } from "../../models/asset";
import AddAssetDialog from "../../components/AssetsEquipment/AddAssetDialog";
import ConfirmationDialogForInvoiceActions, {
  ConfirmationDialogForInvoiceActionsProps,
} from "../../components/ConfirmationDialogForInvoiceActions";

// #endregion Imports

export default function WorkRecordAndTasksContainer(props: {
  siteKey: string;
}): JSX.Element {
  const isMounted = useRef(true);

  // #region SECTION: setup, useState, react-query, useEffect, etc
  // NOTE: not doing anything with the possible taskID param, at least not right now
  type UrlParams = { workRecordID: string; taskID?: string };
  const { workRecordID } = useParams<UrlParams>();
  if (typeof workRecordID !== "string") {
    throw new Error(`workRecordID was not a string: ${workRecordID}`);
  }

  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  // Needed in case there's a customization with type uid or multiple-uid
  // Also needed for the events panels.
  const [userDisplayNamesMap, userDisplayNamesMapIsLoading] =
    useUserDisplayNamesStore((state) => [
      state.userDisplayNames,
      state.loading,
    ]);

  // For displaying company name on task cards
  const companyList = useSiteKeyCompaniesStore(
    (state) => state.siteKeyCompanies,
  );

  const [membershipTemplateList, isLoadingMembershipTemplateList] =
    useMembershipTemplatesStore((state) => [
      state.membershipTemplates,
      state.loading,
    ]);

  const [siteKeyLocationList, getLocationTitle] = useSiteKeyLocationsStore(
    (state) => [state.siteKeyLocationList, state.getLocationTitle],
  );

  /* HISTORY */
  const navigate = useNavigate();

  function handleGoToCustomerPage(customerId: string) {
    navigate(`${CUSTOMERS_URL}/${customerId}`);
  }

  function handleGoToWorkRecordAndTasksPage(
    craftRecordID: ExistingTask["craftRecordID"],
  ) {
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  function handleGoToWorkRecordList() {
    navigate(`${WORK_RECORD_LIST_URL}/all`);
  }

  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  const navToCreateTask = useNavToCreateTask();
  const navToCreateEstimateByTask = useNavToCreateEstimate();

  const [workRecord, setWorkRecord] = useState<ExistingCraftRecord | null>(
    null,
  );

  // Add state for tracking if record is deleted
  const [isDeleted, setIsDeleted] = useState(false);

  const [selectedAsset, setSelectedAsset] = useState<ExistingAsset | null>(
    null,
  );
  const [isAssetDialogOpen, setIsAssetDialogOpen] = useState(false);
  const [taskAssets, setTaskAssets] = useState<Record<string, ExistingAsset[]>>(
    {},
  );

  const [customerDoc, setCustomerDoc] = useState<ExistingCustomer | null>(null);

  const [attachments, setAttachments] = useState<ExistingAttachment[]>([]);

  const [parentRecordPhotos, setParentRecordPhotos] = useState<
    ExistingStiltPhoto[]
  >([]);
  const [isLoadingParentRecordPhotos, setIsLoadingParentRecordPhotos] =
    useState(false);
  const [isRestoringWRLoading, setIsRestoringWRLoading] = useState(false);

  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);

  const [events, setEvents] = useState<ExistingEvent[]>([]);

  const [estimateList, setEstimateList] = useState<ExistingEstimate[]>([]);

  const [workRecordList, setWorkRecordList] = useState<ExistingCraftRecord[]>(
    [],
  );

  const [vehicleList, setVehicleList] = useState<ExistingVehicle[]>([]);
  const [vehicleListIsLoading, setVehicleListIsLoading] = useState(false);

  const siteKeyUsersList = useSiteKeyUsersStore(
    (state) => state.siteKeyUsersList,
  );

  /* Fetch the list of permissions when this component loads */
  const [permissionsMap, setPermissionsMap] = useState<
    Record<string, ExistingSiteKeyUserPermissions>
  >({});

  const [allTasksForCustomer, setAllTasksForCustomer] = useState<
    ExistingTask[]
  >([]);
  const [allTasksForParentRecord, setAllTasksForParentRecord] = useState<
    ExistingTask[]
  >([]);

  // For displaying on the work record panel.
  const [fullAddress, setFullAddress] = useState<string | null>(null);
  // const [isDeletingWorkRec, setIsDeletingWorkRec] = useState(false);
  const [openEditWorkRecord, setOpenEditWorkRecord] = useState(false);
  const [openViewPreviousJobs, setOpenViewPreviousJobs] = useState(false);

  const [openRescheduleTaskDialog, setOpenRescheduleTaskDialog] =
    useState(false);
  const [openEditTaskDialog, setOpenEditTaskDialog] = useState(false);
  const [selectedTask, setSelectedTask] = useState<ExistingTask | null>(null);

  const [estimateListDialogOpen, setEstimateListDialogOpen] = useState(false);
  const [estimateListForTable, setEstimateListForTable] = useState<
    ExistingEstimate[] | null
  >(null);

  const [invoiceListDialogOpen, setInvoiceListDialogOpen] = useState(false);
  const [multiPayCreditCardDialogProps, setMultiPayCreditCardDialogProps] =
    useState<MultiPayCreditCardDialogProps | null>(null);
  const [bulkManualPaymentDialogProps, setBulkManualPaymentDialogProps] =
    useState<RecordManualPaymentDialogProps | null>(null);

  // for TaskStatusChangeDialog's props
  const [taskForTaskStatusChangeDialog, setTaskForTaskStatusChangeDialog] =
    useState<ExistingTask | null>(null);
  // for TaskStatusChangeDialog's props
  const [
    originalTaskForTaskStatusChangeDialog,
    setOriginalTaskForTaskStatusChangeDialog,
  ] = useState<ExistingTask | null>(null);
  // for TaskStatusChangeDialog's props
  const [openTaskStatusChangeDialog, setOpenTaskStatusChangeDialog] = useState<
    string | null
  >(null);
  // for TaskStatusChangeDialog's props
  const [
    customFieldsForTaskStatusChangeDialog,
    setCustomFieldsForTaskStatusChangeDialog,
  ] = useState<ExistingCustomField[]>([]);

  const [skWorkTypeCustomFields, setSkWorkTypeCustomFields] = useState<
    ExistingCustomField[]
  >([]);
  const [skTaskCustomFields, setSkTaskCustomFields] = useState<
    ExistingCustomField[]
  >([]);

  const [workRecordInvoiceList, setWorkRecordInvoiceList] = useState<
    ExistingStiltInvoice[]
  >([]);
  const [feedbackDoc, setFeedbackDoc] = useState<FeedbackPlusExtraType | null>(
    null,
  );
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  const [openConfirmCloseWRDialog, setOpenConfirmCloseWRDialog] =
    useState(false);
  const [isDeletingTasks, setIsDeletingTasks] = useState(false);

  const [confirmDeleteWRDialogOpen, setConfirmDeleteWRDialogOpen] =
    useState(false);
  const [isDeletingWorkRecord, setIsDeletingWorkRecord] = useState(false);

  const [handleSendEmailDialogOpen, setHandleSendEmailDialogOpen] =
    useState(false);

  const [isHandlePaymentDialogOpen, setIsHandlePaymentDialogOpen] =
    useState(false);
  const [
    isEstimateTableHandlePaymentDialogOpen,
    setIsEstimateTableHandlePaymentDialogOpen,
  ] = useState(false);
  const [
    isInvoiceTableHandlePaymentDialogOpen,
    setIsInvoiceTableHandlePaymentDialogOpen,
  ] = useState(false);
  const [invoicePaymentLink, setInvoicePaymentLink] = useState<string | null>(
    null,
  );

  const [viewEstimateDialogOpen, setViewEstimateDialogOpen] = useState(false);
  const [selectedEstimate, setSelectedEstimate] =
    useState<ExistingEstimate | null>(null);
  const [selectedEstimateLocation, setSelectedEstimateLocation] =
    useState<ExistingCustomerLocation | null>(null);
  const [selectedInvoice, setSelectedInvoice] =
    useState<ExistingStiltInvoice | null>(null);

  const [selectedDateTime, setSelectedDateTime] = useState<DateTime>(
    DateTime.now(),
  );

  /* duplicate job use states */
  const [duplicateJobDialogOpen, setDuplicateJobDialogOpen] = useState(false);
  const [selectCustomerDialogOpen, setSelectCustomerDialogOpen] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<ExistingCustomer | null>(null);
  const [selectedLocationIds, setSelectedLocationIds] = useState<
    ExistingCustomerLocation["id"][]
  >([]);
  const [scheduleDateTime, setScheduleDateTime] = useState(
    getCurrentJSDateWithoutSeconds(),
  );
  const [serviceWindowDuration, setServiceWindowDuration] = useState(2);
  const [scheduledByPriority, setScheduledByPriority] =
    useState<ScheduleByPriorityType | null>(null);
  const [selectedCustomerLocations, setSelectedCustomerLocations] = useState<
    ExistingCustomerLocation[]
  >([]);
  const [taskIDsToDuplicate, setTaskIDsToDuplicate] = useState<string[]>([]);
  const [duplicateEstimates, setDuplicateEstimates] = useState(false);
  const [duplicatePhotos, setDuplicatePhotos] = useState(false);
  const [duplicateAttachments, setDuplicateAttachments] = useState(false);
  const [isDuplicatingJob, setIsDuplicatingJob] = useState(false);
  const [selectedSiteLocation, setSelectedSiteLocation] = useState<string>("");
  const [displayErrorOnDuplicateJob, setDisplayErrorOnDuplicateJob] =
    useState(false);

  /* Map */
  const [showMap, setShowMap] = useState(false);
  const [position, setPosition] = useState<google.maps.LatLngLiteral>({
    lat: 0,
    lng: 0,
  });
  const [zoom, setZoom] = useState<number>(10);

  const selectedTasksLength = taskIDsToDuplicate.filter<string>(
    (task): task is string => task != null,
  ).length;

  // Displayed in the scheduling section, if applicable to the current work/task type combo.
  const [assignedTo_TaskCF, setAssignedTo_TaskCF] =
    useState<MultipleUidCustomField | null>(null);
  const [assignedToUIDs, setAssignedToUIDs] = useState<string[] | null>(null);
  // Store the custom fields the user needs to respond to.
  const [customFields, setCustomFields] = useState<ExistingCustomField[]>([]);

  useEffect(() => {
    async function loadTaskAssets() {
      const assets: Record<string, ExistingAsset[]> = {};

      for (const task of allTasksForParentRecord) {
        if (task.assetIDs) {
          try {
            // Use Promise.all to wait for all asset fetches
            const assetPromises = task.assetIDs.map((assetID) =>
              DbRead.assets.getByID(props.siteKey, assetID),
            );
            const fetchedAssets = await Promise.all(assetPromises);
            // Filter out any null results and assign to assets object
            assets[task.id] = fetchedAssets.filter((asset) => asset !== null);
          } catch (error) {
            console.error("Error loading asset:", error);
          }
        }
      }

      setTaskAssets(assets);
    }

    loadTaskAssets();
  }, [allTasksForParentRecord, props.siteKey]);

  useEffect(() => {
    function getWorkRecord() {
      if (!workRecordID) return undefined;

      // First try to get the active record
      const unsubscribe = DbRead.parentRecords.subscribe(
        props.siteKey,
        workRecordID,
        (record) => {
          if (record) {
            setWorkRecord(record);
            setIsDeleted(false);
          } else {
            // If not found, check if it's deleted
            const unsubscribeDeleted = DbRead.parentRecords.subscribeDeleted(
              props.siteKey,
              workRecordID,
              (deletedRecord) => {
                if (deletedRecord) {
                  setIsDeleted(true);
                  setWorkRecord(deletedRecord);
                }
              },
              (error) =>
                logger.error(
                  `Error in subscribeDeletedCraftRecord: ${error.message}`,
                ),
            );
            return unsubscribeDeleted;
          }
        },
        (error) =>
          logger.error(`Error in ${getWorkRecord.name}: ${error.message}`),
      );

      return unsubscribe;
    }

    const unsubscribeFn = getWorkRecord();

    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, workRecordID]);

  useEffect(() => {
    function getCustomerDoc() {
      if (!workRecord) return undefined;

      const customerID = workRecord.customerID ? workRecord.customerID : "";

      const unsubscribe = DbRead.customers.subscribe({
        siteKey: props.siteKey,
        customerID: customerID,
        onChange: setCustomerDoc,
        onError: (error) =>
          logger.error(`Error in ${getCustomerDoc.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getCustomerDoc();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, workRecord]);

  useEffect(() => {
    function getAttachmentList() {
      if (!userPermissions || !workRecord) return undefined;

      const unsubscribe = DbRead.attachments.subscribeAllForWorkRecord({
        siteKey: props.siteKey,
        permissions: userPermissions,
        workRecordID: workRecord.id,
        onChange: setAttachments,
        onError: (error) =>
          logger.error(`Error in ${getAttachmentList.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getAttachmentList();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, userPermissions, workRecord]);

  useEffect(() => {
    function getParentRecordPhotos() {
      if (!userPermissions || !workRecord) return undefined;
      setIsLoadingParentRecordPhotos(true);
      const unsubscribe = DbRead.parentRecordPhotos.subscribeAll({
        siteKey: props.siteKey,
        permissions: userPermissions,
        workRecordID: workRecord.id,
        onChange: setParentRecordPhotos,
        onError: (error) =>
          logger.error(
            `Error in ${getParentRecordPhotos.name}: ${error.message}`,
          ),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getParentRecordPhotos();
    setIsLoadingParentRecordPhotos(false);

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, userPermissions, workRecord]);

  useEffect(() => {
    function getEvents() {
      if (!userPermissions || !workRecord) return undefined;
      const unsubscribe = DbRead.events.subscribeAllByWorkRecordID({
        siteKey: props.siteKey,
        permissions: userPermissions,
        workRecordID: workRecord.id,
        onChange: setEvents,
        onError: (error) =>
          logger.error(`Error in ${getEvents.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getEvents();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, userPermissions, workRecord]);

  useEffect(() => {
    function getEstimateList() {
      if (!userPermissions || !workRecord) return undefined;
      const unsubscribe = DbRead.estimates.subscribeByCraftRecordId(
        props.siteKey,
        workRecord.id,
        setEstimateList,
        (error) =>
          logger.error(`Error in ${getEstimateList.name}: ${error.message}`),
      );
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getEstimateList();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, userPermissions, workRecord]);

  useEffect(() => {
    function getWorkRecordList() {
      if (!customerDoc) return undefined;
      const unsubscribe = DbRead.parentRecords.subscribeAllByCustomerID({
        siteKey: props.siteKey,
        customerID: customerDoc.id,
        onChange: setWorkRecordList,
        onError: (error) =>
          logger.error(`Error in ${getWorkRecordList.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getWorkRecordList();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, customerDoc]);

  useEffect(() => {
    function getVehicleList() {
      setVehicleListIsLoading(true);
      const unsubscribe = DbRead.vehicles.subscribeAll({
        siteKey: props.siteKey,
        onChange: setVehicleList,
        onError: (error) =>
          logger.error(`Error in ${getVehicleList.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getVehicleList();
    setVehicleListIsLoading(false);

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey]);

  useEffect(() => {
    if (siteKeyUsersList.length === 0) return;

    async function getUsersPermissionsMap() {
      const result: Record<string, ExistingSiteKeyUserPermissions> =
        await getPermissionsMap(siteKeyUsersList, props.siteKey);
      setPermissionsMap(result);
    }

    getUsersPermissionsMap();
  }, [props.siteKey, siteKeyUsersList]);

  useEffect(() => {
    if (!workRecordID) return;

    function getInvoices() {
      if (typeof workRecordID !== "string") {
        throw new Error(`workRecordID was not a string: ${workRecordID}`);
      }

      const unsubscribe = DbRead.invoices.subscribeByCraftRecordId(
        props.siteKey,
        workRecordID,
        setWorkRecordInvoiceList,
      );
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getInvoices();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, workRecordID]);

  useEffect(() => {
    function getTaskList() {
      if (!customerDoc || !userPermissions) return undefined;

      const unsubscribe = DbRead.tasks.subscribeSingleCustomerTasks({
        siteKey: props.siteKey,
        permissions: userPermissions,
        customerID: customerDoc.id,
        onChange: (tasks) => {
          setAllTasksForCustomer(tasks);
          setAllTasksForParentRecord(
            tasks.filter((task) => task.craftRecordID === workRecord?.refPath),
          );
        },
        onError: (error) =>
          logger.error(`Error in ${getTaskList.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getTaskList();

    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, customerDoc, userPermissions]);

  // get all the estimate items related to the existing estimates for this work record
  const [estimateItemList, setEstimateItemList] = useState<
    ExistingEstimateItem[]
  >([]);
  useEffect(() => {
    if (estimateList.length === 0) return;

    async function getExistingEstimateItems() {
      const result: ExistingEstimateItem[] = await getEstimateItemList(
        estimateList,
        props.siteKey,
      );
      setEstimateItemList(result);
    }

    getExistingEstimateItems();
  }, [estimateList, props.siteKey]);

  useEffect(() => {
    // need to do an early return if selectedWorkType or selectedTaskType is null.
    // need to display in the UI that they need to select "____" first.
    if (!siteKeyDoc || !workRecord) return;

    const allCustomFields = convertCustomizationsToCustomFields({
      siteKeyDoc,
    });

    const workTypeInt = workRecord.craftType;

    const taskTypeInt: TaskTypesValues[] = [];

    for (const duplicateTaskID of taskIDsToDuplicate) {
      allTasksForParentRecord.map((workRecordTask) => {
        if (workRecordTask.id === duplicateTaskID) {
          taskTypeInt.push(workRecordTask.taskType);
        }
      });
    }

    // Drop the custom fields that are not related to the selected work or task type.
    // eslint-disable-next-line array-callback-return
    const displayThese = allCustomFields.filter((customField) => {
      if (
        customField.craftRecordOrTask === "craftRecord" &&
        customField.craftType === workTypeInt
      ) {
        return customField;
      }

      if (
        customField.craftRecordOrTask === "task" &&
        customField.craftType === workTypeInt &&
        taskTypeInt.includes(customField.taskType)
      ) {
        return customField;
      }
      return undefined;
    });

    setCustomFields(displayThese);
  }, [workRecord, taskIDsToDuplicate, siteKeyDoc]);

  useEffect(() => {
    // If there's an assignedTo task custom field that is editable and whose
    // onTaskStatus array contains the current taskStatus, we want to display it
    // in the Scheduling section.
    const taskStatus = getTaskStatusForApprovedTask(
      scheduledByPriority,
      convertJSDateToFSTimestamp(scheduleDateTime),
    );
    const found = customFields.find(
      (cf) =>
        cf.id === "assignedTo" &&
        cf.craftRecordOrTask === "task" &&
        cf.fieldType === "multiple-uid",
    );
    if (found && found.editable && found.onTaskStatus?.includes(taskStatus)) {
      const assignedTo = found as MultipleUidCustomField;
      // ^ can safely typecast because the fieldType is multiple-uid.
      setAssignedTo_TaskCF(assignedTo);
      if (assignedToUIDs === null) {
        setAssignedToUIDs(assignedTo.defaultValue);
      }
    }
  }, [customFields, scheduleDateTime, scheduledByPriority]);

  useEffect(() => {
    if (workRecord) {
      setPosition({
        lat: workRecord.latitude,
        lng: workRecord.longitude,
      });
    }
  }, [workRecord]);

  useEffect(() => {
    if (siteKeyLocationList && siteKeyLocationList.length !== 0) {
      setSelectedSiteLocation(siteKeyLocationList[0].title);
    }
  }, [siteKeyLocationList]);

  useEffect(() => {
    if (customerDoc && !selectedCustomer) {
      setSelectedCustomer(customerDoc);
    }
  }, [customerDoc, selectedCustomer]);

  useEffect(() => {
    async function setNewCustomerLocations() {
      const newLocations = await DbRead.customerLocations.getByCustomerId(
        props.siteKey,
        selectedCustomer!.id,
      );
      setSelectedCustomerLocations(newLocations);
    }

    if (selectedCustomer) {
      setNewCustomerLocations();
    }
  }, [props.siteKey, selectedCustomer]);

  // For displaying on the work record panel.
  const [currentLocation, setCurrentLocation] =
    useState<ExistingCustomerLocation | null>(null);

  const [viewInvoiceDialogProps, setViewInvoiceDialogProps] =
    useState<ViewInvoiceDialogProps | null>(null);
  const [isViewInvoiceDialogOpen, setIsViewInvoiceDialogOpen] = useState(false);

  const [editInvoiceDialogOpen, setEditInvoiceDialogOpen] = useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState<DateTime | null>(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState<DateTime>(
    DateTime.now(),
  );
  const [paymentTerms, setPaymentTerms] = useState<
    ExistingStiltInvoice["paymentTerms"] | null
  >(null);

  const templatesPaymentTerms: Record<string, TemplatePaymentTerm> =
    siteKeyDoc?.customizations.paymentTerms ?? {};

  useEffect(() => {
    if (selectedInvoice) {
      if (selectedInvoice.dueDate != null) {
        setSelectedDueDate(
          convertFSTimestampToLuxonDT(selectedInvoice.dueDate),
        );
      }
      if (selectedInvoice.issueDate != null) {
        setSelectedIssueDate(
          convertFSTimestampToLuxonDT(selectedInvoice.issueDate),
        );
      }
    }
  }, [selectedInvoice]);

  /* const for job estimate */
  const approvedEstimateList = [...estimateList].filter(
    (estimate) => estimate.status === EstimateStatus.APPROVED,
  );

  const completedTasks = [...allTasksForParentRecord].filter(
    (task) => task.taskStatus === TaskStatus.COMPLETE,
  );

  const openTasks = [...allTasksForParentRecord].filter(
    (task) => task.taskStatus < TaskStatus.COMPLETE,
  );

  let amountDue = 0;
  let amountPaid = 0;

  workRecordInvoiceList.forEach((invoice) => {
    if (invoice.status !== "canceled") {
      amountDue += invoice.amountDue;
      amountPaid += invoice.totalAmount - invoice.amountDue;
    }
  });

  const completeColors = {
    generalBg: "bg-green-100",
    iconBg: "bg-green-200",
  };

  const uncompleteColors = {
    generalBg: "bg-red-100",
    iconBg: "bg-red-200",
  };

  const neutralColors = {
    generalBg: "bg-white",
    iconBg: "bg-gray-200",
  };

  const jobList: JobCardStructure[] = [
    {
      icon: AccountTreeIcon,
      jobDescription: "job created",
      colors: completeColors,
    },
    {
      icon: ReceiptIcon,
      jobDescription:
        estimateList.length === 0 || estimateList.length > 1
          ? `${estimateList.length} estimates created`
          : `${estimateList.length} estimate created`,
      colors: estimateList.length > 0 ? completeColors : neutralColors,
      onClick: () => handleOpenEstimateListDialog(),
    },
    {
      icon: ReceiptIcon,
      jobDescription:
        approvedEstimateList.length === 0 || approvedEstimateList.length > 1
          ? `${approvedEstimateList.length} estimates approved`
          : `${approvedEstimateList.length} estimate approved`,
      colors: approvedEstimateList.length > 0 ? completeColors : neutralColors,
      onClick: () => handleOpenEstimateListDialog(),
    },
    {
      icon: FormatListBulletedIcon,
      jobDescription: getJobDescription(openTasks, completedTasks),
      colors:
        openTasks.length > 0 || completedTasks.length > 0
          ? completeColors
          : neutralColors,
    },
    {
      icon: RequestQuoteIcon,
      jobDescription: getInvoiceSectionString(workRecordInvoiceList),
      colors:
        workRecordInvoiceList.filter((i) => i.status !== "canceled").length > 0
          ? completeColors
          : neutralColors,
      onClick: () => setInvoiceListDialogOpen(true),
    },
    {
      icon: RequestQuoteIcon,
      jobDescription: `${DollarCurrency.format(
        amountDue,
      )} due / ${DollarCurrency.format(amountPaid)} paid`,
      colors:
        // eslint-disable-next-line no-nested-ternary
        amountDue === 0 && amountPaid === 0
          ? neutralColors
          : amountDue === 0
            ? completeColors
            : uncompleteColors,
      onClick: () => setInvoiceListDialogOpen(true),
    },
  ];

  useEffect(() => {
    if (!siteKeyDoc || !workRecord) return;

    const targetWorkType = workRecord.craftType;
    if (isValidCraftType(targetWorkType)) {
      const result = getCraftCustomFieldList({
        siteKey: siteKeyDoc,
        targetWorkType,
      });
      setSkWorkTypeCustomFields(result);
    } else {
      setSkWorkTypeCustomFields([]);
    }
  }, [siteKeyDoc, workRecord]);

  const validPhotosRef = useRef<ValidPhoto[]>([]);
  useEffect(() => {
    if (isLoadingParentRecordPhotos) return;

    const photos: ValidPhoto[] = parentRecordPhotos.reduce((acc, photo) => {
      if (photo.id && photo.photoURL) {
        acc.push({
          id: photo.id,
          original: photo.photoURL,
          thumbnail:
            photo.photoURL_thumb === "" ? photo.photoURL : photo.photoURL_thumb,
          description: convertToReadableTimestamp(photo.timestampCreated),
        });
      }
      return acc;
    }, [] as ValidPhoto[]);

    validPhotosRef.current = photos;
  }, [parentRecordPhotos, isLoadingParentRecordPhotos]);

  useEffect(() => {
    async function getFullAddress() {
      // Typeguard
      if (selectedCustomerLocations.length === 0) return;
      if (!workRecord) return;

      if (selectedCustomerLocations.length > 1) {
        // More than one customer location doc => need to get the correct location
        // from the workRecord doc.
        const locationID = workRecord.customerLocationID;
        const doc = selectedCustomerLocations.find(
          (location) => location.id === locationID,
        );
        setFullAddress(doc ? getAddress(doc) : null);
        setCurrentLocation(doc ? doc : null);
      } else {
        setFullAddress(getAddress(selectedCustomerLocations[0]));
        setCurrentLocation(selectedCustomerLocations[0]);
      }
    }

    getFullAddress();
  }, [selectedCustomerLocations, workRecord]);

  type SKLIdAndTitle = Record<
    ExistingSiteKeyLocation["id"],
    ExistingSiteKeyLocation["title"]
  >;
  // Need to allow updates to the work record doc's locationID
  /** **Just the IDs and titles** */
  const skLocationIdAndTitleListRef = useRef<SKLIdAndTitle[]>([]);
  useEffect(() => {
    async function fetchSiteKeyLocationDocs() {
      const locations = await DbRead.siteKeyLocations.getAll(props.siteKey);
      const idsAndTitles: SKLIdAndTitle[] = locations.map((location) => {
        return { [location.id]: location.title };
      });

      skLocationIdAndTitleListRef.current = idsAndTitles;
    }

    fetchSiteKeyLocationDocs();
  }, [props.siteKey]);

  const userList = useRef<IMultipleUid_AssignUser[]>([]);
  useEffect(() => {
    async function getUserList() {
      if (!siteKeyDoc) return;

      userList.current = siteKeyUsersList
        // we don't want system users to be selectable in the multiple-uid dialog
        .filter((user) => user.systemUser !== true)
        .map((user) => ({
          uid: user.id,
          name: user.displayName,
          company: user.companyName,
          avatarUrl: user.userPhoto_URL,
          isAssigned: false,
          assignedVehicle: null,
        }));
    }

    getUserList();
  }, [siteKeyDoc, siteKeyUsersList]);

  /**
   * in the near term, the useEffect that calls getFeedbackList will only return
   * one feedback doc.
   *
   * here, we're getting the most recent feedback doc (via checking
   * the timestampCreated property) and setting that as the feedback data to display.
   *
   * in the long term (+1 year from now), it's possible that there will be more than
   * one doc for a given work record. (so we're handling that case now.)
   */
  const setLatestFeedback = useCallback(
    function (list: ExistingFeedback[]): void {
      if (!customerDoc) {
        logger.error(`${setLatestFeedback.name} needs customer doc access.`);
        return;
      }

      switch (list.length) {
        case 0: {
          setFeedbackDoc(null);
          break;
        }
        case 1: {
          const customerName = getCustomerName(
            customerDoc.name,
            customerDoc.firstName,
            customerDoc.lastName,
          );
          const customerPhone =
            customerDoc.phone === null || customerDoc.phone.length === 0
              ? "--"
              : customerDoc.phone[0];
          setFeedbackDoc({ ...list[0], customerName, customerPhone });
          break;
        }
        default: {
          const sorted = list.sort(
            (a, b) =>
              b.timestampCreated.toMillis() - a.timestampCreated.toMillis(),
          );
          const customerName = getCustomerName(
            customerDoc.name,
            customerDoc.firstName,
            customerDoc.lastName,
          );
          const customerPhone =
            customerDoc.phone === null || customerDoc.phone.length === 0
              ? "--"
              : customerDoc.phone[0];
          setFeedbackDoc({ ...sorted[0], customerName, customerPhone });
        }
      }
    },
    [customerDoc],
  );

  // Subscribe to realtime updates from the feedbacks collection.
  useEffect(() => {
    // setLatestFeedback needs access to customerDoc
    if (!customerDoc) return;

    function getFeedbackList() {
      if (typeof workRecordID !== "string") {
        throw new Error(`workRecordID was not a string: ${workRecordID}`);
      }

      const unsubscribe = DbRead.feedbacks.subscribeByWorkRecordID({
        siteKeyID: props.siteKey,
        workRecordID,
        // doing this a little differently.. using a plain function instead of a
        // function that comes from useState. (see ƒn docs for explanation)
        onChange: setLatestFeedback,
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getFeedbackList();
    // eslint-disable-next-line consistent-return
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, workRecordID, customerDoc, setLatestFeedback]);

  useEffect(() => {
    async function getScheduledAwaitingTaskList() {
      if (!userPermissions) return undefined;

      // Start loading.
      if (isMounted.current)
        setIsLoadingScheduledAwaitingTasksWithLocation(true);

      //setting the date selected to doesn't care about the hours selected but
      // check the entire day
      const startDate = DateTime.fromJSDate(scheduleDateTime).set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      const endDate = DateTime.fromJSDate(scheduleDateTime)
        .set({
          hour: 23,
          minute: 59,
          second: 59,
        })
        .plus({ days: 30 });

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubscribeScheduledAwaitingTaskList =
        DbRead.tasks.subscribeScheduledAwaitingTaskList({
          siteKey: props.siteKey,
          permissions: userPermissions,
          startDate: convertLuxonDTToFSTimestamp(startDate),
          endDate: convertLuxonDTToFSTimestamp(endDate),
          onChange: async (list) => {
            const tasksWithCLs: ExistingTaskWithCustomerLocation[] = [];
            const clIDs = list.flatMap((task) => task.customerLocationID ?? []);
            const uniqueIDs = Array.from(new Set(clIDs));
            const promises = uniqueIDs.map((clID) =>
              DbRead.customerLocations.getSingle(props.siteKey, clID),
            );
            const customerLocations = await Promise.all(promises);

            for (const task of list) {
              const taskWithCL: ExistingTaskWithCustomerLocation = task;
              if (task.customerLocationID) {
                const found = customerLocations.find(
                  (cl) => cl.id === task.customerLocationID,
                );
                taskWithCL.customerLocation = found;
                tasksWithCLs.push(taskWithCL);
              }
            }
            setScheduledAwaitingTasksWithLocation(tasksWithCLs);
          },
          onError: (error) =>
            logger.error(
              `Error in ${getScheduledAwaitingTaskList.name}: ${error.message}`,
            ),
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setIsLoadingScheduledAwaitingTasksWithLocation(false);
      }
      return unsubscribeScheduledAwaitingTaskList;
    }

    const unsubscribePromises = getScheduledAwaitingTaskList();

    return () => {
      logger.debug(
        "(WorkRecordAndTasksContainer) getScheduledAwaitingTaskList useEffect return ƒn just invoked.",
      );
      isMounted.current = false;
      unsubscribePromises.then((unsubscribe) => {
        if (unsubscribe) unsubscribe();
      });
    };
  }, [props.siteKey, scheduleDateTime, userPermissions]);

  const [memberships, setMemberships] = useState<ExistingMembership[]>([]);
  const [membershipsIsLoading, setMembershipsIsLoading] = useState(false);

  useEffect(() => {
    function getMemberships() {
      if (!customerDoc) return undefined;
      setMembershipsIsLoading(true);
      const unsubscribe = DbRead.memberships.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: customerDoc.id,
        onChange: (snapshot) => {
          // don't show expired memberships other than the most recent expired membership
          const expiredMemberships = snapshot.filter(
            (m) => m.status === "expired",
          );
          let mostRecentExpiredMembership: ExistingMembership | null = null;
          if (expiredMemberships.length > 0) {
            mostRecentExpiredMembership = expiredMemberships.reduce(
              (prev, current) => {
                return (prev.membershipEndDate ?? prev.timestampCreated) >
                  (current.membershipEndDate ?? prev.timestampCreated)
                  ? prev
                  : current;
              },
            );
          }
          const nonExpiredMemberships = snapshot.filter(
            (m) => m.status !== "expired",
          );
          const allMembershipsToDisplay = [...nonExpiredMemberships];

          if (mostRecentExpiredMembership != null) {
            allMembershipsToDisplay.push(mostRecentExpiredMembership);
          }

          setMemberships(allMembershipsToDisplay);
        },
        onError: (error) =>
          logger.error(`Error in ${getMemberships.name}: ${error.message}`),
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getMemberships();
    setMembershipsIsLoading(false);

    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, customerDoc]);

  const customerLocationMemberships = memberships.filter(
    (membership) =>
      membership.customerLocationID === workRecord?.customerLocationID &&
      membership.status !== "canceled",
  );

  const emailList = getEmailList(selectedInvoice, customerDoc);

  const [
    scheduledAwaitingTasksWithLocation,
    setScheduledAwaitingTasksWithLocation,
  ] = useState<ExistingTaskWithCustomerLocation[]>([]);

  const [
    isLoadingScheduledAwaitingTasksWithLocation,
    setIsLoadingScheduledAwaitingTasksWithLocation,
  ] = useState(false);

  const [actionsLoading, setActionsLoading] = useState(false);
  const [confirmationDialogProps, setConfirmationDialogProps] =
    useState<ConfirmationDialogForInvoiceActionsProps>({
      invoiceActionType: "",
      body: "",
      isOpen: false,
      handleConfirmAction: () => {},
      title: "",
      isSubmitting: false,
      onClose: () => {},
      pdfBatchActionButtons: false,
      pendingInvoiceIDsLength: null,
    });

  const [stiltInvoiceStatusSelection, setStiltInvoiceStatusSelection] =
    useState<StiltInvoiceStatusValues | null>(null);
  const invoiceStatus: (StiltInvoiceStatusValues | null)[] = [
    null,
    ...Object.values(OStiltInvoiceStatus),
  ];
  const filteredListOfInvoices = filterInvoicesByDropdownSelection(
    stiltInvoiceStatusSelection,
    workRecordInvoiceList,
  );

  const [sendJobReminderEmail, setSendJobReminderEmail] = useState(false);

  const [customerContacts, setCustomerContacts] = useState<
    ExistingCustomerContact[] | null
  >(null);

  useEffect(() => {
    function getSelectedCustomerContacts() {
      if (!selectedCustomer) return undefined;
      const unsubscribe = DbRead.customerContacts.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: selectedCustomer.id,
        onChange: setCustomerContacts,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getSelectedCustomerContacts();
    return () => unsubscribeFn && unsubscribeFn();
  }, [selectedCustomer, props.siteKey]);

  // Add this state near your other useState declarations
  const [confirmDeleteTaskDialogOpen, setConfirmDeleteTaskDialogOpen] =
    useState(false);
  const [taskToDelete, setTaskToDelete] = useState<string | null>(null);
  const [isDeletingTask, setIsDeletingTask] = useState(false);

  async function executeDeleteTask(id: string): Promise<void> {
    const taskDoc = allTasksForParentRecord.find((task) => task.id === id);
    setIsDeletingTask(true);

    try {
      if (taskDoc) {
        await mutateDeleteTask.mutateAsync({ taskDoc });
        addToastMessage({
          id: createToastMessageID(),
          message: strings.successfulDelete(`task ${taskDoc.title}`),
          dialog: false,
          type: "success",
        });
      }
    } catch (error) {
      logger.error(`error while deleting this task:`, id, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_DELETE_TASK,
        dialog: false,
        type: "error",
      });
    } finally {
      setIsDeletingTask(false);
      setConfirmDeleteTaskDialogOpen(false);
      setTaskToDelete(null);
    }
  }

  // #endregion setup, useState, react-query, useEffect, etc

  // #region useMutation
  const mutateUpdateWorkRecord = useMutation(
    async (args: { workRecID: string; update: DocumentData }) =>
      DbWrite.parentRecords.update(args.workRecID, props.siteKey, args.update),
  );

  const mutateUpdateCraftDetails = useMutation(
    async (args: { refPath: string; updateData: DocumentData }) =>
      await DbWrite.craftDetails.update(args.refPath, args.updateData),
  );

  const mutateUpdateTaskStatusToCanceled = useMutation(
    async (args: { taskID: string; updateData: DocumentData }) =>
      await DbWrite.tasks.update(args.updateData, props.siteKey, args.taskID),
  );

  const mutateDeleteTask = useMutation(
    async (args: { taskDoc: ExistingTask }) =>
      await DbWrite.tasks.delete(args.taskDoc),
  );

  const mutateAddAttachment = useMutation(
    async (doc: DocumentData) =>
      await DbWrite.attachments.createForWorkRecord({
        siteKey: props.siteKey,
        attachmentDoc: doc,
      }),
  );

  const mutateDeleteAttachment = useMutation(
    async (id: ExistingAttachment["id"]) =>
      await DbWrite.attachments.deleteForWorkRecord({
        siteKey: props.siteKey,
        attachmentID: id,
      }),
  );

  const mutateUploadParentRecordPhoto = useMutation(
    async (args: { workRecordID: string; data: DocumentData }) =>
      await DbWrite.parentRecordPhotos.add({
        siteKey: props.siteKey,
        workRecordID: args.workRecordID,
        photoDoc: args.data,
      }),
  );

  const mutateDeleteParentRecordPhoto = useMutation(
    async (args: { workRecordID: string; photoID: string }) =>
      await DbWrite.parentRecordPhotos.delete({
        siteKey: props.siteKey,
        workRecordID: args.workRecordID,
        photoID: args.photoID,
      }),
  );

  const mutateTask = useMutation(
    async (args: { taskID: string; updateData: DocumentData }) =>
      await DbWrite.tasks.update(args.updateData, props.siteKey, args.taskID),
  );

  const mutateTSD = useMutation(
    async (args: { refPath: string; updateData: DocumentData }) =>
      await DbWrite.taskSpecificDetails.update(args.refPath, args.updateData),
  );

  const mutateDeleteWorkRecord = useMutation(
    async (args: { workRecordRefPath: string }) =>
      await DbWrite.parentRecords.delete(args.workRecordRefPath),
  );

  const mutateCreateInvoice = useMutation(
    async (args: { siteKey: string; estimateID: string; version: string }) => {
      return DbWrite.invoices.create({
        siteKey: args.siteKey,
        estimateID: args.estimateID,
        version: args.version,
        platform: "web",
      });
    },
  );

  const mutateRecordManualPayment = useMutation(
    async (args: { paymentData: StiltPayment_CreateAPI }) => {
      await DbWrite.payments.manualPayment(args.paymentData);
    },
  );

  const mutateDuplicateJob = useMutation(
    async (args: { duplicateJob: DuplicateJob }) => {
      await DbWrite.parentRecords.duplicate(args.duplicateJob);
    },
  );

  const mutateUpdateInvoice = useMutation(
    async (args: { validPartialInvoiceData: StiltInvoice_UpdateAPI }) => {
      await DbWrite.invoices.update(args.validPartialInvoiceData);
    },
  );

  // Add this with the other mutations
  const mutateRestoreWorkRecord = useMutation(
    async (args: { workRecordRefPath: string }) =>
      await DbWrite.parentRecords.restore(args.workRecordRefPath),
  );
  // #endregion useMutation

  // #region SECTION: Functions
  async function handleGetPDFBatch(
    invoiceIDs: string[],
    includeJobPhotos: boolean,
    displayPaymentLink: boolean,
  ) {
    if (siteKeyDoc) {
      await generatePDF.invoice(
        siteKeyDoc,
        invoiceIDs,
        displayPaymentLink,
        includeJobPhotos,
        userPermissions,
      );
    }
  }

  async function handleChangeInvoiceStatusBatch(
    invoiceIDs: string[],
    newStatus: StiltInvoiceStatus,
  ) {
    if (siteKeyDoc) {
      await DbWrite.invoices.batchUpdateStatus(
        siteKeyDoc.id,
        invoiceIDs,
        newStatus,
      );
    }
  }

  function onClickHandlePaymentButton(invoiceID: ExistingStiltInvoice["id"]) {
    const invoiceDoc = workRecordInvoiceList.find(
      (invoice) => invoice.id === invoiceID,
    );
    setIsHandlePaymentDialogOpen(true);
    setSelectedInvoice(invoiceDoc ?? null);
  }

  function onClickHandlePaymentTableRowInvoice(
    invoiceID: ExistingStiltInvoice["id"],
  ) {
    const invoiceDoc = workRecordInvoiceList.find(
      (invoice) => invoice.id === invoiceID,
    );
    setIsInvoiceTableHandlePaymentDialogOpen(true);
    setSelectedInvoice(invoiceDoc ?? null);
  }

  function closeViewInvoiceDialog(): void {
    setIsViewInvoiceDialogOpen(false);
    setViewInvoiceDialogProps(null);
  }

  async function handleRefund(
    paymentID: string,
    refundAmount: number,
  ): Promise<void> {
    // try/catch is in the InvoiceSummary component; don't want it here
    return DbWrite.payments.issueRefund(props.siteKey, paymentID, refundAmount);
  }

  async function handleEditInvoiceDialogOpen() {
    if (selectedInvoice) {
      setEditInvoiceDialogOpen(true);
    }
  }

  function handlePaymentTermsChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = event.target.value;
    if (value === "") {
      setPaymentTerms(null);
    } else {
      setPaymentTerms(value);
      const selectedPaymentTermTemplate = Object.entries(
        templatesPaymentTerms,
      ).find(([key, _]) => key === value);
      if (!selectedPaymentTermTemplate) return;
      const newDueDate = selectedIssueDate.plus({
        days: selectedPaymentTermTemplate[1].daysUntilDue,
      });
      setSelectedDueDate(newDueDate);
    }
  }

  async function handleSaveUpdatedInvoice(
    updatedInvoice: Partial<ExistingStiltInvoice>,
  ) {
    if (!selectedInvoice) return;

    const updatedInvoiceFields: StiltInvoice_UpdateAPI = {
      ...updatedInvoice,
      paymentTerms: paymentTerms,
      dueDate: selectedDueDate ? selectedDueDate.toString() : null,
      issueDate: selectedIssueDate?.toString(),
    };

    const diffInvoiceValues: DocumentData = diffObjects(
      selectedInvoice,
      updatedInvoiceFields,
    ).diff;

    if (Object.keys(diffInvoiceValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const editInvoiceToValidate: Partial<ExistingStiltInvoice> = {
      ...diffInvoiceValues,
      id: selectedInvoice.id,
      refPath: selectedInvoice.refPath,
    };

    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
      editInvoiceToValidate,
    );
    logger.info("validatedEditInvoice", validatedEditInvoice);

    try {
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });

      logger.debug("Payment terms has been updated successfully.");
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Payment terms for Invoice #${selectedInvoice.invoiceNumber}`,
        ),
        dialog: true,
        type: "success",
      });
      setIsViewInvoiceDialogOpen(false);
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewPaymentTerms`, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: true,
        type: "error",
      });
    }

    setSelectedInvoice(null);
  }

  async function handleConfirmDeleteWR() {
    if (workRecord == null) return;
    setIsDeletingWorkRecord(true);

    try {
      await mutateDeleteWorkRecord.mutateAsync({
        workRecordRefPath: workRecord.refPath,
      });

      handleGoToWorkRecordList();
    } catch (error) {
      logger.error(`error while running handleConfirmDeleteWR: `, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_DELETE_WORK_REC,
        dialog: false,
        type: "error",
      });
    } finally {
      setIsDeletingWorkRecord(false);
      setConfirmDeleteWRDialogOpen(false);
    }
  }

  async function handleConfirmCloseWR() {
    if (openTasks.length < 1) return;
    setIsDeletingTasks(true);

    try {
      for (const taskDoc of openTasks) {
        const taskUpdate: DocumentData = {
          taskStatus: 99,
          lastModifiedBy: firebaseUser.uid,
          timestampLastModified: Timestamp.now(),
        };

        await mutateUpdateTaskStatusToCanceled.mutateAsync({
          taskID: taskDoc.id,
          updateData: taskUpdate,
        });
      }

      await handleCloseWorkRecord();
    } catch (error) {
      logger.error(`error while running handleConfirmCloseWR: `, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_UPDATE_TASK,
        dialog: false,
        type: "error",
      });
    } finally {
      setIsDeletingTasks(false);
      setOpenConfirmCloseWRDialog(false);
    }
  }

  async function handleCloseWorkRecord() {
    if (!workRecord) {
      logger.error("work record is null; this should not have happened");
      return;
    }

    const updateWorkRecord: DocumentData = {
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
      open: false,
      timestampRecordClosed: Timestamp.now(),
    };

    await mutateUpdateWorkRecord.mutateAsync({
      workRecID: workRecord.id,
      update: updateWorkRecord,
    });

    addToastMessage({
      id: createToastMessageID(),
      message: strings.successfulUpdate("Work record"),
      dialog: false,
      type: "success",
    });
  }

  async function handleUpdateWorkRecord(formValues: Record<string, Json>) {
    if (!workRecord) {
      logger.error("work record is null; this should not have happened");
      return;
    }

    const previousValues = {
      title: workRecord.title,
      description: workRecord.description,
      locationID: workRecord.locationID,
    };

    const {
      title = previousValues.title,
      description = previousValues.description,
      locationID = previousValues.locationID,
      // ^ assign default values if any of these are undefined
      ...newCraftDetails
    } = formValues;

    const newWorkRecValues = { title, description, locationID };

    const workRecDiff = diffObjects(previousValues, newWorkRecValues).diff;
    const craftDetailsDiff = diffObjects(
      workRecord.craftDetails,
      newCraftDetails,
    ).diff;

    const changedWorkRec = Object.keys(workRecDiff).length > 0;
    const changedCraftDetails = Object.keys(craftDetailsDiff).length > 0;
    // return early if there's been no change.
    if (!changedWorkRec && !changedCraftDetails) return;

    const updateWorkRecord: DocumentData = {
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
      ...workRecDiff,
    };

    await mutateUpdateWorkRecord.mutateAsync({
      workRecID: workRecord.id,
      update: updateWorkRecord,
    });

    if (changedCraftDetails) {
      await mutateUpdateCraftDetails.mutateAsync({
        refPath: workRecord.refPath,
        updateData: craftDetailsDiff,
      });
    }

    if (workRecDiff.locationID) {
      // Also need to update all tasks with the new locationID
      allTasksForParentRecord.forEach((task) => {
        const updateTask: DocumentData = {
          locationID: workRecDiff.locationID,
          lastModifiedBy: firebaseUser.uid,
          timestampLastModified: Timestamp.now(),
        };

        mutateTask.mutate({
          taskID: task.id,
          updateData: updateTask,
        });
      });
    }

    addToastMessage({
      id: createToastMessageID(),
      message: strings.successfulUpdate("Work record"),
      dialog: false,
      type: "success",
    });
  }

  async function handleGetMultiPaymentCreditCardLink(
    selectedInvoices: ExistingStiltInvoice[],
    amount: number,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }
    if (!siteKeyDoc) {
      logger.error("siteKeyDoc is null");
      return;
    }

    const paymentSource = siteKeyDoc.customizations?.accounting?.ziftAccountID
      ? "ziftHPP"
      : "payaHPP";

    // Create the array of multi-payments
    const payments: CreateMultiPayment[] = [];
    for (const invoice of selectedInvoices) {
      const valuesToBeValidated: CreateMultiPayment = {
        amount: amount,
        checkNumber: null,
        memo: null,
        paymentMethod: "credit_card",
        paymentSource: paymentSource,
        // IMPORTANT: `amount` must be `formValues.amount`. not the invoice amountDue
        timestampCreated: DateTime.now().toISO(),
        timestampPaymentMade: selectedDateTime.toISO(),
        customerID: invoice.customerID,
        invoiceID: invoice.id,
        customData: {},
        createdBy: firebaseUser.uid,
        billToCustomerID: invoice.billToCustomerID,
        locationID: invoice.locationID,
      };

      payments.push(valuesToBeValidated);
    }

    const uniqueLink = await generateMultiPaymentUniqueLink(
      siteKeyDoc.id,
      selectedInvoices.map((inv) => inv.id),
      "web",
    );
    const paymentID = uniqueLink?.split("/").pop();
    if (!paymentID) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      const paymentLink =
        await DbRead.payments.getMultiPayCreditCardData(paymentID);

      if (paymentLink) {
        window.open(paymentLink, "_blank")?.focus();
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.ERROR_PAYMENT_LINK,
          dialog: false,
          type: "error",
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        // 409 = "conflict" error - means that the payment amount did not
        // equal the sum of the amountDue of the given invoices
        if (err.message.includes("409")) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.PAYMENT_DID_NOT_MATCH_INVOICE_AMOUNTS,
            dialog: false,
            type: "error",
          });
          return;
        }
      }
      logger.error("handleGetMultiPaymentCreditCardLink", err);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }
  }

  // #region SECTION: Upload Attachment
  function handleUploadAttachment(args: {
    files: FileList;
    craftRecordID: string;
    setIsUploading: (val: boolean) => void;
    setAddAttachmentSuccess: (val: boolean) => void;
    setAddAttachmentError: (val: boolean) => void;
  }): void {
    args.setIsUploading(true);
    const storage = getStorage();

    const uploadPromises = Array.from(args.files).map((file) => {
      return new Promise<void>((resolve, reject) => {
        const storageRef = ref(
          storage,
          `siteKeys/${args.craftRecordID}/attachments/${file.name}`,
        );

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            switch (error.code) {
              case "storage/quota-exceeded":
                args.setAddAttachmentError(true);
                console.error(
                  "Quota on Cloud Storage bucket has been exceeded.",
                );
                break;
              default:
                args.setAddAttachmentError(true);
                console.error("Error while uploading attachment");
            }
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              await afterUploadComplete_Attachment({
                url: downloadURL,
                filename: file.name,
              });
              resolve();
            } catch (error) {
              reject(error);
            }
          },
        );
      });
    });

    Promise.all(uploadPromises)
      .then(() => {
        args.setIsUploading(false);
        args.setAddAttachmentSuccess(true);
      })
      .catch(() => {
        args.setIsUploading(false);
        args.setAddAttachmentError(true);
      });
  }

  async function afterUploadComplete_Attachment(args: {
    url: string;
    filename: string;
  }) {
    if (!workRecord) {
      logger.error("workRecord is null and it shouldn't be");
      return;
    }

    const docData = {
      url: args.url,
      filename: args.filename,
      authorizedCompanies: [userPermissions?.companyID],
      craftRecordID: workRecord.id,
      timestampCreated: Timestamp.now(),
      createdBy: firebaseUser.uid,
      deleted: false,
    };

    const attachmentDoc = AttachmentManager.parse(docData);
    mutateAddAttachment.mutateAsync(attachmentDoc);
  }

  // #endregion Upload Attachment

  async function handleDeleteAttachment(id: string) {
    mutateDeleteAttachment.mutateAsync(id);
  }

  // #region SECTION: Upload work record photo
  /** Reduced and thumdnail versions are added via backend. */
  function handleUploadWorkRecordPhoto(args: {
    files: FileList;
    setIsUploading: (val: boolean) => void;
  }): void {
    if (!workRecord) {
      logger.error("work record is null and it shouldn't be");
      return;
    }

    args.setIsUploading(true);
    const storage = getStorage();

    const uploadPromises = Array.from(args.files).map((file) => {
      return new Promise<void>((resolve, reject) => {
        const uuidStr = uuidv4();
        const filetype = file.type.split("/")[1];
        const filename = `${uuidStr}.${filetype}`;

        const metadata = {
          customMetadata: {
            requiresCompression: "true",
            fromReactApp: "true",
            workRecordID: workRecord.id,
          },
        };

        const storageRef = ref(
          storage,
          `siteKeys/${props.siteKey}/${filename}`,
        );
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        uploadTask.on(
          "state_changed",
          null,
          (error: StorageError) => {
            switch (error.code) {
              case "storage/quota-exceeded":
                args.setIsUploading(false);
                addToastMessage({
                  id: createToastMessageID(),
                  message: "Quota on Cloud Storage bucket has been exceeded.",
                  dialog: true,
                  type: "error",
                });
                logger.error(
                  "Quota on Cloud Storage bucket has been exceeded.",
                );
                break;
              default:
                args.setIsUploading(false);
                addToastMessage({
                  id: createToastMessageID(),
                  message: "Error while uploading photo",
                  dialog: true,
                  type: "error",
                });
                logger.error("Error while uploading photo");
            }
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              await afterUploadComplete_WorkRecPhoto(downloadURL);
              resolve();
            } catch (err) {
              logger.error("Error while completing upload", err);
              addToastMessage({
                id: createToastMessageID(),
                message: "Error while completing upload",
                dialog: true,
                type: "error",
              });
              reject(err);
            }
          },
        );
      });
    });

    Promise.all(uploadPromises)
      .then(() => {
        args.setIsUploading(false);
      })
      .catch(() => {
        args.setIsUploading(false);
      });
  }

  async function afterUploadComplete_WorkRecPhoto(photoURL: string) {
    if (!workRecord) {
      logger.error("work record is null and it shouldn't be");
      return;
    }

    // Reduced and thumb are generated on the backend
    const docData = {
      photoURL: photoURL,
      photoURL_reduced: "",
      photoURL_thumb: "",
      timestampCreated: Timestamp.now(),
      createdBy: firebaseUser.uid,
    };

    mutateUploadParentRecordPhoto.mutateAsync({
      workRecordID: workRecord.id,
      data: docData,
    });
  }

  // #endregion Upload work record photo

  async function handleDeleteWorkRecordPhoto(id: string) {
    if (!workRecord) {
      logger.error("work record is null and it definitely shouldn't be");
      return;
    }
    mutateDeleteParentRecordPhoto.mutateAsync({
      workRecordID: workRecord.id,
      photoID: id,
    });
  }

  async function handleDownloadWorkRecordPhotos() {
    if (!workRecord) {
      logger.error("work record is null during download all photos");
      return;
    }

    // try/catch exists in PhotoViewer component
    const url = await DbRead.parentRecordPhotos.downloadAll({
      siteKey: props.siteKey,
      workRecordID: workRecord.id,
    });

    // Create anchor element and dispatch a click to trigger download
    const anch = document.createElement("a");
    anch.download = "photos.zip"; // doesn't quite work - still named workRecordID.zip
    anch.href = url;
    document.body.appendChild(anch);
    anch.click();
    anch.remove();
  }

  async function handleDeleteTask(id: string): Promise<void> {
    // Check if this is the last task
    if (allTasksForParentRecord.length === 1) {
      setTaskToDelete(id);
      setConfirmDeleteTaskDialogOpen(true);
      return;
    }

    // If not the last task, proceed with deletion
    await executeDeleteTask(id);
  }

  /** Find the task they clicked, append customerLocation data, open the dialog */
  function handleOpenRescheduleTaskDialog(taskID: string): void {
    const foundTask = allTasksForParentRecord.find(
      (task) => task.id === taskID,
    );
    if (!foundTask) return;

    const taskWithCL: ExistingTaskWithCustomerLocation = foundTask;

    // add customerLocation data to the selected task if it's available
    if (foundTask.customerLocationID) {
      taskWithCL.customerLocation =
        customerDoc?.customerLocations[foundTask.customerLocationID];
    }

    setSelectedTask(taskWithCL);
    setOpenRescheduleTaskDialog(true);
    setSendJobReminderEmail(foundTask.sendJobReminderEmail ?? false);
  }

  /** Find the task they clicked, get the siteKey's customFields for that task type,
   * and open the dialog */
  function handleOpenEditTaskDialog(taskID: string): void {
    const foundTask = allTasksForParentRecord.find(
      (task) => task.id === taskID,
    );
    if (!foundTask || !siteKeyDoc) return;

    const targetWorkType = foundTask.craftType;
    const targetTaskType = foundTask.taskType;
    if (isValidCraftType(targetWorkType) && isValidTaskType(targetTaskType)) {
      const result = getTaskCustomFieldList({
        siteKey: siteKeyDoc,
        targetWorkType,
        targetTaskType,
      });
      setSkTaskCustomFields(result);
    } else {
      logger.error(
        `craftType or taskType was invalid. craftType: ${targetWorkType} ... taskType: ${targetTaskType}`,
      );
      setSkTaskCustomFields([]);
    }

    setSelectedTask(foundTask);
    setOpenEditTaskDialog(true);
  }

  /** Update the task doc's scheduling-related values */
  async function handleRescheduleTask(updatedTask: ExistingTask) {
    if (!selectedTask) {
      logger.error("selectedTask is null (and it shouldn't be)");
      return;
    }

    const { taskSpecificDetails, ...rest } = updatedTask;

    const taskDiff: DocumentData = diffObjects(selectedTask, rest).diff;
    const tsdDiff: DocumentData = diffObjects(
      selectedTask.taskSpecificDetails,
      taskSpecificDetails,
    ).diff;

    const taskNoChange = Object.keys(taskDiff).length === 0;
    const tsdNoChange = Object.keys(tsdDiff).length === 0;
    // return early if there's been no change
    if (taskNoChange && tsdNoChange) return;

    if (!taskNoChange) {
      const updateData = {
        ...taskDiff,
        timestampLastModified: Timestamp.now(),
        lastModifiedBy: firebaseUser.uid,
      };

      // Don't want a try/catch here, that's handled within the dialog component.
      await handleUpdateTask(updateData, selectedTask.id);
    }

    if (!tsdNoChange) {
      // Task doc needs to update before TSD. That's why we're not using Promise.all
      await handleUpdateTSD(tsdDiff, selectedTask.refPath);
    }

    addToastMessage({
      id: createToastMessageID(),
      message: strings.successfulUpdate("Task"),
      dialog: false,
      type: "success",
    });
  }

  // marked async because we need to make a DB call in containers that don't
  // already have access to the current work record.
  function handleOpenTaskStatusChangeDialogDueToScheduleChange(args: {
    updatedTask: ExistingTask;
    originalTask: ExistingTask;
  }) {
    if (!siteKeyDoc) {
      // should never happen
      logger.error(
        `missing siteKey doc while opening task status change dialog. task id: ${args.updatedTask.id}`,
      );
      return;
    }

    // Get the siteKey's customizations for this task
    const targetWorkType = args.updatedTask.craftType;
    const targetTaskType = args.updatedTask.taskType;
    if (isValidCraftType(targetWorkType) && isValidTaskType(targetTaskType)) {
      const taskCustomFields = getTaskCustomFieldList({
        siteKey: siteKeyDoc,
        targetWorkType,
        targetTaskType,
      });
      setCustomFieldsForTaskStatusChangeDialog(taskCustomFields);
    }

    setTaskForTaskStatusChangeDialog(args.updatedTask);
    setOriginalTaskForTaskStatusChangeDialog(args.originalTask);
    setOpenTaskStatusChangeDialog(args.originalTask.id);
  }

  async function handleEditTask(values: Record<string, Json>) {
    if (!selectedTask) return;

    const {
      assetIDs,
      membershipIDs,
      isMembershipTask,
      description,
      primaryContactEmail,
      primaryContactPhone,
      ...tsd
    } = values;

    const changedPrimaryContactEmail =
      selectedTask.primaryContactEmail !== primaryContactEmail;
    const changedPrimaryContactPhone =
      selectedTask.primaryContactEmail !== primaryContactPhone;
    const changedDescription = selectedTask.description !== description;
    const changedMembershipIDs = selectedTask.membershipIDs !== membershipIDs;
    const changedAssetIDs = selectedTask.assetIDs !== assetIDs;
    const changedIsMembershipTask =
      selectedTask.isMembershipTask !== isMembershipTask;
    const tsdDiff = diffObjects(selectedTask.taskSpecificDetails, tsd).diff;
    const changedTSD = Object.keys(tsdDiff).length > 0;

    if (
      !changedDescription &&
      !changedTSD &&
      !changedMembershipIDs &&
      !changedIsMembershipTask &&
      !changedPrimaryContactPhone &&
      !changedPrimaryContactEmail &&
      !changedAssetIDs
    )
      return;

    let taskUpdate = {};
    if (
      changedDescription ||
      changedMembershipIDs ||
      changedIsMembershipTask ||
      changedPrimaryContactEmail ||
      changedPrimaryContactPhone ||
      changedAssetIDs
    ) {
      taskUpdate = {
        description: description,
        membershipIDs: membershipIDs,
        isMembershipTask: Boolean(isMembershipTask),
        primaryContactEmail: primaryContactEmail,
        primaryContactPhone: primaryContactPhone,
        assetIDs: assetIDs,
        timestampLastModified: Timestamp.now(),
        lastModifiedBy: firebaseUser.uid,
      };
    } else {
      taskUpdate = {
        timestampLastModified: Timestamp.now(),
        lastModifiedBy: firebaseUser.uid,
      };
    }

    const xtData = dropUndefined(taskUpdate);

    // try/catch within the dialog
    await handleUpdateTask(xtData, selectedTask.id);

    if (changedTSD) {
      await handleUpdateTSD(tsdDiff, selectedTask.refPath);
    }

    addToastMessage({
      id: createToastMessageID(),
      message: strings.successfulUpdate("Task"),
      dialog: false,
      type: "success",
    });
  }

  async function handleCreateFollowUpTask(
    task: ExistingTask,
    followUpTaskStatus: TaskStatus | null,
  ) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, refPath, ...rest } = task;

    const newTask: Task_CreateForCustomer = {
      ...rest,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      urgent: false,
      nextOpportunity: false,
      workOrder: "",
      crewCount: 0,
      durations: {},
      holdDurations: {},
      taskStatus: followUpTaskStatus ?? rest.taskStatus,
      taskSpecificDetails: {},
      timestampCreated: Timestamp.now().toMillis(),
      timestampLastModified: Timestamp.now().toMillis(),
      timestampScheduled: null,
      timestampAwaitingStart: null,
      timestampTaskCompleted: null,
      timestampTaskStarted: null,
    };

    // Convert to doc data
    const taskReady = TaskRecordManager.convertNewForFirestore(newTask);
    logger.info("taskReady", taskReady);

    await DbWrite.tasks.createForCustomer({
      siteKey: props.siteKey,
      taskDoc: taskReady,
      estimateID: null,
    });
  }

  async function handleUpdateTask(updateData: DocumentData, taskID: string) {
    await mutateTask.mutateAsync({
      updateData,
      taskID,
    });
  }

  async function handleUpdateTSD(updateData: DocumentData, refPath: string) {
    await mutateTSD.mutateAsync({
      updateData,
      refPath,
    });
  }

  // Add this with the other handler functions
  async function handleRestoreWorkRecord() {
    if (!workRecord) return;

    try {
      setIsRestoringWRLoading(true);
      await mutateRestoreWorkRecord.mutateAsync({
        workRecordRefPath: workRecord.refPath,
      });

      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulRestore("Job"),
        dialog: false,
        type: "success",
      });
      setIsRestoringWRLoading(false);
    } catch (error) {
      setIsRestoringWRLoading(false);
      logger.error(`Error in ${handleRestoreWorkRecord.name}: `, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_WR_CANNOT_BE_RESTORED,
        dialog: false,
        type: "error",
      });
    }
  }

  function handleCreateNewEstimate(taskDoc: ExistingTask) {
    if (customerDoc) {
      navToCreateEstimateByTask(customerDoc, taskDoc);
    }
  }

  async function handleGoToViewEstimate(estimateDoc: ExistingEstimate) {
    const invoiceDoc = workRecordInvoiceList.find(
      (invoice) => invoice.estimateID === estimateDoc.id,
    );

    const locationDoc = selectedCustomerLocations.find(
      (location) => location.id === estimateDoc.customerLocationID,
    );

    setSelectedEstimate(estimateDoc);
    setSelectedEstimateLocation(locationDoc ?? selectedCustomerLocations[0]);
    setSelectedInvoice(invoiceDoc ?? null);
    setViewEstimateDialogOpen(true);
  }

  function handleOpenEstimateListDialog(taskID?: ExistingTask["id"]) {
    if (taskID) {
      const estimatesForCurrentTask: ExistingEstimate[] = estimateList.filter(
        (estimate) => estimate.taskID === taskID,
      );

      setEstimateListForTable(estimatesForCurrentTask);
      setEstimateListDialogOpen(true);
    } else {
      setEstimateListForTable(estimateList);
      setEstimateListDialogOpen(true);
    }
  }

  async function handleCreateInvoice(estimate: ExistingEstimate) {
    if (!isWhiteLabel(whiteLabel)) throw Error("Unexpected white label");
    try {
      const { paymentURL, invoiceData } = await mutateCreateInvoice.mutateAsync(
        {
          siteKey: props.siteKey,
          estimateID: estimate.id,
          version: whiteLabel,
        },
      );
      setInvoicePaymentLink(paymentURL);
      setSelectedInvoice(invoiceData);
      setIsEstimateTableHandlePaymentDialogOpen(true);
    } catch (error) {
      logger.error("error on handleCreateInvoice", error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleGoToPaymentPage(): Promise<void> {
    if (invoicePaymentLink != null) {
      goToPaymentPage(invoicePaymentLink);
    } else {
      if (!selectedInvoice) return;
      const paymentLink = await generatePaymentUniqueLink(
        props.siteKey,
        selectedInvoice.id,
        "web",
      );
      if (paymentLink) {
        goToPaymentPage(paymentLink);
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.ERROR_PAYMENT_LINK,
          dialog: false,
          type: "error",
        });
      }
    }
  }

  /** send itemized invoice along with payment link */
  async function emailInvoice(
    email: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!customerDoc || !selectedInvoice) return;
    if (email.length === 0) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    const paymentLink = await generatePaymentUniqueLink(
      props.siteKey,
      selectedInvoice.id,
      "email",
    );
    if (!paymentLink) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.invoices.sendViaEmail({
        siteKey: props.siteKey,
        invoiceURL: paymentLink,
        customerEmailList: email,
        includeJobPhotos: includeJobPhotos,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_INVOICE,
        dialog: false,
        type: "error",
      });
      logger.error("error emailing customer invoice", e);
    }
  }

  async function handleEmailReceipt(emailAddresses: string[]): Promise<void> {
    if (!selectedInvoice) return;
    if (emailAddresses.length === 0) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.payments.emailReceipt({
        siteKeyID: props.siteKey,
        invoiceID: selectedInvoice.id,
        customerEmailList: emailAddresses,
      });
      setEstimateListDialogOpen(false);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error("Failed to email receipt to customer", e);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT_NOTIFIED,
        dialog: false,
        type: "error",
      });
    } finally {
      setSelectedInvoice(null);
    }
  }

  async function sendEmailFromHandlePaymentDialog(
    emails: string[],
    shouldIncludePhotos: boolean,
  ): Promise<void> {
    if (!selectedInvoice) return;
    if (selectedInvoice.amountDue > 0) {
      await emailInvoice(emails, shouldIncludePhotos);
    } else {
      await handleEmailReceipt(emails);
    }
  }

  async function handleGetPDF(invoiceID: string) {
    if (siteKeyDoc) {
      await generatePDF.invoice(
        siteKeyDoc,
        [invoiceID],
        true,
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false,
        userPermissions,
      );
    }
  }

  async function getJobPDF() {
    if (!workRecord || !siteKeyDoc || !customerDoc) return;

    await generatePDF.job(
      siteKeyDoc,
      workRecord,
      customerDoc,
      fullAddress,
      currentLocation,
      estimateList,
      workRecordInvoiceList,
      allTasksForParentRecord,
      customerLocationMemberships,
      membershipTemplateList,
      workRecord.craftDetails,
      skWorkTypeCustomFields,
      userDisplayNamesMap,
      events,
      customerContacts,
    );
  }

  async function getEstimatePDF(estimateID: string) {
    if (!siteKeyDoc) return;
    await generatePDF.estimate(siteKeyDoc, [estimateID]);
  }

  function openViewInvoiceDialog(invoiceID: string): void {
    if (!siteKeyDoc) return;

    const invoiceDoc = workRecordInvoiceList.find(
      (invoice) => invoice.id === invoiceID,
    );

    setViewInvoiceDialogProps({
      siteKeyID: props.siteKey,
      invoiceID,
      merchantName: siteKeyDoc.name,
      merchantLogoURL:
        siteKeyDoc.customizations.accounting?.merchantLogoURL ?? null,
    });
    setSelectedInvoice(invoiceDoc ?? null);
    setIsViewInvoiceDialogOpen(true);
  }

  function resetSelectedEstimate() {
    setSelectedEstimate(null);
    setSelectedEstimateLocation(null);
    setSelectedInvoice(null);
  }

  async function handleRecordManualPayment(
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (!selectedInvoice) {
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }

    const valuesToBeValidated: StiltPayment_CreateAPI = {
      ...formValues,
      stringTimestampPaymentMade: selectedDateTime.toISO(),
      customerID: selectedInvoice.customerID,
      invoiceID: selectedInvoice.id,
      customData: {},
      createdBy: firebaseUser.uid,
      siteKey: props.siteKey,
      billToCustomerID: selectedInvoice.billToCustomerID,
      locationID: selectedInvoice.locationID,
      deleted: false,
    };

    const validPaymentData =
      StiltPaymentManager.parseCreate(valuesToBeValidated);
    logger.info("validPaymentData", validPaymentData);

    try {
      await mutateRecordManualPayment.mutateAsync({
        paymentData: validPaymentData,
      });
      if (
        validPaymentData.amount === selectedInvoice.amountDue &&
        selectedInvoice.status !== "draft" &&
        siteKeyDoc?.customizations.sendAutomatedReceiptToCustomers
      ) {
        if (selectedInvoice.email !== null) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT,
            dialog: true,
            type: "success",
          });
        } else {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_NO_EMAIL,
            dialog: true,
            type: "info",
          });
        }
      } else {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
          dialog: true,
          type: "success",
        });
      }
    } catch (err) {
      logger.error("handleRecordManualPayment", err);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }
  }

  async function applyBulkManualPayment(
    selectedInvoices: ExistingStiltInvoice[],
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }
    if (siteKeyDoc == null) {
      logger.error("siteKeyDoc is null");
      return;
    }

    // Create the array of multi-payments
    const payments: CreateMultiPayment[] = [];
    for (const invoice of selectedInvoices) {
      const values: CreateMultiPayment = {
        ...formValues,
        // IMPORTANT: `amount` must be `formValues.amount`. not the invoice amountDue
        timestampCreated: DateTime.now().toISO(),
        timestampPaymentMade: selectedDateTime.toISO(),
        customerID: invoice.customerID,
        invoiceID: invoice.id,
        customData: {},
        createdBy: firebaseUser.uid,
        billToCustomerID: invoice.billToCustomerID,
        locationID: invoice.locationID,
      };

      payments.push(values);
    }

    const data = {
      payments,
      siteKey: siteKeyDoc.id,
    };

    const validPaymentData = StiltPaymentManager.parseCreateMultiPayment(data);

    try {
      await DbWrite.payments.manualBulkPayment(validPaymentData);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (err) {
      if (err instanceof Error) {
        // 409 = "conflict" error - means that the payment amount did not
        // equal the sum of the amountDue of the given invoices
        if (err.message.includes("409")) {
          addToastMessage({
            id: createToastMessageID(),
            message: strings.PAYMENT_DID_NOT_MATCH_INVOICE_AMOUNTS,
            dialog: false,
            type: "error",
          });
          return;
        }
      }
      logger.error("applyBulkManualPayment", err);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: false,
        type: "error",
      });
      return;
    }
  }

  function handleRemoveSelectedLocation(locationID: string) {
    const newSelection = selectedLocationIds.filter((id) => id !== locationID);
    setSelectedLocationIds(newSelection);
  }

  function handleRemoveSelectedTask(taskID: string) {
    const newSelection = taskIDsToDuplicate.filter((id) => id !== taskID);
    setTaskIDsToDuplicate(newSelection);
  }

  function resetDuplicateJobStates() {
    /* set all the states related to nested dialogs to avoid bugs */
    setSelectCustomerDialogOpen(false);

    /* reset states */
    setSelectedCustomer(null);
    setSelectedLocationIds([]);
    setScheduleDateTime(getCurrentJSDateWithoutSeconds());
    setServiceWindowDuration(2);
    setScheduledByPriority(null);
    setSelectedCustomerLocations([]);
    setTaskIDsToDuplicate([]);
    setDuplicateEstimates(false);
    setDuplicatePhotos(false);
    setDuplicateAttachments(false);
  }

  const handleAssetClick = (asset: ExistingAsset) => {
    setSelectedAsset(asset);
    setIsAssetDialogOpen(true);
  };

  async function handleDuplicateJob() {
    if (!selectedCustomer || selectedLocationIds.length === 0) return;
    setIsDuplicatingJob(true);

    const definedTasks = taskIDsToDuplicate.filter<string>(
      (task): task is string => task != null,
    );

    const siteKeyLocation: ExistingSiteKeyLocation | undefined =
      siteKeyLocationList.find(
        (siteLocation) => siteLocation.title === selectedSiteLocation,
      );

    const valuesCollected = {
      taskIDs: definedTasks,
      duplicateEstimates: duplicateEstimates,
      duplicatePhotos: duplicatePhotos,
      duplicateAttachments: duplicateAttachments,
      craftRecordID: workRecordID,
      customerID: selectedCustomer.id,
      customerLocationIDs: selectedLocationIds,
      timestampScheduleDate:
        scheduledByPriority === "Urgent" || scheduledByPriority === null
          ? scheduleDateTime.toISOString()
          : null,
      scheduledByPriority: scheduledByPriority,
      serviceWindowDuration: serviceWindowDuration,
      siteKey: props.siteKey,
      siteLocationID: siteKeyLocation?.id ?? siteKeyLocationList[0].id,
      assignedTo: assignedToUIDs,
      sendJobReminderEmail: sendJobReminderEmail,
    };

    const validatedDuplicateJob = DuplicateJobManager.parse(valuesCollected);
    try {
      // call the DB
      await mutateDuplicateJob.mutateAsync({
        duplicateJob: validatedDuplicateJob,
      });
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulDuplicate(`Job: ${workRecord?.title}`),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(`error while running handleDuplicateJob: `, error);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.ERR_DUPLICATE_WORK_REC,
        dialog: false,
        type: "error",
      });
    }
    /* stop loading spinner */
    setIsDuplicatingJob(false);
    /* close dialog */
    setDuplicateJobDialogOpen(false);
    /* reset all states related to the duplicate job dialog */
    resetDuplicateJobStates();
  }

  async function handleInvoiceActionSelected(
    invoiceActionType: InvoiceActionTypes,
    selectedRows: ExistingStiltInvoice[],
  ) {
    if (invoiceActionType === "convertDraftToPending") {
      const invoiceIDs: any[] = [];
      for (const row of selectedRows) {
        // Only send if there's an email
        if (row.status === "draft") {
          invoiceIDs.push(row.id);
        }
      }
      setConfirmationDialogProps({
        invoiceActionType: "convertDraftToPending",
        body: `Of the ${selectedRows.length} invoices selected, ${invoiceIDs.length} will be converted to Pending. Confirm changing ${invoiceIDs.length} invoices to Pending?`,
        title: "Convert Invoices To Pending Status?",
        isSubmitting: false,
        isOpen: true,
        pdfBatchActionButtons: false,
        pendingInvoiceIDsLength: null,
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          }),
        handleConfirmAction: async () => {
          setActionsLoading(true);
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          });
          try {
            await handleChangeInvoiceStatusBatch(
              invoiceIDs,
              StiltInvoiceStatus.PENDING,
            );
            addToastMessage({
              id: createToastMessageID(),
              message: `Invoices converted to Pending`,
              dialog: false,
              type: "success",
            });
          } catch (e) {
            addToastMessage({
              id: createToastMessageID(),
              message: `Error converting invoices to pending`,
              dialog: false,
              type: "warning",
            });
          } finally {
            setActionsLoading(false);
          }
        },
      });
    }
    if (invoiceActionType === "convertZeroAmountInvoicesToPaid") {
      const invoiceIDs: any[] = [];
      for (const row of selectedRows) {
        // Only allow this if invoice has no amountDue and is not canceled
        if (row.status !== "canceled" && row.amountDue === 0) {
          invoiceIDs.push(row.id);
        }
      }
      setConfirmationDialogProps({
        invoiceActionType: "convertZeroAmountInvoicesToPaid",
        body: `Of the ${selectedRows.length} invoices selected, ${invoiceIDs.length} will be converted to Paid. Confirm changing ${invoiceIDs.length} invoices to Paid?`,
        title: "Convert Invoices To Paid Status?",
        isSubmitting: false,
        isOpen: true,
        pdfBatchActionButtons: false,
        pendingInvoiceIDsLength: null,
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          }),
        handleConfirmAction: async () => {
          setActionsLoading(true);
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          });
          try {
            await handleChangeInvoiceStatusBatch(
              invoiceIDs,
              StiltInvoiceStatus.PAID,
            );
            addToastMessage({
              id: createToastMessageID(),
              message: `Invoices converted to Paid`,
              dialog: false,
              type: "success",
            });
          } catch (e) {
            addToastMessage({
              id: createToastMessageID(),
              message: `Error converting invoices to paid`,
              dialog: false,
              type: "warning",
            });
          } finally {
            setActionsLoading(false);
          }
        },
      });
    }
    if (invoiceActionType === "generatePDF") {
      setActionsLoading(true);
      const invoiceIDs: string[] = [];
      const pendingInvoiceIDs: string[] = [];
      for (const row of selectedRows) {
        if (typeof row.id === "string") {
          invoiceIDs.push(row.id);
        }
        if (row.status === StiltInvoiceStatus.PENDING) {
          pendingInvoiceIDs.push(row.id);
        }
      }
      if (pendingInvoiceIDs.length > 0) {
        setConfirmationDialogProps({
          invoiceActionType: "generatePDF",
          body: `Of the ${selectedRows.length} invoices selected, ${pendingInvoiceIDs.length} are still in Pending status. Would you like to change these invoices to Submitted?`,
          title: "Generate PDF",
          isSubmitting: false,
          isOpen: true,
          pdfBatchActionButtons: true,
          pendingInvoiceIDsLength: pendingInvoiceIDs.length,
          onClose: () =>
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            }),
          handleConfirmAction: async (
            status: StiltInvoiceStatus | null,
            includeJobPhotos: boolean,
            displayPaymentLink: boolean,
          ) => {
            setActionsLoading(true);
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            });
            if (status === StiltInvoiceStatus.SUBMITTED) {
              try {
                await handleChangeInvoiceStatusBatch(
                  pendingInvoiceIDs,
                  StiltInvoiceStatus.SUBMITTED,
                );
                addToastMessage({
                  id: createToastMessageID(),
                  message: `Invoices set to submitted`,
                  dialog: false,
                  type: "success",
                });
              } catch (e) {
                addToastMessage({
                  id: createToastMessageID(),
                  message: `Error converting invoices to submitted`,
                  dialog: false,
                  type: "warning",
                });
              } finally {
                setActionsLoading(false);
                await handleGetPDFBatch(
                  invoiceIDs,
                  includeJobPhotos,
                  displayPaymentLink,
                );
              }
            } else {
              setActionsLoading(false);
              await handleGetPDFBatch(
                invoiceIDs,
                includeJobPhotos,
                displayPaymentLink,
              );
            }
          },
        });
      } else {
        setActionsLoading(true);
        setConfirmationDialogProps({
          invoiceActionType: "generatePDF",
          body: ``,
          title: "Generate PDF",
          isSubmitting: false,
          isOpen: true,
          pdfBatchActionButtons: true,
          pendingInvoiceIDsLength: null,
          onClose: () =>
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            }),
          handleConfirmAction: async (
            status: StiltInvoiceStatus | null,
            includeJobPhotos: boolean,
            displayPaymentLink: boolean,
          ) => {
            setActionsLoading(true);
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            });
            if (status === StiltInvoiceStatus.SUBMITTED) {
              try {
                await handleChangeInvoiceStatusBatch(
                  pendingInvoiceIDs,
                  StiltInvoiceStatus.SUBMITTED,
                );
                addToastMessage({
                  id: createToastMessageID(),
                  message: `Invoices set to submitted`,
                  dialog: false,
                  type: "success",
                });
              } catch (e) {
                addToastMessage({
                  id: createToastMessageID(),
                  message: `Error converting invoices to submitted`,
                  dialog: false,
                  type: "warning",
                });
              } finally {
                await handleGetPDFBatch(
                  invoiceIDs,
                  includeJobPhotos,
                  displayPaymentLink,
                );
                setActionsLoading(false);
              }
            } else {
              await handleGetPDFBatch(
                invoiceIDs,
                includeJobPhotos,
                displayPaymentLink,
              );
              setActionsLoading(false);
            }
          },
        });
      }

      setActionsLoading(false);
    }
    if (invoiceActionType === "sendEmailInvoice") {
      const invoicesToEmail: any[] = [];
      for (const row of selectedRows) {
        // Only send if there's an email
        if (typeof row.email === "string") {
          invoicesToEmail.push(row);
        }
      }
      setConfirmationDialogProps({
        invoiceActionType: "sendEmailInvoice",
        body: `Of the ${selectedRows.length} invoices selected, ${invoicesToEmail.length} have email addresses.\n\nConfirm sending ${invoicesToEmail.length} invoices via email?\n\nThis will also update Draft and Pending invoices to Submitted status`,
        title: "Send Invoices Via Email?",
        isSubmitting: false,
        isOpen: true,
        pdfBatchActionButtons: true,
        pendingInvoiceIDsLength: null,
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          }),
        handleConfirmAction: async () => {
          setActionsLoading(true);
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          });
          const invoicesWithError = [];
          for (const invoice of invoicesToEmail) {
            const paymentLink = await generatePaymentUniqueLink(
              props.siteKey,
              invoice.id,
              "email",
            );
            if (!paymentLink) {
              invoicesWithError.push(invoice);
            } else {
              try {
                // await new Promise((r) => setTimeout(r, 500));
                await DbWrite.invoices.sendViaEmail({
                  siteKey: props.siteKey,
                  invoiceURL: paymentLink,
                  customerEmailList: [invoice.email],
                  includeJobPhotos: true, //TODO: CHANGE ME
                });
              } catch (_e) {
                invoicesWithError.push(invoice);
              }
            }
          }
          setActionsLoading(false);
          if (invoicesWithError.length === 0) {
            addToastMessage({
              id: createToastMessageID(),
              message: `Sent invoices to ${invoicesToEmail.length} customers`,
              dialog: false,
              type: "success",
            });
          } else {
            addToastMessage({
              id: createToastMessageID(),
              message: `Error sending ${invoicesWithError.length} invoices`,
              dialog: false,
              type: "warning",
            });
          }
        },
      });
    }
    if (invoiceActionType === "applyManualPayment") {
      if (!siteKeyDoc) return;
      const invoicesToApplyPaymentTo: ExistingStiltInvoice[] = [];
      for (const row of selectedRows) {
        // exclude draft, canceled, and paid invoices
        if (
          row.status !== "paid" &&
          row.status !== "draft" &&
          row.status !== "canceled"
        ) {
          invoicesToApplyPaymentTo.push(row);
        }
      }

      let totalAmount = 0;
      for (const inv of invoicesToApplyPaymentTo) {
        if (inv.amountDue > 0) {
          totalAmount += inv.amountDue;
        } else {
          // remove inv from list
          invoicesToApplyPaymentTo.splice(
            invoicesToApplyPaymentTo.indexOf(inv),
            1,
          );
        }
      }
      totalAmount = getRoundedCurrency(totalAmount);

      if (invoicesToApplyPaymentTo.length === 0) {
        addToastMessage({
          id: createToastMessageID(),
          message:
            "No valid invoices to apply payment to. Only invoices that are in Pending, Submitted, or Partially Paid status can be paid with a multi-invoice payment.",
          dialog: false,
          type: "warning",
        });
        return;
      }

      const siteKeyCurrency =
        typeof siteKeyDoc.customizations.accounting.currency === "string"
          ? siteKeyDoc.customizations.accounting.currency
          : "USD";

      const invoiceMessage = `You are about to apply a payment of ${currencyFormatter(totalAmount, siteKeyCurrency)} to the preceding invoices. Please note that receipts must be sent manually after payment is applied.`;

      setBulkManualPaymentDialogProps({
        isDialogOpen: true,
        closeDialog: () => setBulkManualPaymentDialogProps(null),
        siteKeyDoc: siteKeyDoc,
        amountDue: totalAmount,
        onRecordManualPayment: async (formValues: NewManualPayment) => {
          await applyBulkManualPayment(invoicesToApplyPaymentTo, formValues);
        },
        children: {
          datePicker: (
            <DatePicker
              selected={selectedDateTime.toJSDate()}
              onChange={(date: Date) => {
                const luxonDate = DateTime.fromJSDate(date);
                setSelectedDateTime(luxonDate);
              }}
              // showTimeSelect
              customInput={<SchedulingButton />}
            />
          ),
          multiInvoiceList: (
            <ul>
              {invoicesToApplyPaymentTo.map((inv) => (
                <li key={inv.id}>
                  Invoice Number: {inv.invoiceNumber ?? "Unknown"} -{" "}
                  {currencyFormatter(
                    inv.amountDue,
                    siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
                  )}
                </li>
              ))}
            </ul>
          ),
          multiInvoiceMessage: <p>{invoiceMessage}</p>,
        },
      });
    }
    if (invoiceActionType === "payViaCard") {
      if (!siteKeyDoc) return;
      const invoicesToApplyPaymentTo: ExistingStiltInvoice[] = [];
      for (const row of selectedRows) {
        // exclude draft, canceled, and paid invoices
        if (
          row.status !== "paid" &&
          row.status !== "draft" &&
          row.status !== "canceled"
        ) {
          invoicesToApplyPaymentTo.push(row);
        }
      }

      let totalAmount = 0;
      for (const inv of invoicesToApplyPaymentTo) {
        if (inv.amountDue > 0) {
          totalAmount += inv.amountDue;
        } else {
          // remove inv from list
          invoicesToApplyPaymentTo.splice(
            invoicesToApplyPaymentTo.indexOf(inv),
            1,
          );
        }
      }
      totalAmount = getRoundedCurrency(totalAmount);

      if (invoicesToApplyPaymentTo.length === 0) {
        addToastMessage({
          id: createToastMessageID(),
          message:
            "No valid invoices to apply payment to. Only invoices that are in Pending, Submitted, or Partially Paid status can be paid with a multi-invoice payment.",
          dialog: true,
          type: "warning",
        });
        return;
      }

      const siteKeyCurrency =
        typeof siteKeyDoc.customizations.accounting.currency === "string"
          ? siteKeyDoc.customizations.accounting.currency
          : "USD";

      const invoiceMessage = `You are about to open a payment link to pay ${currencyFormatter(totalAmount, siteKeyCurrency)} via credit card for the preceding invoices. Please note that receipts must be sent manually after payment is applied.`;

      setMultiPayCreditCardDialogProps({
        isDialogOpen: true,
        closeDialog: () => setMultiPayCreditCardDialogProps(null),
        amountDue: totalAmount,
        onSubmitPayment: async () => {
          await handleGetMultiPaymentCreditCardLink(
            invoicesToApplyPaymentTo,
            multiPayCreditCardDialogProps?.amountDue ?? 0,
          );
        },
        children: {
          multiInvoiceList: (
            <ul>
              {invoicesToApplyPaymentTo.map((inv) => (
                <li key={inv.id}>
                  Invoice Number: {inv.invoiceNumber ?? "Unknown"} -{" "}
                  {currencyFormatter(
                    inv.amountDue,
                    siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
                  )}
                </li>
              ))}
            </ul>
          ),
          multiInvoiceMessage: <p>{invoiceMessage}</p>,
        },
      });
    }
  }

  async function payWithCardOnFile(args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }): Promise<void> {
    if (!siteKeyDoc) throw Error("payWithCardOnFile missing siteKeyDoc");

    const processor = siteKeyDoc.customizations.accounting?.ziftAccountID
      ? "zift"
      : "paya";
    const data: APIPaymentSavedCard = {
      siteKeyID: siteKeyDoc.id,
      invoiceID: args.invoiceID,
      amount: args.amount,
      cardLastFour: args.lastFour,
      cardExpiry: args.expiry,
    };
    const valid = StiltPaymentManager.parseCreateWithSavedCard(data);
    await DbWrite.payments.createWithSavedCard(valid, processor);
  }

  // #endregion Functions

  // #region SECTION: Components
  // #region Work Record related
  const customerMembershipsPills =
    customerDoc && customerLocationMemberships.length !== 0 ? (
      <div className="mb-2 flex flex-wrap items-center gap-2">
        {customerLocationMemberships.map((membership) => {
          const template = membershipTemplateList.find(
            (template) => template.id === membership.membershipTemplateID,
          );
          return (
            template && (
              <MembershipPill
                key={membership.id}
                membership={membership}
                title={template.title}
              />
            )
          );
        })}
      </div>
    ) : null;

  const customerInfo = customerDoc && currentLocation && (
    <button
      className="my-4 flex w-full rounded-md border p-4 hover:bg-gray-50 "
      onClick={() => handleGoToCustomerPage(customerDoc.id)}
    >
      <ul className="w-full space-y-2.5 text-left">
        <li className="flex flex-col">
          <span className="text-sm font-medium text-gray-400">
            {strings.CUSTOMER_NAME}
          </span>
          <div className="self-start font-bold capitalize">
            {customerDoc.name}
          </div>
        </li>
        {(customerDoc.notes || customerDoc.notes !== "") && (
          <li className="flex flex-col">
            <span className="text-sm font-medium text-gray-400">
              {strings.CUSTOMER_NOTES}
            </span>
            <p>{customerDoc.notes}</p>
          </li>
        )}
        {customerDoc.email && (
          <li className="flex flex-col">
            <span className="text-sm font-medium text-gray-400">
              {strings.EMAIL}
            </span>
            {customerDoc.email.join(", ")}
          </li>
        )}
        {customerDoc.phone && (
          <li className="flex flex-col">
            <span className="text-sm font-medium text-gray-400">
              {strings.PHONE}
            </span>
            {customerDoc.phone && customerDoc.phone.length > 0
              ? phoneUtils.display(customerDoc.phone[0])
              : null}
          </li>
        )}
        {fullAddress && (
          <li className="flex flex-col">
            <span className="text-sm font-medium text-gray-400">
              {strings.FULL_ADDRESS}
            </span>
            {fullAddress}
          </li>
        )}
        {(currentLocation.notes || currentLocation.notes !== "") && (
          <li className="flex flex-col">
            <span className="text-sm font-medium text-gray-400">
              {strings.LOCATION_NOTES}
            </span>
            <p>{currentLocation.notes}</p>
          </li>
        )}
        {customerMembershipsPills}
      </ul>
    </button>
  );

  const customerTagsPills = customerDoc && (
    <div className="mb-2 flex flex-wrap items-center">
      {typeof customerDoc.balance === "number" && customerDoc.balance !== 0 && (
        <div
          className={`m-1 rounded-full ${getCustomerBalanceTagColor(customerDoc)} px-2.5 py-1 text-xs font-medium ${getCustomerBalanceTextColor(customerDoc)}`}
        >
          {getCustomerBalanceString(customerDoc, siteKeyDoc)}
        </div>
      )}
      {customerDoc.tags.map((tag, index) => {
        return <ChipTag key={index} tag={tag} />;
      })}
    </div>
  );

  const workRecActionButtons = (
    <div className="flex flex-wrap items-center gap-6 xs:justify-between">
      {/* EDIT */}
      <BaseButtonTertiary
        type="button"
        onClick={() => setOpenEditWorkRecord(true)}
        className="w-fit uppercase tracking-wider"
      >
        <PencilIcon
          aria-label="edit"
          className="mr-2 h-5 w-5 text-gray-600"
          aria-hidden="true"
        />
        <span className="-mb-0.5 text-primary">{strings.buttons.EDIT}</span>
      </BaseButtonTertiary>

      {/* ATTACHMENTS */}
      <BaseButtonTertiary
        type="button"
        onClick={() => setOpenEditWorkRecord(true)}
        className="w-fit uppercase tracking-wider"
      >
        <PaperClipIcon
          aria-label="attachments"
          className="mr-2 h-5 w-5 text-gray-600"
          aria-hidden="true"
        />
        <span className="-mb-0.5 text-primary">
          {attachments.length} attachment{attachments.length === 1 ? "" : "s"}
        </span>
      </BaseButtonTertiary>

      {/* VIEW PREVIOUS JOBS */}
      <BaseButtonTertiary
        type="button"
        onClick={() => setOpenViewPreviousJobs(true)}
        className="w-fit uppercase tracking-wider"
      >
        <AccountTreeIcon
          aria-label="view previous jobs"
          className="mr-2 h-5 w-5 text-gray-600"
          aria-hidden="true"
        />
        <span className="-mb-0.5 text-primary">
          {strings.buttons.VIEW_PREVIOUS_JOBS}
        </span>
      </BaseButtonTertiary>

      {/* VIEW MAP */}
      <BaseButtonTertiary
        type="button"
        onClick={() => setShowMap(!showMap)}
        className="w-fit uppercase tracking-wider"
      >
        <MapPinIcon
          aria-label="location"
          className="mr-2 h-5 w-5 text-gray-600"
          aria-hidden="true"
        />
        <span className="-mb-0.5 text-primary">
          {showMap ? `${strings.VIEW_PHOTOS}` : `${strings.VIEW_MAP}`}
        </span>
      </BaseButtonTertiary>
    </div>
  );

  const assetDialog = selectedAsset && (
    <AddAssetDialog
      isDialogOpen={isAssetDialogOpen}
      closeDialog={() => {
        setIsAssetDialogOpen(false);
        setSelectedAsset(null);
      }}
      locationID={workRecord?.locationID ?? null}
      siteKey={props.siteKey}
      asset={selectedAsset}
      customer={customerDoc}
      customerLocation={selectedCustomerLocations[0]}
      customerLocationOptions={selectedCustomerLocations}
      isSubmitting={false}
    />
  );

  const editWorkRecordDialog = workRecord && (
    <EditWorkRecordDialog
      // DATA
      isOpen={openEditWorkRecord}
      workRecord={workRecord}
      siteKeyLocations={skLocationIdAndTitleListRef.current}
      attachments={attachments}
      siteKeyCustomFields={skWorkTypeCustomFields}
      userDisplayNamesMap={userDisplayNamesMap}
      userList={userList.current}
      // FUNCTIONS
      onClose={() => setOpenEditWorkRecord(false)}
      handleUpdateWorkRecord={handleUpdateWorkRecord}
      handleUploadAttachment={handleUploadAttachment}
      handleDeleteAttachment={handleDeleteAttachment}
    />
  );

  const handleSendReceiptDialog = selectedInvoice && (
    <HandleSendEmailDialog
      defaultIncludeJobPhotos={
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false
      }
      isDialogOpen={handleSendEmailDialogOpen}
      closeDialog={() => setHandleSendEmailDialogOpen(false)}
      sendEmailReceipt={handleEmailReceipt}
      title={strings.SEND_RECEIPT_TO_CUSTOMER}
      merchantName={siteKeyDoc?.name ?? ""}
      timestampSentToCustomer={selectedInvoice.timestampSentToCustomer}
      customerEmailList={emailList}
    />
  );

  const viewPreviousJobsDialog = workRecord && (
    <ViewPreviousJobsDialog
      isOpen={openViewPreviousJobs}
      onClose={() => setOpenViewPreviousJobs(false)}
      workRecordList={workRecordList}
      taskList={allTasksForCustomer}
      userDisplayNamesMap={userDisplayNamesMap}
      goToWorkRecordAndTasksPage={handleGoToWorkRecordAndTasksPage}
    />
  );

  function renderEstimateIconCell(params: ICellRendererParams) {
    const currentInvoice = workRecordInvoiceList.find(
      (invoice) => invoice.estimateID === params.data.id,
    );
    let iconToDisplay: JSX.Element;
    if (currentInvoice && currentInvoice.status !== "paid") {
      iconToDisplay = (
        <StyledTooltip title="Handle Payment">
          <PaymentIconWithSpinner
            onCreate={async () => {
              setIsEstimateTableHandlePaymentDialogOpen(true);
              setSelectedInvoice(currentInvoice);
            }}
          />
        </StyledTooltip>
      );
    } else if (currentInvoice && currentInvoice.status === "paid") {
      iconToDisplay = (
        <StyledTooltip title="Send Receipt">
          <EmailButtonWithSpinner
            onClick={async () => {
              setSelectedInvoice(currentInvoice);
              setHandleSendEmailDialogOpen(true);
            }}
          />
        </StyledTooltip>
      );
    } else {
      iconToDisplay = (
        <StyledTooltip title="Create Invoice">
          <InvoiceIconWithSpinner
            onCreate={async () => {
              await handleCreateInvoice(params.data);
            }}
          />
        </StyledTooltip>
      );
    }

    return (
      <div className="flex items-center gap-2">
        {iconToDisplay}
        <StyledTooltip title="Download PDF">
          <PDFIconWithSpinner
            onCreate={async () => getEstimatePDF(params.data.id)}
          />
        </StyledTooltip>
      </div>
    );
  }

  const dueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedDueDate ? `Due Date` : "Due Date Not Selected"}
      <div className="mt-2 flex items-center justify-between gap-4">
        <DatePicker
          selected={
            selectedDueDate
              ? selectedDueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedDueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const issueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedIssueDate ? `Issue Date` : "Issue Date Not Selected"}
      <div className="mt-2 flex items-center justify-between gap-4">
        <DatePicker
          selected={
            selectedIssueDate
              ? selectedIssueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedIssueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const paymentTermsSelector = (
    <BaseInputSelect
      inputName="paymentTerms"
      text="Payment Terms"
      admin={true}
      required={true}
      className="capitalize"
      value={paymentTerms === null ? "" : paymentTerms}
      onChange={handlePaymentTermsChange}
    >
      <option value="">None</option>
      {Object.entries(templatesPaymentTerms).map(([key, paymentTerm]) => {
        return (
          <option key={key} value={key}>
            {paymentTerm.title}
          </option>
        );
      })}
    </BaseInputSelect>
  );

  const editInvoiceDialog = selectedInvoice && (
    <EditInvoiceDialog
      isDialogOpen={editInvoiceDialogOpen}
      closeDialog={() => setEditInvoiceDialogOpen(false)}
      invoiceDoc={selectedInvoice}
      handleSave={handleSaveUpdatedInvoice}
      dueDate={dueDatePicker}
      issueDate={issueDatePicker}
      paymentTerms={paymentTermsSelector}
    />
  );
  const datePicker = selectedInvoice && (
    <DatePicker
      selected={selectedDateTime.toJSDate()}
      onChange={(date: Date) => {
        const luxonDate = DateTime.fromJSDate(date);
        setSelectedDateTime(luxonDate);
      }}
      showTimeSelect
      customInput={<SchedulingButton />}
    />
  );

  const handlePaymentDialog = isHandlePaymentDialogOpen &&
    siteKeyDoc &&
    selectedInvoice &&
    selectedCustomer &&
    userPermissions && (
      <HandlePaymentDialog
        isDialogOpen={isHandlePaymentDialogOpen}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        closeDialog={() => setIsHandlePaymentDialogOpen(false)}
        goToPaymentPage={handleGoToPaymentPage}
        invoiceID={selectedInvoice.id}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceStatus={selectedInvoice.status}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        customer={selectedCustomer}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, selectedCustomer)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={handleRecordManualPayment}
      >
        {{
          DatePicker: datePicker,
        }}
      </HandlePaymentDialog>
    );

  const estimateTableHandlePaymentDialog =
    isEstimateTableHandlePaymentDialogOpen &&
      siteKeyDoc &&
      selectedInvoice &&
      selectedCustomer &&
      userPermissions && (
        <HandlePaymentDialog
          isDialogOpen={isEstimateTableHandlePaymentDialogOpen}
          userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
          closeDialog={() => {
            setIsEstimateTableHandlePaymentDialogOpen(false);
            setInvoicePaymentLink(null);
          }}
          goToPaymentPage={handleGoToPaymentPage}
          invoiceID={selectedInvoice.id}
          invoiceAmount={selectedInvoice.amountDue}
          invoiceStatus={selectedInvoice.status}
          invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
          customer={selectedCustomer}
          payWithCardOnFile={payWithCardOnFile}
          defaultIncludePhotos={
            siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
          }
          emailList={getEmailList(selectedInvoice, selectedCustomer)}
          sendEmail={sendEmailFromHandlePaymentDialog}
          paymentMethods={
            siteKeyDoc.customizations.manualPaymentMethods ?? [
              ...paymentMethods,
            ]
          }
          applyManualPayment={handleRecordManualPayment}
        >
          {{
            DatePicker: datePicker,
          }}
        </HandlePaymentDialog>
      );

  const invoiceTableHandlePaymentDialog =
    isInvoiceTableHandlePaymentDialogOpen &&
      siteKeyDoc &&
      selectedInvoice &&
      selectedCustomer &&
      userPermissions && (
        <HandlePaymentDialog
          isDialogOpen={isInvoiceTableHandlePaymentDialogOpen}
          userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
          closeDialog={() => setIsInvoiceTableHandlePaymentDialogOpen(false)}
          goToPaymentPage={handleGoToPaymentPage}
          invoiceID={selectedInvoice.id}
          invoiceAmount={selectedInvoice.amountDue}
          invoiceStatus={selectedInvoice.status}
          invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
          customer={selectedCustomer}
          payWithCardOnFile={payWithCardOnFile}
          defaultIncludePhotos={
            siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
          }
          emailList={getEmailList(selectedInvoice, selectedCustomer)}
          sendEmail={sendEmailFromHandlePaymentDialog}
          paymentMethods={
            siteKeyDoc.customizations.manualPaymentMethods ?? [
              ...paymentMethods,
            ]
          }
          applyManualPayment={handleRecordManualPayment}
        >
          {{
            DatePicker: datePicker,
          }}
        </HandlePaymentDialog>
      );

  const viewInvoiceDialog = isViewInvoiceDialogOpen &&
    viewInvoiceDialogProps &&
    userPermissions &&
    !userDisplayNamesMapIsLoading && (
      <ViewInvoiceDialog
        isOpen={isViewInvoiceDialogOpen}
        onClose={closeViewInvoiceDialog}
        invoiceID={viewInvoiceDialogProps["invoiceID"]}
        siteKeyID={viewInvoiceDialogProps["siteKeyID"]}
        merchantLogoURL={viewInvoiceDialogProps["merchantLogoURL"]}
        merchantName={viewInvoiceDialogProps["merchantName"]}
        handleRefund={handleRefund}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        editInvoice={handleEditInvoiceDialogOpen}
        sendEmail={emailInvoice}
        handlePayment={onClickHandlePaymentButton}
        deleteInvoice={handleDeleteInvoice}
        userDisplayNamesMap={userDisplayNamesMap}
        handleDeletePayment={handleDeletePayment}
      >
        {{
          EditInvoiceDialog: editInvoiceDialog,
          HandlePaymentDialog: handlePaymentDialog,
        }}
      </ViewInvoiceDialog>
    );

  const invoiceListForWRDialog = workRecordInvoiceList && (
    <InvoiceListForTaskDialog
      isOpen={invoiceListDialogOpen}
      onClose={() => setInvoiceListDialogOpen(false)}
      currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
      handlePayment={onClickHandlePaymentTableRowInvoice}
      invoiceList={filteredListOfInvoices}
      emailReceipt={(invoiceID) => {
        const invoiceDoc = workRecordInvoiceList.find(
          (invoice) => invoice.id === invoiceID,
        );
        setSelectedInvoice(invoiceDoc ?? null);
        setHandleSendEmailDialogOpen(true);
      }}
      handleGetPDF={handleGetPDF}
      openInvoiceDialog={openViewInvoiceDialog}
      actionsLoading={actionsLoading}
      invoiceStatus={invoiceStatus}
      setStiltInvoiceStatusSelection={setStiltInvoiceStatusSelection}
      handleInvoiceActionSelected={handleInvoiceActionSelected}
      getLocationTitle={getLocationTitle}
      siteKeyLocationList={siteKeyLocationList}
    >
      {{
        HandlePaymentDialog: invoiceTableHandlePaymentDialog,
        ViewInvoiceDialog: viewInvoiceDialog,
      }}
    </InvoiceListForTaskDialog>
  );

  const viewEstimateDialog = selectedEstimate &&
    customerDoc &&
    selectedEstimateLocation && (
      <ViewEstimateDialog
        isOpen={viewEstimateDialogOpen}
        onClose={() => {
          resetSelectedEstimate();
          setViewEstimateDialogOpen(false);
        }}
        estimate={selectedEstimate}
      >
        {{
          ViewEstimatePage: (
            <ViewEstimateContainer
              renderedInDialog={true}
              siteKey={props.siteKey}
              onEstimateDeleted={() => {
                setViewEstimateDialogOpen(false);
                setEstimateListDialogOpen(false);
              }}
              optionalDocs={{
                estimateID: selectedEstimate.id,
                customerDoc: customerDoc,
                customerLocationDoc: selectedEstimateLocation,
                invoiceDoc: selectedInvoice,
              }}
            />
          ),
        }}
      </ViewEstimateDialog>
    );

  const apiKey = import.meta.env.VITE_APP_GOOGLE_MAP_KEY;
  const siteMapOrPhotoComponent =
    !isLoadingParentRecordPhotos && !showMap ? (
      <PhotoViewer
        photos={validPhotosRef.current}
        handleDeletePhoto={handleDeleteWorkRecordPhoto}
        handleUploadPhoto={handleUploadWorkRecordPhoto}
        handleDownloadPhotos={handleDownloadWorkRecordPhotos}
      />
    ) : (
      <div className="relative h-96 overflow-hidden rounded-t-md">
        <Wrapper apiKey={apiKey ?? ""} libraries={["places"]}>
          <SiteMap center={position} zoom={zoom}>
            <Marker
              position={position}
              getNewPosition={(currentPosition: google.maps.LatLngLiteral) =>
                setPosition(currentPosition)
              }
              getNewZoom={(currentZoom: number) => setZoom(currentZoom)}
            />
          </SiteMap>
        </Wrapper>
      </div>
    );

  const workRecDetailsAndEvents = workRecord && (
    <DetailsAndEventsPanels
      details={workRecord.craftDetails}
      siteKeyCustomFields={skWorkTypeCustomFields}
      userDisplayNamesMap={userDisplayNamesMap}
      events={events}
      vehicleList={[]} // don't need to pass the vehicleList to the Work Record panel, it won't be used
    />
  );

  // If there are multiple siteKeyLocations, show which siteKeyLocation belogs to this workRecord
  const siteKeyLocationSection = siteKeyLocationList.length > 1 &&
    workRecord && (
      <div className="flex w-full items-center justify-end gap-1 px-1 md:justify-start">
        <BuildingOfficeIcon className="h-6 w-6 text-black" />
        <span className="text-lg font-bold capitalize">
          {getLocationTitle(workRecord.locationID)}
        </span>
      </div>
    );

  const workRecordPanel = workRecord && (
    <WorkRecordPanel workRecord={workRecord} customerFullAddress={fullAddress}>
      {{
        customerInfo,
        customerTagsPills: customerTagsPills,
        actionButtonRow: workRecActionButtons,
        siteMapOrPhoto: siteMapOrPhotoComponent,
        detailsAndEventsPanels: workRecDetailsAndEvents,
      }}
    </WorkRecordPanel>
  );

  const confirmCloseWRDialog = openTasks && (
    <ConfirmCloseWRDialog
      isOpen={openConfirmCloseWRDialog}
      onClose={() => setOpenConfirmCloseWRDialog(false)}
      openTasks={openTasks.length}
      handleConfirmCloseWR={handleConfirmCloseWR}
      isSubmitting={isDeletingTasks}
    />
  );

  const confirmDeleteWRDialog = openTasks && (
    <ConfirmDeleteWRDialog
      isOpen={confirmDeleteWRDialogOpen}
      onClose={() => setConfirmDeleteWRDialogOpen(false)}
      handleConfirmDeleteWR={handleConfirmDeleteWR}
      isSubmitting={isDeletingWorkRecord}
    />
  );

  const selectCustomerDialog = (
    <CustomerTableDialog
      isOpen={selectCustomerDialogOpen}
      onClose={() => setSelectCustomerDialogOpen(false)}
      siteKey={props.siteKey}
      onSelectCustomer={(c) => {
        setSelectedCustomer(c);
        setSelectCustomerDialogOpen(false);
      }}
      addCustomerButton={null}
    />
  );

  const selectCustomerButton = (
    <BaseButtonSecondary
      type="button"
      onClick={() => setSelectCustomerDialogOpen(true)}
      className="w-full text-primary xs:w-auto"
    >
      <CheckCircle fontSize="small" className="mr-2" />
      {strings.SELECT_CUSTOMER}
    </BaseButtonSecondary>
  );

  const displayCustomerData = selectedCustomer && (
    // <CustomerDetails customer={selectedCustomer} />
    <div className="pt-4 font-bold text-gray-800 md:pt-1">
      <div className="pb-4 text-xl font-bold text-primary">
        {strings.CUSTOMER_DETAILS}
      </div>
      <div className="flex flex-col space-y-2.5 capitalize">
        <span className="text-sm font-medium text-gray-400">
          {strings.CUSTOMER_NAME}
        </span>
        {selectedCustomer.name}
      </div>
    </div>
  );

  const selectCustomerLocationGroup = selectedCustomerLocations && (
    <div>
      <div className="flex w-full flex-col xs:col-span-2 xs:mb-6 xs:flex-row">
        <BaseButtonSecondary
          type="button"
          className="xs:ml-2 xs:mr-6 xs:w-36 md:ml-4"
          onClick={() => {
            const allSelected = selectedCustomerLocations.map(
              (location) => location.id,
            );
            setSelectedLocationIds(allSelected);
          }}
        >
          {strings.buttons.SELECT_ALL}
        </BaseButtonSecondary>
        <BaseButtonSecondary
          type="button"
          className="my-3 xs:my-0 xs:w-36"
          onClick={() => setSelectedLocationIds([])}
        >
          {strings.buttons.UNSELECT_ALL}
        </BaseButtonSecondary>
      </div>
      <div className="max-h-[84px] overflow-y-auto px-2">
        {selectedCustomerLocations.map((location) => {
          return (
            <BaseInputCheckbox
              key={location.id}
              label={`${location.type} - ${location.fullAddress}`}
              value={location.id}
              checked={selectedLocationIds.includes(location.id)}
              onChange={(event) =>
                event.target.checked
                  ? setSelectedLocationIds((currentSelection) => [
                      ...currentSelection,
                      location.id,
                    ])
                  : handleRemoveSelectedLocation(location.id)
              }
            />
          );
        })}
      </div>
    </div>
  );

  const tasksToDuplicateSection = (
    <Fragment>
      <div className="flex w-full flex-col xs:col-span-2 xs:mb-6 xs:flex-row">
        <BaseButtonSecondary
          type="button"
          className="xs:ml-2 xs:mr-6 xs:w-36 md:ml-4"
          onClick={() => {
            allTasksForParentRecord.forEach((task) => {
              setTaskIDsToDuplicate((currentTasks) => [
                ...currentTasks,
                task.id,
              ]);
            });
          }}
        >
          {strings.buttons.SELECT_ALL}
        </BaseButtonSecondary>
        <BaseButtonSecondary
          type="button"
          className="my-3 xs:my-0 xs:w-36"
          onClick={() => setTaskIDsToDuplicate([])}
        >
          {strings.buttons.UNSELECT_ALL}
        </BaseButtonSecondary>
      </div>
      <div>
        {allTasksForParentRecord.map((task) => (
          <BaseInputCheckbox
            key={task.id}
            label={task.title}
            value={task.id}
            checked={taskIDsToDuplicate.includes(task.id)}
            onChange={(event) =>
              event.target.checked
                ? setTaskIDsToDuplicate((currentTasks) => [
                    ...currentTasks,
                    task.id,
                  ])
                : handleRemoveSelectedTask(task.id)
            }
          />
        ))}
      </div>
    </Fragment>
  );

  /** to be displayed within the scheduling section */
  const assignedToField =
    taskIDsToDuplicate.length > 0 && assignedTo_TaskCF ? (
      <AssignedToCustomField
        userList={getSelectedUsers(
          // fresh userList for any multiple-uid input
          userList.current
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj) => Object.assign({}, obj)),
          assignedToUIDs,
        )}
        customField={assignedTo_TaskCF}
        handleAssignUsers={(uidList: string[]) => setAssignedToUIDs(uidList)}
      />
    ) : null;

  const confirmationReminderToggles = siteKeyDoc?.customizations
    .sendTaskReminderToCustomers &&
    siteKeyDoc.customizations.sendTaskReminderToCustomers && (
      <StyledSwitchGroup
        tooltipText={strings.SEND_24H_REMINDER_EMAIL_HELP_TEXT}
        readableName={strings.SEND_24H_REMINDER_EMAIL}
        onBlur={() => {}}
        onChange={() => setSendJobReminderEmail(!sendJobReminderEmail)}
        checked={sendJobReminderEmail}
        id="sendJobReminderEmail"
        name={"sendJobReminderEmail"}
      />
    );

  const schedulingSection = siteKeyDoc && (
    <SchedulingSection
      dateAndTime={scheduleDateTime}
      setDateAndTime={setScheduleDateTime}
      duration={serviceWindowDuration}
      setDuration={setServiceWindowDuration}
      siteKeyDoc={siteKeyDoc}
      handleClickScheduledByPriority={(val) => setScheduledByPriority(val)}
      optionalText={strings.DUPLICATE_SCHEDULING}
      assignedToField={assignedToField}
      emailReminder={confirmationReminderToggles}
    />
  );

  const additionalItemsSection = (
    <div className="ml-4 flex flex-col justify-between gap-2">
      {/* Duplicate Estimates */}
      <div>
        <BaseInputCheckbox
          label="Duplicate Estimates"
          onChange={() => setDuplicateEstimates(!duplicateEstimates)}
          checked={duplicateEstimates}
        />
      </div>
      {/* Field: Photos */}
      <div>
        <BaseInputCheckbox
          label="Duplicate Photos"
          onChange={() => setDuplicatePhotos(!duplicatePhotos)}
          checked={duplicatePhotos}
        />
      </div>
      {/* Field: Attachments */}
      <div>
        <BaseInputCheckbox
          label="Duplicate Attachments"
          onChange={() => setDuplicateAttachments(!duplicateAttachments)}
          checked={duplicateAttachments}
        />
      </div>
    </div>
  );

  const duplicateJobInfoBox = selectedCustomer && (
    <p className={`inline-block rounded-md bg-blue-50 px-4 py-2 text-blue-700`}>
      <span className="flex items-center gap-4">
        <span className={`flex-shrink-0 text-blue-700`}>
          <InformationCircleIcon
            aria-label="info message"
            className="h-5 w-5"
          />
        </span>
        <span>
          This action will duplicate this work record to customer{" "}
          <span className="capitalize">{selectedCustomer.name}</span> at{" "}
          {selectedLocationIds.length === 1
            ? `${selectedLocationIds.length} location`
            : `${selectedLocationIds.length} locations`}
          . {selectedTasksLength} Tasks will be copied{" "}
          {getScheduleString(scheduleDateTime, scheduledByPriority)}.{" "}
          {getAdditionalItemsString(
            duplicateEstimates,
            duplicatePhotos,
            duplicateAttachments,
          )}
        </span>
      </span>
    </p>
  );

  const duplicateJobActionButtons = (
    <div className="mt-4 flex w-full items-center justify-between gap-4">
      <BaseButtonSecondary
        type="button"
        className="w-full justify-center uppercase"
        onClick={() => {
          setDuplicateJobDialogOpen(false);
          resetDuplicateJobStates();
        }}
      >
        {strings.buttons.CANCEL}
      </BaseButtonSecondary>

      <BaseButtonPrimary
        disabled={isDuplicatingJob}
        isBusy={isDuplicatingJob}
        busyText={strings.buttons.BUSY_SAVING}
        type="submit"
        className="w-full justify-center uppercase"
        formNoValidate
        onClick={() => {
          if (selectedLocationIds.length > 0) {
            handleDuplicateJob();
          } else {
            setDisplayErrorOnDuplicateJob(true);
          }
        }}
      >
        {strings.buttons.SAVE}
      </BaseButtonPrimary>
    </div>
  );

  const siteLocationsDropdown =
    siteKeyLocationList &&
    (siteKeyLocationList.length > 1 ? (
      <div className="relative col-span-2 mb-2">
        {strings.SITE_LOCATIONS}
        <BasicStringDropdown
          optionList={siteKeyLocationList.map(
            (siteLocation) => siteLocation.title,
          )}
          currentSelection={selectedSiteLocation}
          setCurrentSelection={setSelectedSiteLocation}
        />
      </div>
    ) : null);

  const errorMessage = (
    <span className="-my-5 h-10 justify-self-center sm:col-span-2 lg:col-span-3">
      <ErrorMessage
        message="At least one customer location must be selected"
        clearMessage={() => setDisplayErrorOnDuplicateJob(false)}
      />
    </span>
  );

  const feedbackBtn = feedbackDoc && (
    <FeedbackButton
      customerPleased={feedbackDoc.customerPleased}
      onClick={() => setIsFeedbackDialogOpen(true)}
    />
  );

  const feedbackDialog = feedbackDoc && (
    <ViewFeedbackDialog
      isOpen={isFeedbackDialogOpen}
      onClose={() => setIsFeedbackDialogOpen(false)}
      feedback={feedbackDoc}
    />
  );

  const duplicateJobDialog = allTasksForParentRecord && selectedCustomer && (
    <DuplicateJobDialog
      isOpen={duplicateJobDialogOpen}
      onClose={() => {
        setDuplicateJobDialogOpen(false);
        resetDuplicateJobStates();
      }}
      //Components
      selectCustomerButton={selectCustomerButton}
      displayCustomerData={displayCustomerData}
      selectCustomerLocationGroup={selectCustomerLocationGroup}
      selectTasks={tasksToDuplicateSection}
      additionalItemsSection={additionalItemsSection}
      scheduling={schedulingSection}
      actionButtons={duplicateJobActionButtons}
      infoBox={duplicateJobInfoBox}
      siteLocationsDropdown={siteLocationsDropdown}
      errorMessage={displayErrorOnDuplicateJob ? errorMessage : null}
    >
      {{
        SelectCustomerDialog: selectCustomerDialog,
      }}
    </DuplicateJobDialog>
  );

  const confirmationDialog = (
    <ConfirmationDialogForInvoiceActions
      invoiceActionType={confirmationDialogProps.invoiceActionType}
      isOpen={confirmationDialogProps.isOpen}
      onClose={confirmationDialogProps.onClose}
      handleConfirmAction={confirmationDialogProps.handleConfirmAction}
      isSubmitting={confirmationDialogProps.isSubmitting}
      title={confirmationDialogProps.title}
      body={confirmationDialogProps.body}
      pdfBatchActionButtons={confirmationDialogProps.pdfBatchActionButtons}
      pendingInvoiceIDsLength={confirmationDialogProps.pendingInvoiceIDsLength}
    />
  );

  const confirmDeleteTaskDialog = (
    <ConfirmDeleteTaskDialog
      isOpen={confirmDeleteTaskDialogOpen}
      onClose={() => {
        setConfirmDeleteTaskDialogOpen(false);
        setTaskToDelete(null);
      }}
      handleConfirmDeleteTask={() => {
        if (taskToDelete) {
          executeDeleteTask(taskToDelete);
        }
      }}
      isSubmitting={isDeletingTask}
    />
  );
  // #endregion Work Record related

  // #region Tasks related
  const closeRecordBtn = workRecord?.open ? (
    <BaseButtonSecondary
      type="button"
      onClick={() => {
        if (openTasks.length > 0) {
          setOpenConfirmCloseWRDialog(true);
        } else {
          handleCloseWorkRecord();
        }
      }}
      className="w-fit uppercase tracking-wider text-primary"
    >
      <CloseFullscreen fontSize="small" className="mr-2" />
      {strings.CLOSE_RECORD}
    </BaseButtonSecondary>
  ) : null;

  const createTaskBtn = workRecord?.open ? (
    <BaseButtonPrimary
      type="button"
      onClick={() => navToCreateTask(workRecordID, customerDoc?.id)}
      className="col-span-2 w-fit justify-self-end uppercase tracking-wider text-primary"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.CREATE_TASK_FOR_JOB}
    </BaseButtonPrimary>
  ) : null;

  const rescheduleTaskDialog = siteKeyDoc &&
    userPermissions &&
    selectedTask && (
      <RescheduleTaskDialog
        isOpen={openRescheduleTaskDialog}
        onClose={() => {
          setOpenRescheduleTaskDialog(false);
          setSelectedTask(null);
        }}
        siteKey={siteKeyDoc}
        siteKeyUserPermissions={userPermissions}
        task={cloneDeep(selectedTask)}
        handleRescheduleTask={handleRescheduleTask}
        handleOpenTaskStatusChangeDialogDueToScheduleChange={
          handleOpenTaskStatusChangeDialogDueToScheduleChange
        }
        vehicleList={vehicleList}
        permissionsMap={permissionsMap}
        siteKeyUsersList={siteKeyUsersList}
        siteLocationList={siteKeyLocationList}
      />
    );

  const taskLocation = selectedCustomerLocations.find(
    (location) => location.id === originalTaskForTaskStatusChangeDialog?.id,
  );

  const invoicesForTask = taskForTaskStatusChangeDialog
    ? workRecordInvoiceList.filter(
        (invoice) =>
          invoice.taskID === taskForTaskStatusChangeDialog?.id &&
          invoice.status !== "canceled",
      )
    : [];

  /**
   * this taskStatus change dialog is part of the reschedule task flow.
   * not intended to be used with the change taskStatus button.
   */
  const taskStatusChangeDialog = workRecord &&
    taskForTaskStatusChangeDialog &&
    originalTaskForTaskStatusChangeDialog && (
      <TaskStatusChangeDialog
        // DIALOG BASICS
        open={
          openTaskStatusChangeDialog ===
          originalTaskForTaskStatusChangeDialog.id
        }
        onClose={() => {
          setOpenTaskStatusChangeDialog(null);
          setTaskForTaskStatusChangeDialog(null);
          setOriginalTaskForTaskStatusChangeDialog(null);
          setCustomFieldsForTaskStatusChangeDialog([]);
        }}
        // DATA
        showCraftPersistence={false}
        workRecordTitle={workRecord.title}
        isReschedulingTask={true}
        task={taskForTaskStatusChangeDialog}
        originalTask={originalTaskForTaskStatusChangeDialog}
        siteKeyCustomFields={customFieldsForTaskStatusChangeDialog}
        nextTaskStatus={taskForTaskStatusChangeDialog.taskStatus}
        userList={userList.current}
        userDisplayNamesMap={userDisplayNamesMap}
        uid={firebaseUser.uid}
        // FUNCTIONS
        handleUpdateTask={handleUpdateTask}
        handleUpdateTSD={handleUpdateTSD}
        createFollowUpTask={handleCreateFollowUpTask}
        customerLocation={taskLocation ?? selectedCustomerLocations[0]}
        emailList={emailList}
        invoices={invoicesForTask}
      />
    );

  const editTaskDialog = selectedTask && siteKeyDoc && (
    <EditTaskDialog
      isOpen={openEditTaskDialog}
      onClose={() => {
        setOpenEditTaskDialog(false);
        setSelectedTask(null);
      }}
      siteKey={siteKeyDoc.id}
      membershipsEnabled={
        siteKeyDoc?.customizations?.membershipsEnabled === true
      }
      assetsEnabled={siteKeyDoc?.customizations?.assetsEnabled === true}
      userList={userList.current}
      userDisplayNamesMap={userDisplayNamesMap}
      siteKeyCustomFields={skTaskCustomFields}
      task={selectedTask}
      handleEditTask={handleEditTask}
      customerContacts={customerContacts}
    />
  );

  const deleteRecordBtn = workRecord && (
    <ButtonColored
      className="uppercase"
      kind="danger"
      onClick={() => setConfirmDeleteWRDialogOpen(true)}
    >
      {strings.DELETE_WORK_RECORD}
    </ButtonColored>
  );

  const jobPDFBtn = workRecord && (
    <StyledTooltip title="Download PDF">
      <PDFIconWithSpinner onCreate={async () => getJobPDF()} />
    </StyledTooltip>
  );

  const dublicateJobBtn = workRecord && (
    <BaseButtonSecondary
      className="uppercase"
      onClick={() => setDuplicateJobDialogOpen(true)}
    >
      <CopyAll fontSize="small" className="mr-2" />
      {strings.DUPLICATE_JOB}
    </BaseButtonSecondary>
  );

  const workStatusPanel = customerDoc && <WorkStatusPanel jobList={jobList} />;

  const tasksPanel = siteKeyDoc && userPermissions && workRecord && (
    <TasksPanel
      tasks={allTasksForParentRecord}
      siteKey={siteKeyDoc}
      userDisplayNamesMap={userDisplayNamesMap}
      taskAssets={taskAssets}
      handleAssetClick={handleAssetClick}
      estimates={estimateList}
      companies={companyList}
      events={events}
      userPermissions={userPermissions}
      uid={firebaseUser.uid}
      workRecordTitle={workRecord.title}
      handleDeleteTask={handleDeleteTask}
      handleOpenRescheduleTaskDialog={handleOpenRescheduleTaskDialog}
      handleOpenEditTaskDialog={handleOpenEditTaskDialog}
      handleUpdateTask={handleUpdateTask}
      handleUpdateTSD={handleUpdateTSD}
      handleCreateNewEstimate={handleCreateNewEstimate}
      handleOpenEstimateListDialog={handleOpenEstimateListDialog}
      taskLocation={taskLocation ?? selectedCustomerLocations[0]}
      emailList={emailList}
      customerContacts={customerContacts}
      createFollowUpTask={handleCreateFollowUpTask}
      vehicleList={vehicleList}
      invoices={workRecordInvoiceList}
    >
      {{
        createTaskBtn,
        workStatusPanel,
      }}
    </TasksPanel>
  );

  const bulkManualPaymentDialog = bulkManualPaymentDialogProps && (
    <RecordManualPaymentDialog {...bulkManualPaymentDialogProps} />
  );

  const multiPayCreditCardDialog = multiPayCreditCardDialogProps && (
    <MultiPayCreditCardDialog {...multiPayCreditCardDialogProps} />
  );
  // #endregion Tasks related

  const estimateListForTaskDialog = estimateListForTable && (
    <EstimateListDialog
      isOpen={estimateListDialogOpen}
      onClose={() => setEstimateListDialogOpen(false)}
      estimateListForTable={estimateListForTable}
      handleGoToViewEstimate={handleGoToViewEstimate}
      renderIconCell={renderEstimateIconCell}
      estimateItemList={estimateItemList}
      invoiceList={workRecordInvoiceList}
      currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
    >
      {{
        ViewEstimateDialog: viewEstimateDialog,
        HandlePaymentDialog: estimateTableHandlePaymentDialog,
        ViewInvoiceDialog: viewInvoiceDialog,
        ConfirmationDialog: confirmationDialog,
        HandleSendReceiptDialog: handleSendReceiptDialog,
        InvoiceListForWRDialog: invoiceListForWRDialog,
      }}
    </EstimateListDialog>
  );

  // #endregion Components

  if (isDeleted) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="ml-3">
              <h3 className="text-lg font-bold text-red-800">
                {strings.WORK_RECORD_DELETED}
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{strings.WORK_RECORD_DELETED_MESSAGE}</p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <BaseButtonSecondary
                    type="button"
                    isBusy={isRestoringWRLoading}
                    onClick={handleRestoreWorkRecord}
                    className="rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100"
                  >
                    {strings.RESTORE_WR_QUESTION}
                  </BaseButtonSecondary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (
    !workRecord ||
    !customerDoc ||
    isLoadingParentRecordPhotos ||
    isLoadingMembershipTemplateList ||
    isLoadingScheduledAwaitingTasksWithLocation ||
    vehicleListIsLoading ||
    membershipsIsLoading
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <>
      <WorkRecordAndTasksPage
        workRecord={workRecord}
        tasks={allTasksForParentRecord}
      >
        {{
          siteKeyLocationSection,
          workRecordPanel,
          tasksPanel,
          closeRecordBtn,
          deleteRecordBtn,
          dublicateJobBtn,
          feedbackBtn,
          jobPDFBtn,
        }}
      </WorkRecordAndTasksPage>
      {editWorkRecordDialog}
      {rescheduleTaskDialog}
      {editTaskDialog}
      {viewPreviousJobsDialog}
      {confirmCloseWRDialog}
      {confirmDeleteWRDialog}
      {taskStatusChangeDialog}
      {estimateListForTaskDialog}
      {duplicateJobDialog}
      {invoiceListForWRDialog}
      {feedbackDialog}
      {multiPayCreditCardDialog}
      {bulkManualPaymentDialog}
      {confirmDeleteTaskDialog}
      {assetDialog}
    </>
  );
}

function getScheduleString(
  scheduleDatetime: Date,
  scheduledByPriority: ScheduleByPriorityType | null,
): string {
  let sentence = "";

  switch (scheduledByPriority) {
    case "Urgent":
      sentence = `as ${scheduledByPriority} and scheduled for ${convertToReadableTimestamp(
        convertJSDateToFSTimestamp(scheduleDatetime),
      )}`;
      break;
    case "Awaiting Schedule":
    case "Next Opportunity":
    case "Awaiting Parts":
    case "Awaiting Estimate":
    case "Awaiting Approval":
      sentence = `as ${scheduledByPriority}`;
      break;
    default:
      sentence = `and scheduled for ${convertToReadableTimestamp(
        convertJSDateToFSTimestamp(scheduleDatetime),
      )}`;
  }

  return sentence;
}

function getAdditionalItemsString(
  estimates: boolean,
  photos: boolean,
  attachments: boolean,
): string {
  let sentence = "";

  if (estimates && photos && attachments) {
    sentence = "All Estimates, Photos and Attachments will be copied.";
  } else if (estimates && photos && !attachments) {
    sentence = "All Estimates and Photos will be copied.";
  } else if (estimates && !photos && !attachments) {
    sentence = "All Estimates will be copied.";
  } else if (!estimates && photos && !attachments) {
    sentence = "All Photos will be copied.";
  } else if (!estimates && photos && attachments) {
    sentence = "All Photos and Attachments will be copied.";
  } else if (!estimates && !photos && attachments) {
    sentence = "All Attachments will be copied.";
  } else if (estimates && !photos && attachments) {
    sentence = "All Estimates and Attachments will be copied.";
  }

  return sentence;
}

async function handleDeleteInvoice(
  siteKeyID: string,
  invoiceID: string,
): Promise<void> {
  return DbWrite.invoices.delete(siteKeyID, invoiceID);
}

async function handleDeletePayment(
  siteKeyID: string,
  paymentID: string,
): Promise<void> {
  return DbWrite.payments.delete(siteKeyID, paymentID);
}
