// Libs
import { Control, Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";

// Local
import BaseInputText from "../../components/BaseInputText";
import BaseInputSelect from "../../components/BaseInputSelect";
import StyledMessage from "../../components/StyledMessage";
import { StyledTooltip } from "../../components/StyledTooltip";
import StyledSwitchGroup from "../../components/StyledSwitchGroup";
import {
  invoiceFrequency,
  invoiceMethod,
} from "../../models/membership-template";
import { getReadableInvoiceMethod } from "../../models/membership-template";
import { TemplateFormSchema, TemplateFormState } from "./types";
import BaseInputNumber from "../../components/BaseInputNumber";
import BaseInputTextArea from "../../components/BaseInputTextArea";

interface Props {
  /** this connects the form to the save button in the parent component */
  id: string;
  onSubmit: (formValues: TemplateFormState) => Promise<void>;
  setIsBusy: (isBusy: boolean) => void;
  defaultValues?: TemplateFormState;
  children: {
    pbiSection: React.ReactNode;
    renewalPBISection: React.ReactNode;
    taskGenSection: (control: Control<TemplateFormState>) => React.ReactNode;
  };
}

/** These are shared between add/edit membership template components */
export function AddEditTemplateForm(props: Props) {
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
  } = useForm<TemplateFormState>({
    resolver: zodResolver(TemplateFormSchema),
    defaultValues: props.defaultValues ?? {
      title: "",
      description: "",
      invoiceMethod: invoiceMethod[0],
      invoiceFrequency: invoiceFrequency[0],
      automaticallyRenewMembership: false,
      automaticallyGenerateTasks: false,
      discount: 0,
    },
  });

  const watchInvMethod = watch("invoiceMethod");

  const { setIsBusy } = props;
  // update parent component's save button loading state
  useEffect(() => {
    setIsBusy(isSubmitting);
  }, [isSubmitting, setIsBusy]);

  const handleFormSubmit: SubmitHandler<TemplateFormState> = async (
    formValues,
  ) => {
    await props.onSubmit(formValues);
  };

  return (
    <form
      id={props.id}
      autoComplete="off"
      onSubmit={handleSubmit(handleFormSubmit)}
      className="grid grid-cols-1 gap-8 md:grid-cols-2"
    >
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <div>
            <BaseInputText
              text="Title"
              inputName="title"
              admin={true}
              required={true}
              {...field}
            />
            {errors.title?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.title.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <div>
            <BaseInputTextArea
              text="Description"
              inputName="description"
              admin={true}
              required={true}
              rows={1}
              {...field}
              value={field.value === null ? "" : field.value}
            />
            {errors.description?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.description.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        )}
      />

      <Controller
        name="invoiceMethod"
        control={control}
        render={({ field }) => (
          <div>
            <BaseInputSelect
              inputName="invoiceMethod"
              text="Invoice Method"
              admin={true}
              required={true}
              className="capitalize"
              {...field}
              value={field.value === null ? "" : field.value}
              onChange={(event) =>
                field.onChange(
                  event.target.value === "" ? null : event.target.value,
                )
              }
            >
              {invoiceMethod.map((method) => {
                return (
                  <option key={method} value={method}>
                    {getReadableInvoiceMethod(method)}
                  </option>
                );
              })}
            </BaseInputSelect>
            {errors.invoiceMethod?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.invoiceMethod.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        )}
      />

      <Controller
        name="invoiceFrequency"
        control={control}
        render={({ field }) => (
          <div>
            <BaseInputSelect
              inputName="invoiceFrequency"
              text="Invoice Frequency"
              admin={true}
              required={true}
              className="capitalize"
              {...field}
              value={field.value === null ? "" : field.value}
              onChange={(event) =>
                field.onChange(
                  event.target.value === "" ? null : event.target.value,
                )
              }
            >
              {invoiceFrequency.map((frequency) => {
                return (
                  <option
                    key={frequency}
                    value={frequency}
                    className="capitalize"
                  >
                    {frequency}
                  </option>
                );
              })}
            </BaseInputSelect>
            {errors.invoiceFrequency?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.invoiceFrequency.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        )}
      />

      {watchInvMethod === "automatic" && (
        <StyledTooltip
          title={`When enabled, memberships using this template will automatically renew at the end of their term. Individual memberships can override this setting. Customers receive email notifications about upcoming renewals.`}
        >
          <div className="flex justify-center rounded border border-black px-3 py-2">
            <Controller
              name="automaticallyRenewMembership"
              control={control}
              render={({ field }) => (
                <StyledSwitchGroup
                  readableName="Automatically Renew Membership"
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  ref={field.ref}
                  checked={field.value ?? false}
                  id="automaticallyRenewMembership"
                  name={field.name}
                />
              )}
            />
          </div>
        </StyledTooltip>
      )}

      <Controller
        name="discount"
        control={control}
        render={({ field }) => (
          // this discount may be applied to services rendered or equipment purchased for customers with this membership.
          // customers with this membership plan are eligible for this percentage discount on services rendered and equipment purchases.
          // customers with this membership plan are eligible for this percentage discount on estimates/invoices.
          // this discount percentage will be available to apply to estimates for customers with this membership plan.
          <div>
            <StyledTooltip title="This discount percentage may be applied to estimates for customers with this membership plan.">
              <BaseInputNumber
                text="Discount"
                className="max-w-28"
                inputName="discount"
                admin={true}
                required={false}
                {...field}
                value={field.value}
                onChange={(event) => {
                  const numberValue = event.target.valueAsNumber;
                  // if (displayError === true && numberValue > 0) {
                  //   setDisplayError(false);
                  // }
                  field.onChange(isNaN(numberValue) ? "" : numberValue);
                }}
              />
            </StyledTooltip>
            {errors.discount?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.discount.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        )}
      />

      <div className="flex flex-col gap-4 rounded bg-gray-100 px-5 py-4 md:col-span-2">
        <p className="text-sm">
          Selecting a price book item is required. The item should define the
          membership fee for this plan. If the membership should renew at a
          different rate, a renewal price book item should also be specified.
        </p>
        <div className="flex flex-col gap-4 sm:grid sm:grid-cols-2 sm:items-start lg:gap-8">
          {props.children.pbiSection}
          {props.children.renewalPBISection}
        </div>
      </div>
      <div className="flex flex-col gap-4 rounded bg-gray-100 px-5 py-4 text-sm md:col-span-2">
        <span>
          <p className="mb-1.5">
            Template tasks are expected to be completed during the membership
            term.
          </p>
          <p>
            When automatic task generation is enabled, tasks will be
            automatically created and distributed evenly throughout the
            membership period. When automatic task generation is disabled, tasks
            must be manually created for the membership. This setting can be
            overridden on a per-membership basis.
          </p>
        </span>
        {props.children.taskGenSection(control)}
      </div>
    </form>
  );
}
