//Libs

//Local
import NavButtonBack from "../../components/NavButtonBack";
import { CustomerLocationSection } from "../../components/customers/CreateTask";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import * as strings from "../../strings";
import { CustomerDetails } from "../../components/estimates/CustomerDetails";
import { CustomerAddress } from "../../components/estimates/CustomerAddress";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { ExistingMembership } from "../../models/membership";
import { ExistingTask } from "../../models/task";

interface Props {
  /* DATA */
  customer: ExistingCustomer;
  customerLocationOptions: ExistingCustomerLocation[];
  selectedCustomerLocation: ExistingCustomerLocation | null;
  membershipTemplateList: ExistingMembershipTemplate[];
  customerLocationMemberships: ExistingMembership[];
  taskDoc: ExistingTask | null;
  /* FUNCTIONS */
  setSelectedCustomerLocation: (
    location: ExistingCustomerLocation | null,
  ) => void;
  openEditCustomerDialog: (() => void) | null;
  /* CHILDREN */
  children: {
    ActionButtons: React.ReactNode;
    SiteKeyLocationSection: React.ReactNode;
    CreateEstimateDetails: React.ReactNode;
    EditCustomerDialog: React.ReactNode;
  };
}

export default function CreateEstimatePage({
  customer,
  customerLocationOptions,
  selectedCustomerLocation,
  setSelectedCustomerLocation,
  taskDoc,
  ...props
}: Props) {
  return (
    <div className="mx-auto w-full space-y-6 px-2 pb-10 xxs:px-4 lg:max-w-screen-lg">
      <NavButtonBack />
      <h1 className="text-5xl font-semibold text-primary">
        {strings.buttons.CREATE_NEW_ESTIMATE}
      </h1>
      {/* SHOW CUSTOMER INFORMATION */}
      <section>
        <hr className="mb-8 mt-12 block w-full border border-gray-200" />
        {props.children.SiteKeyLocationSection}
        <div className="flex flex-col space-y-6 sm:grid sm:grid-cols-2 sm:space-x-10 sm:space-y-0">
          <CustomerDetails
            customer={customer}
            openEditCustomerDialog={props.openEditCustomerDialog}
            openCallDialog={null}
          />
          <CustomerAddress
            customerLocations={
              selectedCustomerLocation != null
                ? [selectedCustomerLocation]
                : customerLocationOptions
            }
          />
        </div>

        {/* SELECT CUSTOMER LOCATION */}
        {taskDoc && (
          <div className="mt-10 text-lg">
            <span className="font-semibold">Location: </span>
            {selectedCustomerLocation?.fullAddress}
          </div>
        )}
        {!taskDoc && (
          <div
            className={`mt-10 flex flex-col ${
              customerLocationOptions.length < 5 ? "sm:grid sm:grid-cols-3" : ""
            } `}
          >
            {customerLocationOptions.length < 5 ? (
              <span className="pr-2 text-2xl capitalize text-primary">
                Select Address
              </span>
            ) : null}
            <CustomerLocationSection
              allowSelection={true}
              selectedCustomer={customer}
              customerLocationOptions={customerLocationOptions}
              selectedCustomerLocation={selectedCustomerLocation}
              setSelectedCustomerLocation={setSelectedCustomerLocation}
              membershipTemplateList={props.membershipTemplateList}
              customerLocationMemberships={props.customerLocationMemberships}
            />
          </div>
        )}

        <hr className="mb-8 mt-10 block w-full border border-gray-200" />

        {props.children.CreateEstimateDetails}

        <div className="mb-4 mt-4 flex flex-col sm:flex-row sm:justify-end">
          {props.children.ActionButtons}
        </div>
      </section>
      {props.children.EditCustomerDialog}
    </div>
  );
}
