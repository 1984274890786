import { Dispatch, SetStateAction } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/solid";
import { MdFormatListBulleted } from "react-icons/md";
import { Control, Controller } from "react-hook-form";

// Local
import { ExistingPriceBookItem } from "../../models/price-book-item";
import { ExistingSiteKey } from "../../models/site-key";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import AddNewEstimateItemSelection from "../../components/estimates/AddNewEstimateItemSelection";
import { TemporaryEstimateItem } from "../../models/estimate-item";
import currencyFormatter from "../../currency";
import * as strings from "../../strings";
import { getReadableCraftType } from "../../models/craft-types";
import { getReadableTaskType } from "../../models/task-types";
import { getReadableTaskStatus } from "../../models/task-status";
import { DialogStates, TemporaryTaskGeneration } from "./types";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import StyledSwitchGroup from "../../components/StyledSwitchGroup";
import { TemplateFormState } from "./types";

interface Props {
  siteKeyDoc: ExistingSiteKey | null;
  onFilterTextBoxChanged: (searchTerm: string) => Promise<void>;

  dialogStates: DialogStates;
  setDialogStates: Dispatch<SetStateAction<DialogStates>>;

  typesensePBIs: ExistingPriceBookItem[];
  pbi: ExistingPriceBookItem | null;
  renewalPBI: ExistingPriceBookItem | null;
  setPBI: Dispatch<SetStateAction<ExistingPriceBookItem | null>>;
  setRenewalPBI: Dispatch<SetStateAction<ExistingPriceBookItem | null>>;

  taskGenerationList: TemporaryTaskGeneration[];
  setTaskGenerationList: Dispatch<SetStateAction<TemporaryTaskGeneration[]>>;
  setTaskGenerationDoc: Dispatch<
    SetStateAction<TemporaryTaskGeneration | null>
  >;
}

/** Shared utility functions/components, for membership template forms */
export function getTemplateFormSections({
  pbi,
  renewalPBI,
  dialogStates,
  setDialogStates,
  onFilterTextBoxChanged,
  typesensePBIs,
  siteKeyDoc,
  setPBI,
  setRenewalPBI,
  taskGenerationList,
  setTaskGenerationList,
  setTaskGenerationDoc,
}: Props): {
  pbiComponent: JSX.Element;
  renewalPBIComponent: JSX.Element;
  taskGenComponent: (control: Control<TemplateFormState>) => JSX.Element;
} {
  return {
    pbiComponent: getPBIComponent({
      pbi,
      isSectionOpen: dialogStates.pbi,
      setDialogStates,
      onFilterTextBoxChanged,
      typesensePBIs,
      onSelectItem: (tempItem: TemporaryEstimateItem) => {
        const pbi = typesensePBIs.find(
          (item) => item.id === tempItem.priceBookItemID,
        );
        setPBI(pbi ?? null);
        setDialogStates((prev) => ({ ...prev, pbi: false }));
      },
      siteKeyDoc,
      setPricebookItem: setPBI,
    }),

    renewalPBIComponent: getRenewalPBIComponent({
      renewalPBI,
      isSectionOpen: dialogStates.renewalPBI,
      setDialogStates,
      onFilterTextBoxChanged,
      typesensePBIs,
      onSelectItem: (tempItem: TemporaryEstimateItem) => {
        const pbi = typesensePBIs.find(
          (item) => item.id === tempItem.priceBookItemID,
        );
        setRenewalPBI(pbi ?? null);
        setDialogStates((prev) => ({ ...prev, renewalPBI: false }));
      },
      siteKeyDoc,
      setRenewalPBI,
    }),

    taskGenComponent: (control: Control<TemplateFormState>) =>
      getTaskGenComponent({
        setDialogStates,
        taskGenerationList,
        deleteTask: (id: string) => {
          setTaskGenerationList((prev) =>
            prev.filter((task) => task.id !== id),
          );
        },
        editTask: (id: string) => {
          const taskToEdit = taskGenerationList.find((task) => task.id === id);
          if (!taskToEdit) return;
          setTaskGenerationDoc(taskToEdit);
          setDialogStates((prev) => ({ ...prev, taskGen: true }));
        },
        control,
      }),
  };
}

function getPBIComponent(args: {
  siteKeyDoc: ExistingSiteKey | null;
  onFilterTextBoxChanged: (searchTerm: string) => Promise<void>;

  isSectionOpen: boolean;
  setDialogStates: Dispatch<SetStateAction<DialogStates>>;

  typesensePBIs: ExistingPriceBookItem[];
  pbi: ExistingPriceBookItem | null;
  setPricebookItem: Dispatch<SetStateAction<ExistingPriceBookItem | null>>;
  onSelectItem: (tempEstimateItem: TemporaryEstimateItem) => void;
}): JSX.Element {
  const currency =
    args.siteKeyDoc?.customizations.accounting?.currency ?? "USD";

  if (args.pbi == null) {
    if (args.isSectionOpen === false) {
      return (
        <BaseButtonPrimary
          type="button"
          onClick={() =>
            args.setDialogStates((prev) => ({ ...prev, pbi: true }))
          }
          className="w-full text-primary sm:mx-auto sm:w-52"
        >
          <AddCircleIcon fontSize="small" className="mr-2" />
          {strings.SELECT_PRICEBOOK_ITEM}
        </BaseButtonPrimary>
      );
    } else {
      return (
        <div className="mb-4">
          <AddNewEstimateItemSelection
            onSearch={args.onFilterTextBoxChanged}
            PBItemQueryResultList={args.typesensePBIs}
            handleAddEstimateItem={args.onSelectItem}
            currency={currency}
          />
        </div>
      );
    }
  } else {
    return (
      <div className="relative mt-4 grid grid-cols-[5fr,2fr,1fr] rounded-2xl border border-gray-200 bg-white p-4 pt-5">
        <div className="absolute -top-[17.5px] left-[10.5px] h-[1.1rem] w-[8.2rem] rounded-t-lg bg-gray-200"></div>
        {/* ^ the empty div provides the border for "Price Book Item" */}
        <div className="absolute -top-4 left-3 rounded-t-md bg-white px-2 pt-0.5">
          Price Book Item
        </div>
        {/* TITLE AND DESCRIPTION */}
        <div className="flex w-full items-center">
          <div className="flex flex-col space-y-2">
            <span className="block whitespace-normal text-base font-medium leading-5">
              {args.pbi.title}
            </span>
            <span className="block whitespace-normal text-sm leading-5 text-gray-400">
              {args.pbi.description}
            </span>
            {args.pbi.discountable === false && (
              <p className={`text-sm italic`}>* Non-discountable</p>
            )}
          </div>
        </div>
        {/* AMOUNT */}
        <div className="flex items-center justify-self-end">
          <div className="text-lg font-medium sm:text-base md:text-lg">
            {currencyFormatter(args.pbi.unitPrice, currency)}
          </div>
        </div>
        {/* DELETE BUTTON */}
        <button
          className="h-fit w-fit place-self-center justify-self-end rounded-full hover:bg-red-50"
          onClick={() => args.setPricebookItem(null)}
        >
          <TrashIcon
            aria-label="delete button"
            className="block h-10 w-10 cursor-pointer p-2 text-red-700"
          />
        </button>
      </div>
    );
  }
}

function getRenewalPBIComponent(args: {
  siteKeyDoc: ExistingSiteKey | null;
  onFilterTextBoxChanged: (searchTerm: string) => Promise<void>;

  renewalPBI: ExistingPriceBookItem | null;
  isSectionOpen: boolean;
  setDialogStates: Dispatch<SetStateAction<DialogStates>>;

  typesensePBIs: ExistingPriceBookItem[];
  onSelectItem: (tempItem: TemporaryEstimateItem) => void;
  setRenewalPBI: Dispatch<SetStateAction<ExistingPriceBookItem | null>>;
}): JSX.Element {
  const currency =
    args.siteKeyDoc?.customizations.accounting?.currency ?? "USD";

  if (args.renewalPBI == null) {
    if (args.isSectionOpen === false) {
      return (
        <BaseButtonSecondary
          type="button"
          onClick={() =>
            args.setDialogStates((prev) => ({ ...prev, renewalPBI: true }))
          }
          className="w-full sm:mx-auto sm:w-52"
          textColor="text-black"
          borderColor="border-gray-700"
        >
          <AddCircleIcon fontSize="small" className="mr-2" />
          {strings.SELECT_RENEWAL_PRICEBOOK_ITEM}
        </BaseButtonSecondary>
      );
    } else {
      return (
        <div className="mb-4">
          <AddNewEstimateItemSelection
            onSearch={args.onFilterTextBoxChanged}
            PBItemQueryResultList={args.typesensePBIs}
            handleAddEstimateItem={args.onSelectItem}
            currency={currency}
          />
        </div>
      );
    }
  } else {
    return (
      <div className="relative mt-4 grid grid-cols-[5fr,2fr,1fr] rounded-2xl border border-gray-200 bg-white p-4 pt-5">
        <div className="absolute -top-[17.5px] left-[10.5px] h-[1.1rem] w-[12.2rem] rounded-t-lg bg-gray-200"></div>
        {/* ^ the empty div provides the border for "Renewal Price Book Item" */}
        <div className="absolute -top-4 left-3 rounded-t-md bg-white px-2 pt-0.5">
          Renewal Price Book Item
        </div>
        {/* TITLE AND DESCRIPTION */}
        <div className="flex w-full items-center">
          <div className="flex flex-col space-y-2">
            <span className="block whitespace-normal text-base font-medium leading-5">
              {args.renewalPBI.title}
            </span>
            <span className="block whitespace-normal text-sm leading-5 text-gray-400">
              {args.renewalPBI.description}
            </span>
            {args.renewalPBI.discountable === false && (
              <p className={`text-sm italic`}>* Non-discountable</p>
            )}
          </div>
        </div>
        {/* AMOUNT */}
        <div className="flex items-center justify-self-end">
          <div className="text-lg font-medium sm:text-base md:text-lg">
            {currencyFormatter(args.renewalPBI.unitPrice, currency)}
          </div>
        </div>
        {/* DELETE BUTTON */}
        <button
          className="h-fit w-fit place-self-center justify-self-end rounded-full hover:bg-red-50"
          onClick={() => args.setRenewalPBI(null)}
        >
          <TrashIcon
            aria-label="delete button"
            className="block h-10 w-10 cursor-pointer p-2 text-red-700"
          />
        </button>
      </div>
    );
  }
}

function getTaskGenComponent(args: {
  setDialogStates: Dispatch<SetStateAction<DialogStates>>;
  taskGenerationList: TemporaryTaskGeneration[];
  deleteTask: (id: string) => void;
  editTask: (id: string) => void;
  control: Control<TemplateFormState>;
}): JSX.Element {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      <span className="flex flex-col gap-4 sm:grid sm:grid-cols-2 sm:items-center sm:justify-items-center md:col-span-2 lg:col-span-3">
        <BaseButtonSecondary
          type="button"
          onClick={() =>
            args.setDialogStates((prev) => ({ ...prev, taskGen: true }))
          }
          className="w-full sm:w-52"
          textColor="text-black"
          borderColor="border-gray-700"
        >
          <MdFormatListBulleted className="mr-2 h-5 w-5" />
          {strings.ADD_TASK_TEMPLATE}
        </BaseButtonSecondary>

        {args.taskGenerationList.length > 0 && (
          <div className="max-w-fit rounded-md border border-gray-300 bg-white px-3 py-2">
            <Controller
              name="automaticallyGenerateTasks"
              control={args.control}
              render={({ field }) => (
                <StyledSwitchGroup
                  readableName="Automatically Generate Tasks"
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  ref={field.ref}
                  checked={field.value ?? false}
                  id="automaticallyGenerateTasks"
                  name={field.name}
                />
              )}
            />
          </div>
        )}
      </span>

      {/* want a little extra space if there are tasks */}
      {args.taskGenerationList.length > 0 && (
        <div className="mt-1 md:col-span-2 lg:col-span-3"></div>
      )}

      {args.taskGenerationList.map((task) => {
        return (
          <div
            key={task.id}
            className="relative grid grid-cols-[5fr,2fr,1fr] rounded-2xl border border-gray-200 bg-white p-4 pt-5 xxs:grid-cols-[3fr,4fr,1fr] md:grid-cols-[5fr,2fr,1fr]"
          >
            <div className="flex w-full items-center">
              <div className="flex flex-col space-y-2">
                <span className="block whitespace-normal text-base font-medium leading-5">
                  <span className="text-gray-400">Job: </span>
                  {getReadableCraftType(task.craftType)}
                </span>
                <span className="block whitespace-normal text-base font-medium leading-5">
                  <span className="text-gray-400">Type: </span>
                  {getReadableTaskType(task.taskType)}
                </span>
                <span className="block whitespace-normal text-base font-medium leading-5">
                  <span className="text-gray-400">Status: </span>
                  {getReadableTaskStatus(task.taskStatus)}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-self-end">
              <span className="block whitespace-normal text-sm leading-5 text-gray-400">
                {task.description}
              </span>
            </div>

            {/* ACTION BUTTONS */}
            <div className="ml-2 flex flex-col">
              <button
                type="button"
                className="h-fit w-fit place-self-center justify-self-end rounded-full hover:bg-primaryOpacity90"
                onClick={() => args.editTask(task.id)}
              >
                <PencilIcon
                  aria-label="edit button"
                  className="block h-10 w-10 cursor-pointer p-2 text-gray-700"
                />
              </button>
              <button
                type="button"
                className="h-fit w-fit place-self-center justify-self-end rounded-full hover:bg-red-50"
                onClick={() => args.deleteTask(task.id)}
              >
                <TrashIcon
                  aria-label="delete button"
                  className="block h-10 w-10 cursor-pointer p-2 text-red-700"
                />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
