import { useState } from "react";
import { Control } from "react-hook-form";
// Local
import NavButtonBack from "../../components/NavButtonBack";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { EDIT_TEMPLATE, buttons } from "../../strings";
import { AddEditTemplateForm } from "./AddEditTemplateForm";
import { TemplateFormState } from "./types";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";

interface Props {
  template: ExistingMembershipTemplate;
  onSubmit: (formValues: TemplateFormState) => Promise<void>;
  onCancel: () => void;
  children: {
    pbiSection: React.ReactNode;
    renewalPBISection: React.ReactNode;
    taskGenSection: (control: Control<TemplateFormState>) => React.ReactNode;
  };
}

export default function EditTemplatePage(props: Props) {
  const [isBusy, setIsBusy] = useState(false);

  return (
    <div className="relative mx-auto w-full md:mx-0 md:max-w-[1280px]">
      <div className="mb-8 mt-4 flex flex-wrap items-center justify-between gap-4">
        <div className="flex flex-wrap items-center gap-2 sm:gap-4">
          <NavButtonBack onClick={props.onCancel} />
          {/* TODO: make h1 component. overridable props with sane defaults (text-3xl, sm:text-4xl)
           also.. leading-3 shouldn't be defined within that component */}
          <h1 className="text-3xl font-semibold leading-3 text-primary sm:text-5xl">
            {EDIT_TEMPLATE}
          </h1>
        </div>
        <BaseButtonPrimary
          type="submit"
          form="editTemplateForm"
          formNoValidate
          disabled={isBusy}
          isBusy={isBusy}
          busyText={buttons.BUSY_SAVING}
          // className="w-40 uppercase"
          className="w-40 uppercase sm:self-end"
        >
          {buttons.SAVE}
        </BaseButtonPrimary>
      </div>

      <AddEditTemplateForm
        id="editTemplateForm"
        onSubmit={props.onSubmit}
        setIsBusy={setIsBusy}
        defaultValues={{
          title: props.template.title,
          description: props.template.description,
          invoiceMethod: props.template.invoiceMethod,
          invoiceFrequency: props.template.invoiceFrequency,
          automaticallyRenewMembership:
            props.template.automaticallyRenewMembership ?? false,
          automaticallyGenerateTasks:
            props.template.automaticallyGenerateTasks ?? false,
          discount: props.template.discount,
        }}
      >
        {{
          pbiSection: props.children.pbiSection,
          renewalPBISection: props.children.renewalPBISection,
          taskGenSection: props.children.taskGenSection,
        }}
      </AddEditTemplateForm>
    </div>
  );
}
