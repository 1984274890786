import { useMutation } from "react-query";
import { memo, useEffect } from "react";
import isEqual from "lodash/isEqual";
// Local
import { ExistingCustomer } from "../../models/customer";
import { useIncomingCallsStore } from "../../store/incoming-calls";
import { useActiveCallStore } from "../../store/active-call";
import AlertV2 from "../AlertV2";
import AlertV2Button from "../AlertV2Button";
import LoadingSpinner from "../LoadingSpinner";
import { logger } from "../../logging";
import { DbRead } from "../../database";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import * as strings from "../../strings";

interface Props {
  siteKey: string;
  uid: string;
  customer: ExistingCustomer | null;
}

/**
 * Memoized component for displaying incoming or active call alerts.
 *
 * Allows user to match a customer to an incoming call. Once the customer is
 * matched, the component renders the active call and updates the customer record
 * with the phone number.
 *
 * TODO: ^ docs prolly aren't final
 */
// RENAME? CallControl ... CallBubble ... CallAlertControl ...
const CallAlert = memo(
  (props: Props) => {
    const addMessage = useToastMessageStore((state) => state.addToastMessage);

    const {
      currentCall: incomingCall,
      clearCall: clearIncomingCall,
      updateCallCustomer,
    } = useIncomingCallsStore();

    const { activeCall, setActiveCall, clearActiveCall, addPhoneToCustomer } =
      useActiveCallStore();

    const mutateUpdateCall = useMutation(
      () => {
        if (!props.customer) throw new Error("No customer found");
        return updateCallCustomer(props.customer.id);
      },
      {
        onSuccess: () => {
          clearIncomingCall();
          setActiveCall(incomingCall);
          const existingPhone = props.customer?.phone;
          const nonEmpty =
            existingPhone &&
            existingPhone.length > 0 &&
            existingPhone[0].length > 0;
          addPhoneToCustomer({
            customerRefPath: props.customer!.refPath, // i wouldn't normally advocate for bang usage, but we wouldn't have gotten to the onSuccess callback if customer was null
            phoneAlreadyExists: nonEmpty === true,
            uid: props.uid,
          });

          addMessage({
            id: createToastMessageID(),
            message: strings.successfulUpdate(props.customer!.name),
            dialog: false,
            type: "success",
          });
        },
        onError: () => {
          logger.error(
            `Could not update call document for ${incomingCall?.caller}`,
          );
          clearIncomingCall();
        },
      },
    );

    // Clear the active call when the call ends --
    const callDocID = incomingCall?.id ?? activeCall?.id;
    useEffect(() => {
      function subscribeToActiveCall() {
        if (!callDocID) return undefined;
        const unsubscribeFn = DbRead.calls.subscribe({
          siteKey: props.siteKey,
          docID: callDocID,
          onChange: (call) => {
            if (
              call?.callStatus === "canceled" ||
              call?.callStatus === "completed" ||
              call?.callStatus === "failed" ||
              call?.callStatus === "no-answer" ||
              call?.callStatus === "cleared"
            ) {
              clearActiveCall();
            }
          },
          onError: logger.error,
        });
        return unsubscribeFn;
      }
      const unsubscribe = subscribeToActiveCall();
      return () => unsubscribe && unsubscribe();
    }, [callDocID, clearActiveCall, props.siteKey]);

    // if (!incomingCall && !activeCall)
    //   console.log("NO CALL, returning fragment");
    if (!incomingCall && !activeCall) return <></>;

    const matchBtn = props.customer ? (
      <AlertV2Button
        variant="warning"
        onClick={() => mutateUpdateCall.mutateAsync()}
      >
        Match
      </AlertV2Button>
    ) : null;

    const MatchCustomerAlertPrompt = (
      <AlertV2
        variant="warning"
        title={
          <>
            Customer match needed for{" "}
            <span className="font-medium">{incomingCall?.caller}</span>
          </>
        }
        message={
          props.customer ? (
            <span className="pr-4 text-base">
              Match <span className="font-medium">{props.customer.name}</span>{" "}
              to this call?
            </span>
          ) : null
        }
        onDismiss={clearIncomingCall}
        alignActions="left"
        actions={
          <>
            {mutateUpdateCall.isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {matchBtn}
                <AlertV2Button variant="warning" onClick={clearIncomingCall}>
                  Dismiss
                </AlertV2Button>
              </>
            )}
          </>
        }
      />
    );

    const SelectCustomerAlertPrompt = (
      <AlertV2
        variant="warning"
        title={
          <span className="pr-4">
            Select a customer to match to{" "}
            <span className="font-medium">{incomingCall?.caller}</span>
          </span>
        }
        onDismiss={clearIncomingCall}
      />
    );

    const MatchError = (
      <AlertV2
        variant="error"
        title="Could not update call document."
        message={
          <span className="pr-4">
            Dismiss this alert and reselect the call to try again. If the issue
            persists, please contact support.
          </span>
        }
        onDismiss={() => {
          mutateUpdateCall.reset();
          clearIncomingCall();
        }}
      />
    );

    const ActiveCallAlert = (
      <AlertV2
        variant="info"
        title="Active call"
        message="You are currently on a call." // TODO: update this gazz
        // THIS BECOMES A "BUBBLE"... and persists until the call ends.
        // likely want to use some headless UI component to lift it alongside
        // the root element (portal gazz)
      />
    );

    return (
      <>
        {mutateUpdateCall.isError && (
          <div className="w-fit max-w-xl pb-2">{MatchError}</div>
        )}

        {incomingCall && props.customer ? (
          <div className="w-fit max-w-xl pb-2">{MatchCustomerAlertPrompt}</div>
        ) : null}

        {incomingCall && !props.customer ? (
          <div className="w-fit max-w-xl pb-2">{SelectCustomerAlertPrompt}</div>
        ) : null}

        {activeCall ? (
          <div className="w-fit max-w-xl pb-2">{ActiveCallAlert}</div>
        ) : null}
      </>
    );
  },
  (prev, next) => isEqual(prev.customer, next.customer),
);

export default CallAlert;
