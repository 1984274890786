import create from "zustand";
import { Timestamp } from "firebase/firestore";
// Local
import { ExistingStiltPhoneCall } from "../models/calls";
import { logger } from "../logging";
import { DbWrite } from "../database";
import { CustomerContact_CreateAPI } from "../models/customer-contact";

interface ActiveCallStore {
  /**
   * A way to mark a particular call Firestore document as the active call. This
   * is only tracked locally on the client and not persisted to the database.
   */
  activeCall: ExistingStiltPhoneCall | null;
  /**
   * Set the active call locally.
   */
  setActiveCall: (call: ExistingStiltPhoneCall | null) => void;
  /**
   * Clear the active call locally. This is done when the call ends.
   */
  clearActiveCall: () => void;
  /**
   * A convenience method that attaches the phone number to the customer record.
   *
   * Details: sets customer.phone if not already set. (Does not set anything on
   * customer.billingInfo.) If customer.phone is already set, adds an
   * "additional contact" to the customer (creates a customerContact document).
   */
  addPhoneToCustomer: (args: {
    customerRefPath: string;
    /** pass true if customer.phone is already set */
    phoneAlreadyExists: boolean;
    uid: string;
  }) => Promise<void>;
}

// Export a hook to access data globally and define initial values.
export const useActiveCallStore = create<ActiveCallStore>((set, get) => {
  return {
    activeCall: null,
    setActiveCall: (call) => {
      logger.info("setting active call", call);
      set({ activeCall: call });
    },
    clearActiveCall: () => {
      logger.info("clearing active call");
      set({ activeCall: null });
    },

    addPhoneToCustomer: async (args) => {
      const call = get().activeCall;
      if (!call) {
        logger.error(
          `active-call.ts: Expected call to exist. Unable to add phone number to customer @ ${args.customerRefPath}`,
        );
        return;
      }
      let phone: string;
      if (call.caller !== null) {
        phone = call.caller;
      } else if (call.from !== null) {
        phone = call.from;
      } else {
        logger.error(
          `active-call.ts: Expected "caller" or "from" to exist. Unable to add phone number to customer @ ${args.customerRefPath}`,
        );
        return;
      }

      const customerID = args.customerRefPath.split("/")[3];

      if (!args.phoneAlreadyExists) {
        await DbWrite.customers.update({
          phone: [phone],
          id: customerID,
          refPath: args.customerRefPath,
          timestampLastModified: Timestamp.now(),
          lastModifiedBy: args.uid,
        });
        return;
      } else {
        const contact: CustomerContact_CreateAPI = {
          type: "phone",
          value: phone,
          notes: "",
          optIn: false,
          customerID: customerID,
          customerLocationID: null,
          deleted: false,
          customData: {},
          createdBy: args.uid,
          lastModifiedBy: args.uid,
          siteKey: args.customerRefPath.split("/")[1],
        };
        await DbWrite.customerContacts.create(contact);
        return;
      }
    },
  };
});
