import React, { useEffect, useRef, useState } from "react";
import TaskDetailsDialog from "./scheduling/TaskDetailsDialog";
import TaskCardOpen from "./WorkRecordAndTasks/TaskCardOpen";
import DetailsAndEventsPanels from "./WorkRecordAndTasks/DetailsAndEventsPanels";
import ChangeTaskStatusButton from "./WorkRecordAndTasks/ChangeTaskStatusButton";
import EditTaskDialog from "./WorkRecordAndTasks/EditTaskDialog";
import RescheduleTaskDialog from "./WorkRecordAndTasks/RescheduleTaskDialog";
import TaskStatusChangeDialog from "./WorkRecordAndTasks/TaskStatusChangeDialog";
import EstimateListDialog from "./WorkRecordAndTasks/EstimateListForTaskDialog";
import {
  ExistingTask,
  Task_CreateForCustomer,
  TaskRecordManager,
} from "../models/task";
import BaseButtonPrimary from "./BaseButtonPrimary";
import * as strings from "../strings";
import { whiteLabel, WORK_RECORD_AND_TASKS_URL } from "../urls";
import { useNavigate } from "react-router-dom";
import { ExistingEstimate } from "../models/estimate";
import { ExistingCustomField } from "../models/custom-field";
import { isValidCraftType } from "../models/craft-types";
import { isValidTaskType } from "../models/task-types";
import { getTaskCustomFieldList } from "./WorkRecordAndTasks/functions";
import { useUserPermissionsStore } from "../store/user-permissions";
import { useSiteKeyDocStore } from "../store/site-key-doc";
import { useUserDisplayNamesStore } from "../store/user-display-names-map";
import { removeSystemUsersFromUserDisplayNameMap } from "../utils/removeSystemUsersFromUserMap";
import { ExistingEvent } from "../models/event";
import { ExistingVehicle } from "../models/vehicle";
import { useAuthStore } from "../store/firebase-auth";
import { User } from "firebase/auth";
import { TaskStatus } from "../models/task-status";
import { logger as devLogger } from "../logging";
import { DocumentData, Timestamp } from "firebase/firestore";
import { diffObjects } from "../assets/js/object-diff";
import { createToastMessageID, dropUndefined } from "../utils";
import { useToastMessageStore } from "../store/toast-messages";
import { DbRead, DbWrite } from "../database";
import { ExistingSiteKeyUserPermissions } from "../models/site-key-user-permissions";
import { useSiteKeyUsersStore } from "../store/site-key-users";
import { useSiteKeyLocationsStore } from "../store/site-key-locations";
import { ICellRendererParams } from "ag-grid-community";
import { StyledTooltip } from "./StyledTooltip";
import { PaymentIconWithSpinner } from "./PaymentButton";
import EmailButtonWithSpinner from "./EmailButtonWithSpinner";
import { InvoiceIconWithSpinner } from "./InvoiceCreateButton";
import { PDFIconWithSpinner } from "./PDFButton";
import ViewEstimateDialog from "./WorkRecordAndTasks/ViewEstimateDialog";
import ViewEstimateContainer from "../Pages/Estimates/ViewEstimateContainer";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import HandlePaymentDialog from "./Invoices/HandlePaymentDialog";
import { getEmailList } from "../utils/getEmailList";
import {
  APIPaymentSavedCard,
  paymentMethods,
  StiltPayment_CreateAPI,
  StiltPaymentManager,
} from "../models/stilt-payment";
import HandleSendEmailDialog from "./estimates/HandleSendEmailDialog";
import BaseInputSelect from "./BaseInputSelect";
import EditInvoiceDialog from "./Invoices/EditInvoiceDialog";
import ViewInvoiceDialog, {
  ViewInvoiceDialogProps,
} from "./Payments/ViewInvoiceDialog";
import { SchedulingButton } from "../Pages/Scheduling/SchedulingContainer";
import {
  ExistingStiltInvoice,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  TemplatePaymentTerm,
} from "../models/invoice";
import { NewManualPayment } from "./Invoices/RecordManualPaymentDialog";
import { generatePaymentUniqueLink } from "../assets/js/generatePaymentUniqueLink";
import { isWhiteLabel } from "../white-label-check";
import { generatePDF } from "./Invoices/generatePDF";
import { useMutation } from "react-query";
import { ExistingCustomer } from "../models/customer";
import { Json } from "../models/json-type";
import { useNavToCreateEstimate } from "../navigation";
import { ExistingCustomerContact } from "../models/customer-contact";
import { IMultipleUid_AssignUser } from "./CustomFields/MultipleUidDialog";
import { ExistingCustomerLocation } from "../models/customer-location";
import { ExistingCraftRecord } from "../models/craft-record";
import { ExistingEstimateItem } from "../models/estimate-item";
import { ExistingAsset } from "../models/asset";

export interface TaskDetailsDialogProps {
  taskDetailsDialogOpen: boolean;
  setTaskDetailsDialogOpen: (open: boolean) => void;

  selectedTaskID: string;

  // Allow the parent component to pre-open the reschedule task dialog
  withRescheduleTaskDialogOpen?: boolean;

  vehicleList: ExistingVehicle[];
  assets: ExistingAsset[];
  onAssetClick: (asset: ExistingAsset) => void;
  permissionsMap: Record<string, ExistingSiteKeyUserPermissions>;
}

export const TaskDetailsDialogContainer = ({
  selectedTaskID,
  taskDetailsDialogOpen,
  setTaskDetailsDialogOpen,
  vehicleList,
  assets,
  onAssetClick,
  permissionsMap,
  withRescheduleTaskDialogOpen,
}: TaskDetailsDialogProps) => {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [siteKeyDoc] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const siteKeyUsersList = useSiteKeyUsersStore(
    (state) => state.siteKeyUsersList,
  );
  const [siteKeyLocationList] = useSiteKeyLocationsStore((state) => [
    state.siteKeyLocationList,
    state.getLocationTitle,
  ]);
  const [userDisplayNamesMap, userDisplayNamesMapIsLoading] =
    useUserDisplayNamesStore((state) => [
      state.userDisplayNames,
      state.loading,
    ]);
  const filterUserDisplayNamesMap =
    removeSystemUsersFromUserDisplayNameMap(userDisplayNamesMap);

  const [openEditTaskDialog, setOpenEditTaskDialog] = useState(false);
  const [openRescheduleTaskDialog, setOpenRescheduleTaskDialog] = useState(
    withRescheduleTaskDialogOpen ?? false,
  );
  const [openTaskStatusChangeDialog, setOpenTaskStatusChangeDialog] =
    useState<boolean>(false);

  const [estimateListDialogOpen, setEstimateListDialogOpen] = useState(false);

  const [viewEstimateDialogOpen, setViewEstimateDialogOpen] = useState(false);
  const [selectedEstimate, setSelectedEstimate] =
    useState<ExistingEstimate | null>(null);

  const [selectedInvoice, setSelectedInvoice] =
    useState<ExistingStiltInvoice | null>(null);
  const [isHandlePaymentDialogOpen, setIsHandlePaymentDialogOpen] =
    useState(false);
  const [isTableHandlePaymentDialogOpen, setIsTableHandlePaymentDialogOpen] =
    useState(false);
  const [invoicePaymentLink, setInvoicePaymentLink] = useState<string | null>(
    null,
  );
  const [handleSendEmailDialogOpen, setHandleSendEmailDialogOpen] =
    useState(false);
  const [viewInvoiceDialogProps, setViewInvoiceDialogProps] =
    useState<ViewInvoiceDialogProps | null>(null);
  const [isViewInvoiceDialogOpen, setIsViewInvoiceDialogOpen] = useState(false);
  const [editInvoiceDialogOpen, setEditInvoiceDialogOpen] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<DateTime>(
    DateTime.now(),
  );

  const [selectedDueDate, setSelectedDueDate] = useState<DateTime | null>(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState<DateTime>(
    DateTime.now(),
  );
  const [paymentTerms, setPaymentTerms] = useState<
    ExistingStiltInvoice["paymentTerms"] | null
  >(null);
  const [customer, setCustomer] = useState<ExistingCustomer | null>(null);
  const [workRecordInvoiceList, setWorkRecordInvoiceList] = useState<
    ExistingStiltInvoice[]
  >([]);

  const [skTaskCustomFields, setSkTaskCustomFields] = useState<
    ExistingCustomField[]
  >([]);

  const [taskForTaskStatusChangeDialog, setTaskForTaskStatusChangeDialog] =
    useState<ExistingTask | null>(null);
  // const [
  //   originalTaskForTaskStatusChangeDialog,
  //   setOriginalTaskForTaskStatusChangeDialog,
  // ] = useState<ExistingTask | null>(null);

  const [customerContacts, setCustomerContacts] = useState<
    ExistingCustomerContact[] | null
  >(null);
  const [customerLocation, setCustomerLocation] =
    useState<ExistingCustomerLocation | null>(null);
  const [selectedTask, setSelectedTask] = useState<ExistingTask | null>(null);
  const [taskEvents, setTaskEvents] = useState<ExistingEvent[]>([]);
  const [taskEstimates, setTaskEstimates] = useState<ExistingEstimate[]>([]);
  const [craftRecord, setCraftRecord] = useState<ExistingCraftRecord | null>(
    null,
  );
  const [estimateItemsMap, setEstimateItemsMap] = useState<
    Record<string, ExistingEstimateItem[]>
  >({});

  const [nextTaskStatus, setNextTaskStatus] = useState<TaskStatus | null>(null);

  const [showCraftPersistence, setShowCraftPersistence] = useState(false);

  const templatesPaymentTerms: Record<string, TemplatePaymentTerm> =
    siteKeyDoc?.customizations.paymentTerms ?? {};

  const navigate = useNavigate();
  const navToCreateEstimateByTask = useNavToCreateEstimate();

  const assignedUserList = useRef<IMultipleUid_AssignUser[]>([]);

  // Add new state to handle delayed opening of reschedule dialog
  const [shouldShowRescheduleDialog, setShouldShowRescheduleDialog] =
    useState(false);

  // Modify the useEffect to handle both opening and closing states
  useEffect(() => {
    if (!taskDetailsDialogOpen) {
      // Reset states when dialog closes
      setShouldShowRescheduleDialog(false);
      setOpenRescheduleTaskDialog(false);
    } else if (taskDetailsDialogOpen && withRescheduleTaskDialogOpen) {
      // Small delay to ensure TaskDetailsDialog is mounted first
      const timer = setTimeout(() => {
        setOpenRescheduleTaskDialog(true);
        setShouldShowRescheduleDialog(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [taskDetailsDialogOpen, withRescheduleTaskDialogOpen]);

  useEffect(() => {
    async function subscribeToTask() {
      if (!siteKeyDoc) return;
      DbRead.tasks.subscribeOneByID(
        siteKeyDoc.id,
        selectedTaskID,
        setSelectedTask,
      );
    }

    subscribeToTask();
  }, [selectedTaskID, setSelectedTask, siteKeyDoc]);

  useEffect(() => {
    async function subscribeToTaskEstimates() {
      if (!siteKeyDoc) return;
      DbRead.estimates.subscribeByTaskID(
        siteKeyDoc.id,
        selectedTaskID,
        setTaskEstimates,
      );
    }

    subscribeToTaskEstimates();
  }, [selectedTaskID, setSelectedTask, siteKeyDoc]);

  useEffect(() => {
    async function subscribeToTaskEvents() {
      if (!siteKeyDoc) return;
      DbRead.events.subscribeAllByTaskID({
        siteKey: siteKeyDoc.id,
        taskID: selectedTaskID,
        onChange: setTaskEvents,
      });
    }

    subscribeToTaskEvents();
  }, [selectedTaskID, setSelectedTask, siteKeyDoc]);

  useEffect(() => {
    async function getCustomer() {
      if (!selectedTask?.customerID) return;
      if (!siteKeyDoc) return;

      const customer = await DbRead.customers.get(
        siteKeyDoc?.id,
        selectedTask.customerID,
      );
      setCustomer(customer);
    }

    getCustomer();
  }, [selectedTask?.customerID, siteKeyDoc]);

  useEffect(() => {
    async function getCraftRecord() {
      if (!siteKeyDoc) return;
      if (!selectedTask) return;

      const craftRecordID = selectedTask?.craftRecordID.split("/")[3];
      if (!craftRecordID) return;
      const craftRecord = await DbRead.parentRecords.get(
        siteKeyDoc?.id,
        craftRecordID,
      );
      setCraftRecord(craftRecord);
    }

    getCraftRecord();
  }, [selectedTask, selectedTask?.craftRecordID, siteKeyDoc]);

  useEffect(() => {
    if (!customer) return;
    if (!selectedTask?.customerLocationID) return;

    const customerLocation =
      customer.customerLocations[selectedTask.customerLocationID];
    if (customerLocation) {
      setCustomerLocation(customerLocation);
    } else {
      setCustomerLocation(null);
    }
  }, [customer, selectedTask?.customerLocationID]);

  useEffect(() => {
    if (siteKeyUsersList.length === 0) return;

    assignedUserList.current = siteKeyUsersList
      // we don't want system users to be selectable in the multiple-uid dialog
      .filter((user) => user.systemUser !== true)
      .map((user) => ({
        uid: user.id,
        name: user.displayName,
        company: user.companyName,
        avatarUrl: user.userPhoto_URL,
        isAssigned: false,
        assignedVehicle: null,
      }));
  }, [siteKeyUsersList]);

  useEffect(() => {
    function getInvoices() {
      if (!siteKeyDoc) return;
      if (!selectedTask) return;

      const craftRecordID = selectedTask.craftRecordID.split("/")[3];
      if (!craftRecordID) return;

      // return ƒn for cleanup
      DbRead.invoices.subscribeByCraftRecordId(
        siteKeyDoc.id,
        craftRecordID,
        setWorkRecordInvoiceList,
      );
    }

    getInvoices();
  }, [selectedTask, selectedTask?.craftRecordID, siteKeyDoc]);

  useEffect(() => {
    function getEstimateItems() {
      if (!siteKeyDoc) return;

      // return ƒn for cleanup
      taskEstimates.forEach((estimate) => {
        return DbRead.estimateItems.subscribeByEstimateId({
          siteKey: siteKeyDoc.id,
          estimateId: estimate.id,
          onChange: (items) => {
            setEstimateItemsMap((prev) => ({
              ...prev,
              [estimate.id]: items,
            }));
          },
        });
      });
    }

    getEstimateItems();
  }, [taskEstimates, siteKeyDoc]);

  useEffect(() => {
    function getSelectedCustomerContacts() {
      if (!customer) return undefined;
      if (!siteKeyDoc) return undefined;
      return DbRead.customerContacts.subscribeByCustomerID({
        siteKey: siteKeyDoc.id,
        customerID: customer.id,
        onChange: setCustomerContacts,
      });
    }

    const unsubscribeFn = getSelectedCustomerContacts();
    return () => unsubscribeFn && unsubscribeFn();
  }, [customer, siteKeyDoc]);

  // Get the company name to display on the UI
  // const companyName = companyList.find(
  //   (company) => company.id === selectedTask?.assignedCompanyID,
  // )?.name;
  // Get the siteKey's customizations for this task
  const targetWorkType = selectedTask?.craftType;
  const targetTaskType = selectedTask?.taskType;
  let taskCustomFields: ExistingCustomField[];
  if (
    isValidCraftType(targetWorkType) &&
    isValidTaskType(targetTaskType) &&
    siteKeyDoc
  ) {
    taskCustomFields = getTaskCustomFieldList({
      siteKey: siteKeyDoc,
      targetWorkType,
      targetTaskType,
    });
  } else {
    taskCustomFields = [];
  }

  function handlePaymentTermsChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = event.target.value;
    if (value === "") {
      setPaymentTerms(null);
    } else {
      setPaymentTerms(value);
      const selectedPaymentTermTemplate = Object.entries(
        templatesPaymentTerms,
      ).find(([key, _]) => key === value);
      if (!selectedPaymentTermTemplate) return;
      const newDueDate = selectedIssueDate.plus({
        days: selectedPaymentTermTemplate[1].daysUntilDue,
      });
      setSelectedDueDate(newDueDate);
    }
  }

  async function handleSaveUpdatedInvoice(
    updatedInvoice: Partial<ExistingStiltInvoice>,
  ) {
    if (!selectedInvoice) return;

    const updatedInvoiceFields: StiltInvoice_UpdateAPI = {
      ...updatedInvoice,
      paymentTerms: paymentTerms,
      dueDate: selectedDueDate ? selectedDueDate.toString() : null,
      issueDate: selectedIssueDate?.toString(),
    };

    const diffInvoiceValues: DocumentData = diffObjects(
      selectedInvoice,
      updatedInvoiceFields,
    ).diff;

    if (Object.keys(diffInvoiceValues).length === 0) {
      devLogger.debug("No values have changed");
      return;
    }

    const editInvoiceToValidate: Partial<ExistingStiltInvoice> = {
      ...diffInvoiceValues,
      id: selectedInvoice.id,
      refPath: selectedInvoice.refPath,
    };

    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
      editInvoiceToValidate,
    );
    devLogger.info("validatedEditInvoice", validatedEditInvoice);

    try {
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });

      devLogger.debug("Payment terms has been updated successfully.");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Payment terms for Invoice #${selectedInvoice.invoiceNumber}`,
        ),
        dialog: true,
        type: "success",
      });
      setIsViewInvoiceDialogOpen(false);
    } catch (error) {
      devLogger.error(
        `An error occurred during handleSaveNewPaymentTerms`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: true,
        type: "error",
      });
    }

    setSelectedInvoice(null);
  }

  async function applyManualPayment(
    formValues: NewManualPayment,
  ): Promise<void> {
    if (firebaseUser == null) {
      devLogger.error("firebaseUser is null");
      return;
    }

    if (!selectedInvoice) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }

    if (!siteKeyDoc) {
      devLogger.error("siteKeyDoc is null");
      return;
    }

    const valuesToBeValidated: StiltPayment_CreateAPI = {
      ...formValues,
      stringTimestampPaymentMade: selectedDateTime.toISO(),
      customerID: selectedInvoice.customerID,
      invoiceID: selectedInvoice.id,
      customData: {},
      createdBy: firebaseUser.uid,
      siteKey: siteKeyDoc.id,
      billToCustomerID: selectedInvoice.billToCustomerID,
      locationID: selectedInvoice.locationID,
      deleted: false,
    };

    const validPaymentData =
      StiltPaymentManager.parseCreate(valuesToBeValidated);
    devLogger.info("validPaymentData", validPaymentData);

    try {
      await mutateRecordManualPayment.mutateAsync({
        paymentData: validPaymentData,
      });
      if (
        validPaymentData.amount === selectedInvoice.amountDue &&
        selectedInvoice.status !== "draft" &&
        siteKeyDoc?.customizations.sendAutomatedReceiptToCustomers
      ) {
        if (selectedInvoice.email !== null) {
          addMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT,
            dialog: true,
            type: "success",
          });
        } else {
          addMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_NO_EMAIL,
            dialog: true,
            type: "info",
          });
        }
      } else {
        addMessage({
          id: createToastMessageID(),
          message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
          dialog: true,
          type: "success",
        });
      }
    } catch (err) {
      devLogger.error("handleRecordManualPayment", err);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }
  }

  async function handleEmailReceipt(emailAddresses: string[]): Promise<void> {
    if (!selectedInvoice) return;
    if (!siteKeyDoc) return;
    if (emailList.length === 0) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.payments.emailReceipt({
        siteKeyID: siteKeyDoc.id,
        invoiceID: selectedInvoice.id,
        customerEmailList: emailAddresses,
      });
      setEstimateListDialogOpen(false);
      addMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      devLogger.error("Failed to email receipt to customer", e);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT_NOTIFIED,
        dialog: false,
        type: "error",
      });
    } finally {
      setSelectedInvoice(null);
    }
  }

  /** send itemized invoice along with payment link */
  async function emailInvoice(
    email: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!customer || !selectedInvoice || !siteKeyDoc) return;
    if (email.length === 0) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        type: "error",
        dialog: false,
      });
      return;
    }

    const paymentLink = await generatePaymentUniqueLink(
      siteKeyDoc.id,
      selectedInvoice.id,
      "email",
    );
    if (!paymentLink) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.invoices.sendViaEmail({
        siteKey: siteKeyDoc.id,
        invoiceURL: paymentLink,
        customerEmailList: email,
        includeJobPhotos: includeJobPhotos,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_INVOICE,
        dialog: false,
        type: "error",
      });
      devLogger.error("error emailing customer invoice", e);
    }
  }

  async function sendEmailFromHandlePaymentDialog(
    emails: string[],
    shouldIncludePhotos: boolean,
  ): Promise<void> {
    if (!selectedInvoice) return;
    if (selectedInvoice.amountDue > 0) {
      await emailInvoice(emails, shouldIncludePhotos);
    } else {
      await handleEmailReceipt(emails);
    }
  }

  async function handleDeleteInvoice(
    siteKeyID: string,
    invoiceID: string,
  ): Promise<void> {
    return DbWrite.invoices.delete(siteKeyID, invoiceID);
  }

  async function handleDeletePayment(
    siteKeyID: string,
    paymentID: string,
  ): Promise<void> {
    return DbWrite.payments.delete(siteKeyID, paymentID);
  }

  async function handleRefund(
    paymentID: string,
    refundAmount: number,
  ): Promise<void> {
    if (!siteKeyDoc) return;
    // try/catch is in the InvoiceSummary component; don't want it here
    await DbWrite.payments.issueRefund(siteKeyDoc.id, paymentID, refundAmount);
  }

  async function handleEditInvoiceDialogOpen() {
    if (selectedInvoice) {
      setEditInvoiceDialogOpen(true);
    }
  }

  function onClickHandlePaymentButton(invoiceID: ExistingStiltInvoice["id"]) {
    const invoiceDoc = workRecordInvoiceList.find(
      (invoice) => invoice.id === invoiceID,
    );
    setIsHandlePaymentDialogOpen(true);
    setSelectedInvoice(invoiceDoc ?? null);
  }

  function closeViewInvoiceDialog(): void {
    setIsViewInvoiceDialogOpen(false);
    setViewInvoiceDialogProps(null);
  }

  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  async function handleCreateNewEstimate(taskDoc: ExistingTask) {
    if (taskDoc.customerID && siteKeyDoc && customer) {
      navToCreateEstimateByTask(customer, taskDoc);
    }
  }

  async function handleCreateFollowUpTask(
    task: ExistingTask,
    followUpTaskStatus: TaskStatus | null,
  ) {
    if (firebaseUser == null) {
      devLogger.error("firebaseUser is null");
      return;
    }
    if (!siteKeyDoc) {
      devLogger.error("siteKeyDoc is null");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, refPath, ...rest } = task;

    const newTask: Task_CreateForCustomer = {
      ...rest,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      urgent: false,
      nextOpportunity: false,
      workOrder: "",
      crewCount: 0,
      durations: {},
      holdDurations: {},
      taskStatus: followUpTaskStatus ?? rest.taskStatus,
      taskSpecificDetails: {},
      timestampCreated: Timestamp.now().toMillis(),
      timestampLastModified: Timestamp.now().toMillis(),
      timestampScheduled: null,
      timestampAwaitingStart: null,
      timestampTaskCompleted: null,
      timestampTaskStarted: null,
    };

    // Convert to doc data
    const taskReady = TaskRecordManager.convertNewForFirestore(newTask);
    devLogger.info("taskReady", taskReady);

    await DbWrite.tasks.createForCustomer({
      siteKey: siteKeyDoc.id,
      taskDoc: taskReady,
      estimateID: null,
    });
  }

  async function handleGoToPaymentPage(): Promise<void> {
    if (!siteKeyDoc) return;
    if (invoicePaymentLink != null) {
      goToPaymentPage(invoicePaymentLink);
    } else {
      if (!selectedInvoice) return;
      const paymentLink = await generatePaymentUniqueLink(
        siteKeyDoc.id,
        selectedInvoice.id,
        "web",
      );
      if (paymentLink) {
        goToPaymentPage(paymentLink);
      } else {
        addMessage({
          id: createToastMessageID(),
          message: strings.ERROR_PAYMENT_LINK,
          dialog: false,
          type: "error",
        });
      }
    }
  }

  async function handleCreateInvoice(estimate: ExistingEstimate) {
    if (!siteKeyDoc) return;
    if (!isWhiteLabel(whiteLabel)) throw new Error(`Unexpected white label`);
    try {
      const { paymentURL, invoiceData } = await mutateCreateInvoice.mutateAsync(
        {
          siteKey: siteKeyDoc.id,
          estimateID: estimate.id,
          version: whiteLabel,
        },
      );
      setIsHandlePaymentDialogOpen(true);
      setInvoicePaymentLink(paymentURL);
      setSelectedInvoice(invoiceData);
    } catch (error) {
      devLogger.error("error on handleCreateInvoice", error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  const emailList = selectedInvoice
    ? getEmailList(selectedInvoice, customer)
    : [];

  /* MUTATIONS */
  const mutateUpdateInvoice = useMutation(
    async (args: { validPartialInvoiceData: StiltInvoice_UpdateAPI }) => {
      await DbWrite.invoices.update(args.validPartialInvoiceData);
    },
  );

  const mutateRecordManualPayment = useMutation(
    async (args: { paymentData: StiltPayment_CreateAPI }) => {
      await DbWrite.payments.manualPayment(args.paymentData);
    },
  );

  const mutateCreateInvoice = useMutation(
    async (args: { siteKey: string; estimateID: string; version: string }) => {
      return DbWrite.invoices.create({
        siteKey: args.siteKey,
        estimateID: args.estimateID,
        version: args.version,
        platform: "web",
      });
    },
  );

  async function getEstimatePDF(estimateID: string) {
    if (!siteKeyDoc) return;
    await generatePDF.estimate(siteKeyDoc, [estimateID]);
  }

  function resetSelectedEstimate() {
    setSelectedEstimate(null);
    setSelectedInvoice(null);
  }

  async function handleGoToViewEstimate(estimateDoc: ExistingEstimate) {
    const invoiceDoc = workRecordInvoiceList.find(
      (invoice) => invoice.estimateID === estimateDoc.id,
    );

    setSelectedEstimate(estimateDoc);
    setSelectedInvoice(invoiceDoc ?? null);
    setViewEstimateDialogOpen(true);
  }

  async function handleUpdateTask(updateData: DocumentData, taskID: string) {
    if (!siteKeyDoc) {
      return;
    }
    await DbWrite.tasks.update(updateData, siteKeyDoc.id, taskID);
  }

  async function handleUpdateTSD(updateData: DocumentData, refPath: string) {
    await DbWrite.taskSpecificDetails.update(refPath, updateData);
  }

  async function handleDeleteTask(id: string): Promise<void> {
    if (!selectedTask) return;
    await DbWrite.tasks.delete(selectedTask);
  }

  function goToWorkRecordAndTasksPage(
    craftRecordPath: ExistingTask["craftRecordID"],
  ) {
    const craftRecordID = craftRecordPath.split("/")[3];
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  async function handleEditTask(values: Record<string, Json>) {
    if (!selectedTask) return;

    const { membershipIDs, assetIDs, description, ...tsd } = values;

    const changedDescription = selectedTask.description !== description;
    const changedMembershipIDs = selectedTask.membershipIDs !== membershipIDs;
    const changedAssetIDs = selectedTask.assetIDs !== assetIDs;
    const tsdDiff = diffObjects(selectedTask.taskSpecificDetails, tsd).diff;
    const changedTSD = Object.keys(tsdDiff).length > 0;

    if (
      !changedDescription &&
      !changedTSD &&
      !changedMembershipIDs &&
      !changedAssetIDs
    )
      return;

    let taskUpdate = {};
    if (changedDescription || membershipIDs || assetIDs) {
      taskUpdate = {
        description: description,
        membershipIDs: membershipIDs,
        assetIDs: assetIDs,
        // @ts-ignore
        isMembershipTask: membershipIDs && membershipIDs?.length > 0,
        timestampLastModified: Timestamp.now(),
        lastModifiedBy: firebaseUser.uid,
      };
    } else {
      taskUpdate = {
        timestampLastModified: Timestamp.now(),
        lastModifiedBy: firebaseUser.uid,
      };
    }

    const xtData = dropUndefined(taskUpdate);

    // try/catch within the dialog
    await handleUpdateTask(xtData, selectedTask.id);

    if (changedTSD) {
      await handleUpdateTSD(tsdDiff, selectedTask.refPath);
    }

    addMessage({
      id: createToastMessageID(),
      message: strings.successfulUpdate("Task"),
      dialog: false,
      type: "success",
    });
  }

  async function payWithCardOnFile(args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }): Promise<void> {
    if (!siteKeyDoc) throw Error("payWithCardOnFile missing siteKeyDoc");

    const processor = siteKeyDoc.customizations.accounting?.ziftAccountID
      ? "zift"
      : "paya";
    const data: APIPaymentSavedCard = {
      siteKeyID: siteKeyDoc.id,
      invoiceID: args.invoiceID,
      amount: args.amount,
      cardLastFour: args.lastFour,
      cardExpiry: args.expiry,
    };
    const valid = StiltPaymentManager.parseCreateWithSavedCard(data);
    await DbWrite.payments.createWithSavedCard(valid, processor);
  }

  function handleOpenTaskStatusChangeDialogDueToScheduleChange(args: {
    updatedTask: ExistingTask;
    originalTask: ExistingTask;
  }) {
    if (!siteKeyDoc) {
      // should never happen
      devLogger.error(
        `missing siteKey doc while opening task status change dialog. task id: ${args.updatedTask.id}`,
      );
      return;
    }

    // Get the siteKey's customizations for this task
    const targetWorkType = args.updatedTask.craftType;
    const targetTaskType = args.updatedTask.taskType;
    if (isValidCraftType(targetWorkType) && isValidTaskType(targetTaskType)) {
      const taskCustomFields = getTaskCustomFieldList({
        siteKey: siteKeyDoc,
        targetWorkType,
        targetTaskType,
      });
      setSkTaskCustomFields(taskCustomFields);
    }

    // Here we need to provide an updated task object that the taskStatusChangeDialog will use
    console.log(args.updatedTask.taskSpecificDetails);
    setTaskForTaskStatusChangeDialog(args.updatedTask);
    // setOriginalTaskForTaskStatusChangeDialog(args.originalTask);
    setOpenTaskStatusChangeDialog(true);
  }

  /** Update the task doc's scheduling-related values */
  async function handleRescheduleTask(updatedTask: ExistingTask) {
    if (!selectedTask) {
      devLogger.error("taskDoc is null (and it shouldn't be)");
      return;
    }

    const { taskSpecificDetails, ...rest } = updatedTask;

    const taskDiff: DocumentData = diffObjects(selectedTask, rest).diff;
    const tsdDiff: DocumentData = diffObjects(
      selectedTask.taskSpecificDetails,
      taskSpecificDetails,
    ).diff;

    const taskNoChange = Object.keys(taskDiff).length === 0;
    const tsdNoChange = Object.keys(tsdDiff).length === 0;
    // return early if there's been no change
    if (taskNoChange && tsdNoChange) return;

    const updateData = {
      ...taskDiff,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
    };

    // Don't want a try/catch here, that's handled within the dialog component.
    await handleUpdateTask(updateData, selectedTask.id);
    // Task doc needs to update before TSD. That's why we're not using Promise.all
    await handleUpdateTSD(tsdDiff, selectedTask.refPath);

    addMessage({
      id: createToastMessageID(),
      message: strings.successfulUpdate("Task"),
      dialog: false,
      type: "success",
    });
  }

  const openJobButton = selectedTask && (
    <BaseButtonPrimary
      className="flex gap-2 uppercase"
      onClick={() => {
        // localStorage.setItem(
        //   "schedulingContainerCurrentDate",
        //   JSON.stringify(selectedDateTime.toMillis()),
        // );
        goToWorkRecordAndTasksPage(selectedTask.craftRecordID);
      }}
    >
      {strings.buttons.OPEN_JOB}
    </BaseButtonPrimary>
  );

  function renderEstimateIconCell(params: ICellRendererParams) {
    const currentInvoice = workRecordInvoiceList.find(
      (invoice) => invoice.estimateID === params.data.id,
    );
    let iconToDisplay: JSX.Element;
    if (currentInvoice && currentInvoice.status !== "paid") {
      iconToDisplay = (
        <StyledTooltip title="Handle Payment">
          <PaymentIconWithSpinner
            onCreate={async () => {
              setIsTableHandlePaymentDialogOpen(true);
              setSelectedInvoice(currentInvoice);
            }}
          />
        </StyledTooltip>
      );
    } else if (currentInvoice && currentInvoice.status === "paid") {
      iconToDisplay = (
        <StyledTooltip title="Send Receipt">
          <EmailButtonWithSpinner
            onClick={async () => {
              setSelectedInvoice(currentInvoice);
              setHandleSendEmailDialogOpen(true);
            }}
          />
        </StyledTooltip>
      );
    } else {
      iconToDisplay = (
        <StyledTooltip title="Create Invoice">
          <InvoiceIconWithSpinner
            onCreate={async () => {
              await handleCreateInvoice(params.data);
            }}
          />
        </StyledTooltip>
      );
    }

    return (
      <div className="flex items-center gap-2">
        {iconToDisplay}
        <StyledTooltip title="Download PDF">
          <PDFIconWithSpinner
            onCreate={async () => getEstimatePDF(params.data.id)}
          />
        </StyledTooltip>
      </div>
    );
  }

  const viewEstimateDialog = siteKeyDoc &&
    selectedEstimate &&
    customer &&
    customerLocation && (
      <ViewEstimateDialog
        isOpen={viewEstimateDialogOpen}
        onClose={() => {
          resetSelectedEstimate();
          setViewEstimateDialogOpen(false);
        }}
        estimate={selectedEstimate}
      >
        {{
          ViewEstimatePage: (
            <ViewEstimateContainer
              renderedInDialog={true}
              siteKey={siteKeyDoc.id}
              optionalDocs={{
                estimateID: selectedEstimate.id,
                customerDoc: customer,
                customerLocationDoc: customerLocation,
                invoiceDoc: selectedInvoice,
              }}
            />
          ),
        }}
      </ViewEstimateDialog>
    );

  const manualPaymentDatePicker = selectedInvoice && (
    <DatePicker
      selected={selectedDateTime.toJSDate()}
      onChange={(date: Date) => {
        const luxonDate = DateTime.fromJSDate(date);
        setSelectedDateTime(luxonDate);
      }}
      showTimeSelect
      customInput={<SchedulingButton />}
    />
  );

  /** this one opens from within ViewInvoiceDialog */
  const handlePaymentDialog = isHandlePaymentDialogOpen &&
    siteKeyDoc &&
    selectedInvoice &&
    userPermissions &&
    customer && (
      <HandlePaymentDialog
        isDialogOpen={isHandlePaymentDialogOpen}
        closeDialog={() => {
          setIsHandlePaymentDialogOpen(false);
          setInvoicePaymentLink(null);
        }}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin}
        invoiceID={selectedInvoice.id}
        invoiceStatus={selectedInvoice.status}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        customer={customer}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, customer)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: manualPaymentDatePicker,
        }}
      </HandlePaymentDialog>
    );

  /** this one opens when the Estimate List table row's action button is clicked */
  const tableHandlePaymentDialog = isTableHandlePaymentDialogOpen &&
    siteKeyDoc &&
    selectedInvoice &&
    userPermissions &&
    customer && (
      <HandlePaymentDialog
        isDialogOpen={isTableHandlePaymentDialogOpen}
        closeDialog={() => setIsTableHandlePaymentDialogOpen(false)}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin}
        invoiceID={selectedInvoice.id}
        invoiceStatus={selectedInvoice.status}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        customer={customer}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, customer)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      >
        {{
          DatePicker: manualPaymentDatePicker,
        }}
      </HandlePaymentDialog>
    );

  const handleSendReceiptDialog = selectedInvoice && (
    <HandleSendEmailDialog
      defaultIncludeJobPhotos={
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false
      }
      isDialogOpen={handleSendEmailDialogOpen}
      closeDialog={() => setHandleSendEmailDialogOpen(false)}
      sendEmailReceipt={handleEmailReceipt}
      title={strings.SEND_RECEIPT_TO_CUSTOMER}
      merchantName={siteKeyDoc?.name ?? ""}
      timestampSentToCustomer={selectedInvoice.timestampSentToCustomer}
      customerEmailList={emailList}
    />
  );

  const dueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedDueDate ? `Due Date` : "Due Date Not Selected"}
      <div className="mt-2 flex items-center justify-between gap-4">
        <DatePicker
          selected={
            selectedDueDate
              ? selectedDueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedDueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const issueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedIssueDate ? `Issue Date` : "Issue Date Not Selected"}
      <div className="mt-2 flex items-center justify-between gap-4">
        <DatePicker
          selected={
            selectedIssueDate
              ? selectedIssueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedIssueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const paymentTermsSelector = (
    <BaseInputSelect
      inputName="paymentTerms"
      text="Payment Terms"
      admin={true}
      required={true}
      className="capitalize"
      value={paymentTerms === null ? "" : paymentTerms}
      onChange={handlePaymentTermsChange}
    >
      <option value="">None</option>
      {Object.entries(templatesPaymentTerms).map(([key, paymentTerm]) => {
        return (
          <option key={key} value={key}>
            {paymentTerm.title}
          </option>
        );
      })}
    </BaseInputSelect>
  );

  const editInvoiceDialog = selectedInvoice && (
    <EditInvoiceDialog
      isDialogOpen={editInvoiceDialogOpen}
      closeDialog={() => setEditInvoiceDialogOpen(false)}
      invoiceDoc={selectedInvoice}
      handleSave={handleSaveUpdatedInvoice}
      dueDate={dueDatePicker}
      issueDate={issueDatePicker}
      paymentTerms={paymentTermsSelector}
    />
  );

  const viewInvoiceDialog = isViewInvoiceDialogOpen &&
    viewInvoiceDialogProps &&
    userPermissions &&
    !userDisplayNamesMapIsLoading && (
      <ViewInvoiceDialog
        isOpen={isViewInvoiceDialogOpen}
        onClose={closeViewInvoiceDialog}
        invoiceID={viewInvoiceDialogProps["invoiceID"]}
        siteKeyID={viewInvoiceDialogProps["siteKeyID"]}
        merchantLogoURL={viewInvoiceDialogProps["merchantLogoURL"]}
        merchantName={viewInvoiceDialogProps["merchantName"]}
        handleRefund={handleRefund}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin}
        editInvoice={handleEditInvoiceDialogOpen}
        sendEmail={emailInvoice}
        handlePayment={onClickHandlePaymentButton}
        deleteInvoice={handleDeleteInvoice}
        userDisplayNamesMap={userDisplayNamesMap}
        handleDeletePayment={handleDeletePayment}
      >
        {{
          EditInvoiceDialog: editInvoiceDialog,
          HandlePaymentDialog: handlePaymentDialog,
        }}
      </ViewInvoiceDialog>
    );

  const rescheduleTaskDialog = siteKeyDoc &&
    userPermissions &&
    selectedTask &&
    shouldShowRescheduleDialog && (
      <RescheduleTaskDialog
        isOpen={openRescheduleTaskDialog}
        onClose={() => {
          // Only reset the reschedule dialog state
          setOpenRescheduleTaskDialog(false);
          setShouldShowRescheduleDialog(false);
        }}
        handleRescheduleTask={handleRescheduleTask}
        siteKey={siteKeyDoc}
        siteKeyUserPermissions={userPermissions}
        task={selectedTask}
        handleOpenTaskStatusChangeDialogDueToScheduleChange={
          handleOpenTaskStatusChangeDialogDueToScheduleChange
        }
        permissionsMap={permissionsMap}
        siteKeyUsersList={siteKeyUsersList}
        siteLocationList={siteKeyLocationList}
        vehicleList={vehicleList}
      />
    );

  const estimateListForTaskDialog = (
    <EstimateListDialog
      isOpen={estimateListDialogOpen}
      onClose={() => setEstimateListDialogOpen(false)}
      estimateListForTable={taskEstimates}
      handleGoToViewEstimate={handleGoToViewEstimate}
      renderIconCell={renderEstimateIconCell}
      estimateItemList={Object.values(estimateItemsMap).flat()}
      invoiceList={workRecordInvoiceList}
      currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
    >
      {{
        ViewEstimateDialog: viewEstimateDialog,
        HandlePaymentDialog: tableHandlePaymentDialog,
        ViewInvoiceDialog: viewInvoiceDialog,
        ConfirmationDialog: null,
        HandleSendReceiptDialog: handleSendReceiptDialog,
        InvoiceListForWRDialog: null,
      }}
    </EstimateListDialog>
  );

  const editTaskDialog = selectedTask && siteKeyDoc && (
    <EditTaskDialog
      isOpen={openEditTaskDialog}
      onClose={() => {
        setOpenEditTaskDialog(false);
      }}
      siteKey={siteKeyDoc.id}
      task={selectedTask}
      membershipsEnabled={
        siteKeyDoc?.customizations?.membershipsEnabled === true
      }
      assetsEnabled={siteKeyDoc?.customizations?.assetsEnabled === true}
      siteKeyCustomFields={skTaskCustomFields}
      handleEditTask={handleEditTask}
      userList={assignedUserList.current}
      userDisplayNamesMap={filterUserDisplayNamesMap}
      customerContacts={customerContacts}
    />
  );

  /**
   * this taskStatus change dialog is part of the reschedule task flow.
   * not intended to be used with the change taskStatus button.
   */
  // TODO: known issue: taskForTaskStatusChangeDialog should be null after task status change but requires-re-render if doing another task status change right after a previous one
  const taskStatusChangeDialog = selectedTask && customerLocation && (
    <TaskStatusChangeDialog
      // DIALOG BASICS
      open={openTaskStatusChangeDialog}
      onClose={() => {
        setTaskForTaskStatusChangeDialog(null);
        setOpenTaskStatusChangeDialog(false);
        setShowCraftPersistence(false);
        setNextTaskStatus(null);
      }}
      // DATA
      showCraftPersistence={showCraftPersistence}
      workRecordTitle={craftRecord?.title ?? ""}
      task={taskForTaskStatusChangeDialog ?? selectedTask}
      originalTask={selectedTask}
      isReschedulingTask={false}
      siteKeyCustomFields={taskCustomFields}
      nextTaskStatus={nextTaskStatus}
      userList={assignedUserList.current}
      userDisplayNamesMap={filterUserDisplayNamesMap}
      uid={firebaseUser.uid}
      handleUpdateTask={handleUpdateTask}
      handleUpdateTSD={handleUpdateTSD}
      customerLocation={customerLocation}
      emailList={emailList}
      createFollowUpTask={handleCreateFollowUpTask}
      invoices={workRecordInvoiceList.filter(
        (i) => i.taskID === selectedTask.id,
      )}
    />
  );

  // Add this new function to handle the Edit Date button click
  const handleEditDateClick = () => {
    setOpenRescheduleTaskDialog(true);
    setShouldShowRescheduleDialog(true);
  };

  if (!userPermissions || !siteKeyDoc || !selectedTask) return null;
  return (
    <TaskDetailsDialog
      title={selectedTask.title}
      isDialogOpen={taskDetailsDialogOpen}
      closeDialog={() => {
        // Reset all state when the main dialog closes
        setTaskDetailsDialogOpen(false);
        setOpenRescheduleTaskDialog(false);
        setShouldShowRescheduleDialog(false);
        setSelectedTask(null);
      }}
    >
      {{
        OpenJobButton: openJobButton,
        TaskCard: (
          <TaskCardOpen
            key={selectedTask.id}
            task={selectedTask}
            assets={assets}
            onAssetClick={onAssetClick}
            existingEstimatesForTask={taskEstimates.length > 0}
            handleDeleteTask={handleDeleteTask}
            handleOpenRTD={handleEditDateClick}
            handleOpenEditTaskDialog={() => setOpenEditTaskDialog(true)}
            handleCreateNewEstimate={handleCreateNewEstimate}
            handleOpenEstimateListDialog={() => setEstimateListDialogOpen(true)}
          >
            {{
              detailsAndEventsPanels: (
                <DetailsAndEventsPanels
                  details={selectedTask.taskSpecificDetails}
                  siteKeyCustomFields={taskCustomFields}
                  userDisplayNamesMap={filterUserDisplayNamesMap}
                  events={taskEvents}
                  vehicleList={vehicleList}
                />
              ),
              changeTaskStatusButton: (
                <ChangeTaskStatusButton
                  task={selectedTask}
                  userPermissions={userPermissions}
                  siteKeyDoc={siteKeyDoc}
                  uid={firebaseUser.uid}
                  handleUpdateTask={handleUpdateTask}
                  setOpenTaskStatusChangeDialog={() =>
                    setOpenTaskStatusChangeDialog(true)
                  }
                  setShowCraftPersistence={() => setShowCraftPersistence(true)}
                  setNextTaskStatus={setNextTaskStatus}
                />
              ),
            }}
          </TaskCardOpen>
        ),
        EditTaskDialog: editTaskDialog,
        RescheduleTaskDialog: rescheduleTaskDialog,
        TaskStatusChangeDialog: taskStatusChangeDialog,
        EstimateListForTaskDialog: estimateListForTaskDialog,
      }}
    </TaskDetailsDialog>
  );
};
