import { useState } from "react";
import { Control } from "react-hook-form";
// Local
import { TemplateFormState } from "./types";
import { AddEditTemplateForm } from "./AddEditTemplateForm";
import { ADD_NEW_TEMPLATE, buttons } from "../../strings";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import NavButtonBack from "../../components/NavButtonBack";

interface Props {
  onSubmit: (formValues: TemplateFormState) => Promise<void>;
  onCancel: () => void;
  children: {
    pbiSection: React.ReactNode;
    renewalPBISection: React.ReactNode;
    taskGenSection: (control: Control<TemplateFormState>) => React.ReactNode;
  };
}

export default function AddTemplatePage(props: Props) {
  const [isBusy, setIsBusy] = useState(false);

  return (
    <div className="relative mx-auto w-full md:mx-0 md:max-w-[1280px]">
      <div className="mb-8 mt-4 flex flex-wrap items-center justify-between gap-4">
        <div className="flex flex-wrap items-center gap-2 sm:gap-4">
          <NavButtonBack onClick={props.onCancel} />
          <h1 className="text-3xl font-semibold leading-3 text-primary sm:text-5xl">
            {ADD_NEW_TEMPLATE}
          </h1>
        </div>
        <BaseButtonPrimary
          type="submit"
          form="addTemplateForm"
          formNoValidate
          disabled={isBusy}
          isBusy={isBusy}
          busyText={buttons.BUSY_SAVING}
          // className="w-40 uppercase"
          className="w-40 uppercase sm:self-end"
        >
          {buttons.SAVE}
        </BaseButtonPrimary>
      </div>

      <AddEditTemplateForm
        id="addTemplateForm"
        onSubmit={props.onSubmit}
        setIsBusy={setIsBusy}
      >
        {{
          pbiSection: props.children.pbiSection,
          renewalPBISection: props.children.renewalPBISection,
          taskGenSection: props.children.taskGenSection,
        }}
      </AddEditTemplateForm>
    </div>
  );
}
